import React, { useEffect, useState } from "react";
import DagBoekList from "./detail/DagBoekList";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import Swal from "sweetalert2";
import { AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import ReInput from "../../Componenten/Layout/ReInput";
import TypeNote from "./TypeNote";
import { DocumentIcon, PaperClipIcon } from "@heroicons/react/24/solid";
import Select2 from "../../Componenten/Select2";
import RPDrawerComment from "../../Componenten/Drawers/RPDrawerComment";
import { useSelector } from "react-redux";
import SelectPatienten from "../../Componenten/SelectPatienten";
import moment from "moment";

const EetdagboekAdmin = ({}) => {
  const { patienten } = useSelector((state) => state.user);
  const [dagboeken, setDagboeken] = useState([]);
  const user = getUser();
  const userdetails = getUserdetails();
  const [command, setCommand] = useState("insert");
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(patienten[0]);
  const [showEdit, setShowEdit] = useState(true);

  const [dates, setdates] = useState({
    startdate: moment().startOf('month').format('YYYY-MM-DD'),
    enddate: moment().endOf('month').format('YYYY-MM-DD'),
  });

  const eetdagboek = {
    user_id: user?.id,
    datum: new Date(),
    dag: "",
    ochtend: "",
    voormiddag: "",
    middag: "",
    namiddag: "",
    avond: "",
    smorgens: "",
    smiddags: "",
    savonds: "",
    type_note: 1,
    title: "",
    admin: userdetails?.full_name || user?.username,
    admin_id: userdetails?.user_id || user?.id,
    score: 0,
    opmerking: "",
  };

  let commentaaradmin = {
    admin: userdetails?.full_name || user?.username,
    admin_id: userdetails?.user_id || user?.id,
    score: 0,
    opmerking: "",
  };

  const [updateAdmin, setUpdateAdmin] = useState(commentaaradmin);
  const [formData, setFormData] = useState(eetdagboek);

  useEffect(() => {
    const fetchDaymealData = async (search) => {
      let adminUpdate = {};
      try {
        const response = await AXIOS_POST(`eetdagboek/user`, search);
        let eetdagboeken = response.data.data;

        setDagboeken(eetdagboeken);
        setRefresh(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    let newId = selected?.user_id || patienten[0]?.user_id;
    const search = {
      id: newId,
      startdate: moment(dates?.startdate).format('YYYY-MM-DD'),
      enddate: moment(dates?.enddate).format('YYYY-MM-DD'),
    };
    if (selected || patienten?.length > 0 || formData || dates) {
      fetchDaymealData(search);
    }
  }, [open, patienten, formData, selected, dates?.startdate, dates?.enddate]);

  /* useEffect(() => {
    if (selected)
      setdates({
        ...dates,
        startdate: moment(selected?.created_at).format("YYYY-MM-DD"),
      });
  }, [selected]); */

  const validateForm = () => {
    if (!formData?.score) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title:
          "De gegevens zijn niet opgeslagen. Gelieve een score in te voeren. Deze wordt meegerekend bij de vooruitgang van de klant.",
        showConfirmButton: false,
        timer: 2500,
      });
      return false;
    }

    return true;
  };

  const Opslaan = async () => {
    if (!validateForm()) return;
    const newObject = {
      admin: formData.admin,
      admin_id: formData.admin_id,
      score: formData.score,
      opmerking: formData.opmerking,
    };
    try {
      await AXIOS_UPDATE(`eetdagboek/adminComments`, formData.id, newObject);
      setCommand(null);
      Swal.fire("Gegevens zijn gewijzigd", "", "success");
      setRefresh(true);
    } catch (error) {
      console.error("Error updating data:", error);
      Swal.fire("Er is een fout opgetreden bij het wijzigen", "", "error");
    }

    setOpen(false);
  };

  const EditTekst = async (item, cmd) => {
    setFormData((prev) => ({
      ...prev,
      id: item?.eetdagboek_id || "",
      user_id: item?.eetdagboek_user_id || "",
      datum: item?.eetdagboek_datum || new Date(),
      dag: item?.eetdagboek_dag || "",
      ochtend: item?.eetdagboek_ochtend || "",
      voormiddag: item?.eetdagboek_voormiddag || "",
      middag: item?.eetdagboek_middag || "",
      namiddag: item?.eetdagboek_namiddag || "",
      avond: item?.eetdagboek_avond || "",
      smorgens: item?.eetdagboek_smorgens || "",
      smiddags: item?.eetdagboek_smiddags || "",
      savonds: item?.eetdagboek_savonds || "",
      type_note: item?.eetdagboek_type_note || 1,
      title: item?.eetdagboek_title || "",
      admin: item?.eetdagboek_admin || commentaaradmin.admin,
      admin_id: item?.eetdagboek_admin_id || commentaaradmin.admin_id,
      score: item?.eetdagboek_score || 0,
      opmerking: item?.eetdagboek_opmerking || "",
      url: item?.eetdagboek_url || "",
    }));
  };

  return (
    <div className="flex h-dvh dark:bg-gray-800">
      <aside className="inset-y-0 bg-gray-300 dark:bg-gray-900  2xl:w-1/4 xl:w-2/6 overflow-y-auto px-4 py-2 sm:px-6 xl:block h-dvh">
        <div className="mt-4">
          {patienten.length > 0 && (
            <SelectPatienten selected={selected} setSelected={setSelected} />
          )}
        </div>
        <div className="flex">
          <input
            type="date"
            name="startdate"
            id="startdate"
            placeholder="datum"
            className="border flex-1 py-1 px-2 dark:bg-gray-800 dark:text-white rounded"
            value={moment(dates.startdate).format("YYYY-MM-DD")}
            onChange={(e) => setdates({ ...dates, startdate: e.target.value })}
          />

          <input
            type="date"
            name="enddate"
            id="enddate"
            placeholder="datum"
            className="border flex-1 py-1 px-2 dark:bg-gray-800 dark:text-white rounded"
            value={moment(dates.enddate).format("YYYY-MM-DD")}
            onChange={(e) => setdates({ ...dates, enddate: e.target.value })}
          />
        </div>

        <div className="mt-2 py-2">
          {dagboeken.length > 0 ? (
            <DagBoekList
              admin={true}
              showText={EditTekst}
              dagboeken={dagboeken}
            />
          ) : (
            <div>
              <p>Geen gegevens gevonden voor deze patient</p>
            </div>
          )}
        </div>
      </aside>
      <main className="2xl:w-3/4 xl:w-4/6 h-dvh lg:overflow-y-auto">
        {formData?.title && (
          <div className="grid justify-items-stretch">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="inline-flex justify-self-end mt-2 items-center gap-2 rounded-md bg-gray-700 mx-8 px-4 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-500"
            >
              Opmerking plaatsen
              <DocumentIcon
                className="-mr-0.5 h-5 w-5 text-green-200"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
        <div className="xl:pl-8">
          <div className="px-4 py-4 sm:px-6 lg:px-8 lg:py-4">
            <div className="col-span-full">
              <div className="flex gap-x-4">
                <div
                  dangerouslySetInnerHTML={{ __html: formData?.title }}
                  className={`mt-2 space-y-2 text-xl text-gray-800 dark:text-white`}
                />
                {formData?.url && (
                  <a
                    href={formData?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="border rounded p-2 cursor-pointer bg-white dark:bg-gray-700  hover:border-gray-600 hover:bg-gray-500">
                      <span className="gap-x-2 flex cursor-pointer">
                        <PaperClipIcon className="dark:text-white h-6 w-6" />{" "}
                        <font className="dark:text-white block text-sm font-medium leading-6 text-gray-900">
                          Een Afbeelding van een maaltijd gelinkt
                        </font>{" "}
                      </span>
                    </div>
                  </a>
                )}
              </div>
            </div>
            <TypeNote admin={showEdit} Eetdagboek={formData} />
          </div>
        </div>
      </main>
      {formData && (
        <RPDrawerComment
          onClick={Opslaan}
          item={formData}
          admin={formData}
          setUpdateAdmin={setFormData}
          setOpen={setOpen}
          open={open}
        />
      )}
    </div>
  );
};

export default EetdagboekAdmin;
