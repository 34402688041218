import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { AXIOS_GET_CAL_SCHEDULED_EVENTS, AXIOS_GET_SCHEDULED_EVENTS } from "../../server/api/crud";
import Spinner from "../../Componenten/Spinner";
import { CalendarDateRangeIcon } from "@heroicons/react/20/solid";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";

function CalendlyEvents() {
  const userdetails = getUserdetails();
  const user = getUser();
  const email = user?.email ? user?.email : userdetails?.email;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({
    organization:"https://api.calendly.com/organizations/e5ba7299-d262-4435-acc9-67a68893e28f",
    status: "alles",
    count:'50',
    sort: 'start_time:desc',
    user: userdetails?.calendly_info?.uri
  });

  useEffect(() => {
    async function fetchData() {
      // You can await here
      setLoading(true);
      let newparams = {};
      if (params?.status == "alles") {
        newparams = {
          organization: params.organization,
          count:'50',
          sort: 'start_time:desc',
          user: userdetails?.calendly_info?.uri
        };
      } else {
        newparams = params;
      }

      const response = await AXIOS_GET_CAL_SCHEDULED_EVENTS(newparams);

      let newData = response?.collection;
      let Dataspecialist = response?.collection;
      setData(Dataspecialist);
      setLoading(false);

      // ...
    }
    fetchData();
  }, [params?.status]);
  return (
    <div className="dark:bg-gray-900 bg-white h-max">
      {loading && <Spinner />}
      <select
        id="status"
        name="status"
        defaultValue={params?.status}
        onChange={(e) =>
          setParams((prev) => ({ ...prev, status: e.target.value }))
        }
        className="m-4 rounded border p-2 text-black dark:text-white bg-white dark:bg-gray-900"
      >
        <option value={"alles"}>Alle afspraken</option>
        <option value={"active"}>Toekomstige afspraken</option>
        <option value={"canceled"}>Geannuleerde afspraken</option>
      </select>
      <div className="inline-block min-w-full align-middle sm:grid-cols-2 grid md:grid-cols-3 sm:px-2 lg:px-4 gap-2">
          {data?.length > 0 &&
            data.map((item, index) => {
              return (
                <div
                  className="border rounded border-gray-300 p-2 m-4"
                  key={index}
                >
                  <p className="dark:text-white flex items-center text-xl">
                    <div
                      className={`w-2 h-2 ${
                        item.status == "active" ? "bg-green-500" : "bg-red-500"
                      } rounded-full mr-2`}
                    />{" "}
                    {item.name}
                  </p>
                  <p className="dark:text-white">
                    {moment(item.start_time).format("LL")}
                  </p>
                  <time
                    dateTime={item.start_time}
                    className="dark:text-white italic text-sm"
                  >
                    {moment(item.start_time).format("HH:mm")} -{" "}
                  </time>
                  <time
                    dateTime={item.end_time}
                    className="dark:text-white italic text-sm"
                  >
                    {moment(item.end_time).format("HH:mm")}
                  </time>
                  <div>
                    {item.invitees.map((invitee, index) => (
                      <div
                        title={invitee?.questions_and_answers[0]?.answer}
                        className="cursor-pointer flex flex-col border rounded p-2 m-4"
                        key={index}
                      >
                        <p className="dark:text-white text-sm italic">Klant</p>
                        <p className="dark:text-white text-sm">
                          {invitee?.name}
                        </p>
                        <p className="dark:text-white text-xs">
                          {invitee?.email}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div>
                    {item.creators.map((invitee, index) => (
                      <div
                        className="flex flex-col border border-green-500 dark:bg-gray-800 rounded p-2 m-4"
                        key={index}
                      >
                        <p className="dark:text-green-500 text-sm italic">
                          Specialist
                        </p>
                        <p className="dark:text-white text-sm">
                          {invitee?.user_name}
                        </p>
                        <p className="dark:text-white text-xs">
                          {invitee?.user_email}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
  );
}

export default CalendlyEvents;
