"use client";

import {
  Input,
  Label,
} from "@headlessui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Pagination from "./Pagination";
import { fetchAllPatientenDetails, fetchAllUsersDetails } from "../apiCalls/usersApi";
import { fetchMailRooms } from "../apiCalls/messageApi";

const SelectPatientMail = ({  user,dispatch,mailMessage }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      fetchMailRooms(user,dispatch,mailMessage = null,pageSize,page,searchQuery)
    }, 300),
    []
  );

  useEffect(() => {
  
    fetchMailRooms(user,dispatch,mailMessage = null,pageSize,page,search);

  }, [page, pageSize]);

  // Add debounced search function

  return (
    <div className="w-full dark:bg-gray-800 items-center px-5 rounded-md">
      <Input
        placeholder="Zoeken patient op"
        className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        onChange={(event) => {
          setSearch(event.target.value);
          debouncedSearch(event.target.value);
        }}
        onBlur={() => setSearch("")}
        displayValue={(mail) =>
          mail?.receiver ||
          mail?.sender ||
          mail?.subject ||
          mail?.category
        }
      />

      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageRanges={[8, 16, 32, 64, 128, 258]}
      />
    </div>
  );
};

export default SelectPatientMail;
