import React, { useState } from "react";
import DagelijksFormulier from "./details/DagelijksFormulier";
import EtenNotities from "./details/EtenNotities";
import HongerControle from "./details/HongerControle";
import { getUser, getUserdetails, getVoedingID } from "../../../Localstorage/localstorage";

const VoedingForm = ({ setOpen, userdetails }) => {
  const [selected, setSelected] = useState(0);
  const voeding = getVoedingID();
  const user = getUser();

  return (
    <div>
      <h2 className="text-center text-2xl">Voedingsvragenlijst</h2>
      <div>
        {selected == 0 ? (
          <EtenNotities setSelected={setSelected} voeding={voeding} admin={user?.role == 1 ? user : null} setOpen={setOpen} userdetails={userdetails} />
        ) : selected == 1 ? (
          <HongerControle setSelected={setSelected} voeding={voeding} admin={user?.role == 1 ? user : null} setOpen={setOpen} userdetails={userdetails} />
        ) : (
          <DagelijksFormulier setSelected={setSelected} voeding={voeding} admin={user?.role == 1 ? user : null} setOpen={setOpen} userdetails={userdetails} />
        )}
      </div>
    </div>
  );
};

export default VoedingForm;
