import {
  faCheckCircle,
  faList12,
  faListCheck,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useLayoutEffect, useState } from "react";
import { getUser } from "../../Localstorage/localstorage";
import RPModal from "../../Modal/RPModal";
import { AXIOS_GET } from "../../server/api/crud";
import PreIntakeForm from "./preintake/PreIntakeForm";

function PreIntake({ swipperRef, userdetails,user, navigation }) {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    how_found: "",
    age: 0,
    weight: 0.0,
    height: 0,
    occupation: "",
    sport_hours: "",
    other_activity_hours: "",
    environment: "",
    household: "",
    children: "",
    shopping: "",
    shopping_places: "",
    cooking: "",
    menu_decision: "",
    help_needed: "",
    belang_meest_hulp: "",
    main_complaint: "",
    other_complaints: "",
    complaint_start: "",
    personal_expectations: "",
    expect_fr_diet_orth: "",
    willingness_to_change: "",
    past_attempts: "",
    worked_well: "",
    worked_not_well: "",
    future_changes: "",
    obstacles: "",
    diet_habits_rating: "",
    health_conditions: "",
    other_diseases: "",
    family_conditions: "",
    medications_used: "",
    current_medications: "",
    vaccinations: "",
    sports_injuries: "",
    allergies: "",
    breathing: "",
    sleep_quality: "",
    average_sleep_hours: "",
    sleep_rating: null,
    sleep_issues: "",
    regular_bowel_movement: "",
    foreign_infections: "",
    birth_method: "",
    breastfeeding: "",
    food_reactions: "",
    gut_cleanings: "",
    dental_work: "",
    amalgam_fillings: "",
    contraceptives: "",
    smoking: "",
    alcohol: "",
    coffee: "",
    food_cravings: "",
    food_addictions: "",
    other_addictions: "",
    stress_level: "",
    stress_handling: "",
    mental_issues: "",
    issueses: "",
    emotional_flexibility: "",
    personal_remarks: "",
  });

  useLayoutEffect(() => {
    const fetchData = async (p_id) => {
      try {
        const response = await AXIOS_GET(`preintake/${p_id}`);
        if (response.data.data[0]) setFormData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (userdetails) {
      const { user_id } = userdetails;
      fetchData(user_id);
      //Redirect to home page if userdetails is already logged in
    }
  }, [open]);
  return (
    <div className="flex justify-center items-center mt-16">
      {formData.status == "current" ? (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div
            className={`ml-8 text-4xl bg-green-50 flex items-center justify-center text-green-800`}
          >
            <FontAwesomeIcon icon={faListCheck} />
          </div>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Het pre-intakeformulier is al ingevuld. Wilt u nog aanpassingen
            maken? Klik op deze ruimte
          </span>
        </button>
      ) : formData.status == "completed" ? (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div
            className={`ml-8 text-4xl flex items-center justify-center text-green-800 mb-8`}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </div>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Het pre-intakeformulier is ingevuld en verstuurd.
          </span>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Vervolgens als u verdergaan wilt gaan met het invullen van de vragenlijsten? Klik dan
            bovenaan op de tab 'Stap 3: Klachtenregistratieformulier'.
          </span>
        </button>
      ) : (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-100"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 2H32C33.1 2 34 2.9 34 4V44C34 45.1 33.1 46 32 46H12C10.9 46 10 45.1 10 44V4C10 2.9 10.9 2 12 2Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 2H24L34 12H10V2Z"
            />
          </svg>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Vul uw pre-intakeformulier in door op deze ruimte te klikken.
          </span>
        </button>
      )}
      <RPModal
        setOpen={setOpen}
        forms={formData}
        open={open}
        title={"Pre-intake"}
        Component={
          <PreIntakeForm
            userdetails={userdetails}
            setOpen={setOpen}
            slider={swipperRef}
            formData={formData}
            setFormData={setFormData}
          />
        }
      />
    </div>
  );
}

export default PreIntake;
