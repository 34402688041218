import React from "react";
import { benamingKlachten, benamingKlachtenObject } from "../../klantenregister";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faList } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useNavigation } from "react-router-dom";

const RPProgressbarVragenlijst = ({
  preintake,
  klachtenregistratie,
  voedingsdagboek,
}) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate("/healthforms")}
      className="p-8 flex-1 bg-white dark:bg-transparent border border-gray-200 rounded shadow-md flex flex-col items-center gap-4 cursor-pointer"
    >
      <label
        className="text-xl text-gray-600 dark:text-gray-100"
        htmlFor="time-filter"
      >
        Vragenlijsten
      </label>
      <div className="p-4 bg-white dark:bg-transparent rounded flex flex-col items-center w-full">
        <nav aria-label="Progress" className="grid grid-cols-2 w-full">
          <div>
            <p className="text-sm font-bold">{"Preintake formulier"}</p>
            <p className="text-sm font-medium text-gray-400">
              Ingevuld{" "}
              {Object.entries(preintake).reduce(
                (sum, [key, value]) =>
                  sum + (value != null && value !== "" ? 1 : 0),
                0
              )}{" "}
              van de {Object.keys(preintake).length} vragen
            </p>
          </div>

          <ol
            role="list"
            className="ml-8 flex items-center gap-2 grid grid-cols-10"
          >
            {Object.entries(preintake).map(([key, value]) => (
              <li key={key}>
                {value != null ? (
                  <a
                    href={"#"}
                    className="block h-3 w-3 rounded-full bg-green-600 hover:bg-green-900"
                  >
                    <span className="sr-only">{key}</span>
                  </a>
                ) : (
                  <a
                    href={"#"}
                    className="block h-3 w-3 rounded-full bg-gray-200 hover:bg-gray-400"
                  >
                    <span className="sr-only">{key}</span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="p-4 bg-white dark:bg-transparent rounded flex flex-col items-center w-full">
        <nav aria-label="Progress" className="grid grid-cols-2 w-full">
          <div>
            <p className="text-sm font-bold">{klachtenregistratie.title}</p>
            <p className="text-sm font-medium text-gray-400">
              Ingevuld{" "}
              {
                Object.entries(klachtenregistratie.lijst).filter(
                  ([key, value]) => value.length > 0
                ).length
              }{" "}
              van de {Object.keys(klachtenregistratie.lijst).length} Categorieen
            </p>
          </div>
          <ol
            role="list"
            className="ml-8 flex items-center gap-2 grid grid-cols-6 "
          >
            {Object.entries(klachtenregistratie.lijst).map(([key, value]) => (
              <li key={key}>
                {value.length > 0 ? (
                  <div
                    title={benamingKlachtenObject[key]?.link}
                    className="block h-2.5 w-2.5 rounded-full bg-green-600 hover:bg-green-900"
                  >
                    <span >{}</span>
                  </div>
                ) 
                 : (
                  <div
                  title={benamingKlachtenObject[key]?.link}
                  className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
                  >
                    <span className="sr-only">{key}</span>
                  </div>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="p-4 bg-white dark:bg-transparent rounded flex flex-col items-center w-full">
        <nav aria-label="Progress" className="grid grid-cols-2 w-full">
          <div>
            <p className="text-sm font-bold">{"VoedingsDagboek formulier"}</p>
            <p className="text-sm font-medium text-gray-400">
              Ingevuld {voedingsdagboek?.status} van de 3 vragen
            </p>
          </div>

          <ol
            role="list"
            className="ml-8 flex items-center gap-4 grid grid-cols-3"
          >
            {Array.from({ length: 3 }, (_, i) => (
              <li key={i}>
                {i <= voedingsdagboek?.status ? (
                  <a
                    href={"#"}
                    className="block h-10 w-10 rounded-full bg-green-600 hover:bg-green-900"
                  >
                    <span className="sr-only">{i}</span>
                  </a>
                ) : (
                  <a
                    href={"#"}
                    className="block h-10 w-10 rounded-full bg-gray-200 hover:bg-gray-400"
                  >
                    <span className="sr-only">{i}</span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
};

export default RPProgressbarVragenlijst;
