import { Radio, RadioGroup } from '@headlessui/react'
import { CalendarDaysIcon } from '@heroicons/react/20/solid'
import React from 'react'

  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const FilterNavigation = ({options, setSelectedRadio,selectedRadio}) => {
  return (
    <fieldset >
    <RadioGroup value={selectedRadio} onChange={setSelectedRadio} className="mt-4 flex items-center space-x-3 mb-4">
      {options.map((option) => (
        <Radio
          key={option.name}
          value={option}
          aria-label={option.name}
        >
             {({ checked, focus }) => (
          <div
          className={classNames(
            checked || option.value == selectedRadio ? "bg-gray-700 text-white " : "bg-white text-gray-700 ",
            "h-10 w-10 rounded-full p-2 border-opacity-10"
          )}
            aria-hidden="true"
          >
            <option.icon  />
          </div>)}
        </Radio>
      ))}
    </RadioGroup>
  </fieldset>
  )
}

export default FilterNavigation