import { Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SmallSideBar = ({
  Fragment,
  navigation,
  user,
  location,
  sidebarOpen,
  setSidebarOpen,
}) => {

  const [showDetails, setshowDetails] = useState(false);

  const { mailRooms } = useSelector((state) => state.mail);


  function getOpenMail () {
    var totalSum = 0;

    if(mailRooms.length > 0){
        totalSum = mailRooms.reduce((sum, item) => {
        
        if(item.receiver_id != user?.id)
          return 0;
        
        return sum + (item.sum_status || 0)}, 0);
    }
    return totalSum;
  }

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 lg:hidden"
        onClose={() => setSidebarOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button
                  type="button"
                  className="-m-2.5 p-2.5"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
            <div className="flex h-16 shrink-0 items-center justify-center">
              <img
                className="h-10 w-auto"
                src="https://sam.xjy.mybluehost.me/wp-content/uploads/2024/03/repeatconnect2.png"
                alt="Repeathealth"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="-mx-2 flex-1 space-y-1">
              {navigation(user,getOpenMail()).map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive || location.pathname.startsWith(item.href)
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:text-white hover:bg-gray-900",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )
                    }
                  >
                  {item.href == "/mailbox" ? (
                      <div className="flex-1 flex gap-x-3 relative">
                        {item?.count > 0 && <div className="bg-red-900 px-1.5 py-0.5 text-xs font-medium text-red-200 rounded absolute  -top-2 -left-3 p-1">
                          {item?.count}
                        </div>}

                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        <span className="relative">{item.name}</span>
                      </div>
                    ) : (
                      <div className="flex-1 flex gap-x-3">
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </div>
                    )}
                    {item?.children && (
                      <>
                        {showDetails ? (
                          <button
                            title="Sluit de details"
                            className="  p-1 rounded-full"
                            onClick={() => setshowDetails(false)}
                          >
                            {" "}
                            <ChevronDownIcon className="h-4 w-4 shrink-0" />{" "}
                          </button>
                        ) : (
                          <button
                            title="Open voor details"
                            className=" p-1 rounded-full"
                            onClick={() => setshowDetails(true)}
                          >
                            {" "}
                            <ChevronRightIcon className="h-4 w-4 shrink-0" />{" "}
                          </button>
                        )}
                      </>
                    )}
                  </NavLink>
                  {item?.children && showDetails && (
                    <ul className="ml-4 space-y-1">
                      {item.children.map((child) => (
                        <li key={child.name}>
                          <NavLink
                            to={child.href}
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )
                            }
                          >
                            <child.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {child.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              </ul>
            </nav>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SmallSideBar;
