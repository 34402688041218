export const emailHTML = (emailData) => (`
<!DOCTYPE html>
<html lang="nl">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Bevestiging Registratie Repeat Connect</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
    }
    h1 {
      color: #4CAF50;
    }
    .details {
      margin: 20px 0;
    }
    .button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      text-decoration: none;
      border-radius: 5px;
    }
    .footer {
      margin-top: 20px;
      font-size: 12px;
      color: #666;
    }
  </style>
</head>
<body>
  <div class="container">
    <h1>Gefeliciteerd!</h1>
    <p>Beste ${emailData?.name},</p>
    <p><i><b>Welkom bij Repeat Connect!</b></i></p>
    <p>We bevestigen hierbij dat uw registratie bij Repeat Connect succesvol is afgerond. U heeft nu toegang tot ons digitale platform.</p>
    <h4>Uw inloggegevens:</h4>
    <div class="details">
      <p><strong>Gebruikersnaam:</strong> ${emailData?.gebruikersnaam}</p>
      <p><strong>Wachtwoord:</strong> ${emailData?.wachtwoord}</p>
    </div>
    <p>Klik op onderstaande knop om in te loggen:</p>
    <p><a href="${emailData.inloglink}" class="button">Inloggen</a></p>
    <div class="footer">
      <p>Dit is een automatisch gegenereerde e-mail. Beantwoord dit bericht niet.</p>
    </div>
  </div>
</body>
</html>
`);