import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AXIOS_DELETE, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import moment from "moment";
import {
  setAppointmentDelete,
  setAppointmentUpdate,
} from "../../redux/appointmentSlice";
import CrudEvent from "./CrudEvent";
import { CalendarDateRangeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import RP_Avatar from "../RP_Avatar";
import CrudEventCalendlyEdit from "./CrudEventCalendlyEdit";


export default function ShowInfoAfspraak({
  open,
  setOpen,
  evenment = null,
  select,
  user,
}) {
  const [event, setEvent] = useState(evenment);
  const [openModal, setOpenModal] = useState(false);
  const [openModalWijzig, setOpenModalWijzig] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [gebruiker, setGebruiker] = useState(null);
  const { patienten } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (evenment) {
      setEvent(evenment);
      let users = patienten.find(
        (user) => user?.email == evenment?.invitees[0]?.email
      );
      setGebruiker(users);
    }
  }, [evenment]);

  // Delete an event
  const handleDelete = async (event) => {
    await AXIOS_DELETE(`afspraak`, event?.id, event).then((respons) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Afspraak is succesvol verwijderd`,
        showConfirmButton: false,
        timer: 2500,
      });
      setOpen(false);
      dispatch(setAppointmentDelete(event));
    });
    // dispatch(setAppointment(event));
  };

  return (
    <>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />
        <div
          id="calenderberton"
          className="fixed inset-0 bg-gray-900 bg-opacity-75 z-10 w-screen overflow-y-auto"
        >
          <div className="flex events-end justify-center p-4 mt-20 text-center sm:events-center sm:p-0">

            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg dark:bg-gray-800 bg-white  px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >

              <div className={` mx-auto p-4`}>
              <XMarkIcon onClick={() => setOpen(false)} className="absolute h-10 w-10 right-2 top-4 text-white" />

                <h1 className="text-2xl font-semibold mb-4 dark:text-gray-100">
                  Afspraak
                </h1>

                <div
                  className="border rounded border-gray-700 p-2"
                  key={event?.calendar_event?.external_id}
                >
                  <CalendarDateRangeIcon className="w-10 h-10 text-gray-500" />
                  <p className="dark:text-white flex events-center items-center">
                    <div className="w-2 h-2 bg-green-500 rounded-full mr-2" />{" "}
                    {event?.name}
                  </p>
                  <p className="dark:text-white">
                    {moment(event?.start_time).format("LL")}
                  </p>
                  <time
                    dateTime={event?.start_time}
                    className="dark:text-white italic text-sm"
                  >
                    {moment(event?.start_time).format("HH:mm")} -{" "}
                  </time>
                  <time
                    dateTime={event?.end_time}
                    className="dark:text-white italic text-sm"
                  >
                    {moment(event?.end_time).format("HH:mm")}
                  </time>
                  <div className="cursor-pointer flex items-center justify-between border rounded border-gray-600 p-2 m-4">
                    {event?.invitees?.map((invitee, index) => (
                      <div
                        title={invitee?.questions_and_answers[0]?.answer}
                        key={index}
                      >
                        <p className="dark:text-white text-sm italic">Klant</p>
                        <p className="dark:text-white text-sm">
                          {invitee?.name}
                        </p>
                        <p className="dark:text-white text-xs">
                          {invitee?.email}
                        </p>
                      </div>
                    ))}

                    <RP_Avatar avatar={gebruiker?.avatar} size={20} />
                  </div>
                  <div className="flex items-center justify-between border border-green-500 rounded p-2 m-4">
                    {event?.creators?.map((creator, index) => (
                      <div key={index}>
                        <p className="dark:text-green-500 text-sm italic">
                          Specialist
                        </p>
                        <p className="dark:text-white text-sm">
                          {creator?.user_name}
                        </p>
                        <p className="dark:text-white text-xs">
                          {creator?.user_email}
                        </p>
                      </div>
                    ))}
                    <RP_Avatar avatar={select?.avatar} size={20} />
                  </div>
                </div>
              </div>

              {moment().format("DD/MM/YYYY HH:MM") <
                moment(event?.start_time).format("DD/MM/YYYY HH:MM") && (
                <>
                  <div className="flex justify-between">
                    {event && event?.invitees?.length && (
                      <button
                      onClick={(()=>setOpenCancelModal(true))}
                        className="z-40 bg-gray-700 text-white mr-2 mt-2 rounded mr-4 py-2 px-4"
                        >Afspraak Annuleren</button>
                    )}
                    {event && event?.invitees?.length && (
                      <button 
                      onClick={(()=>setOpenModalWijzig(true))}
                      className="bg-green-800 text-white mr-2 mt-2 rounded mr-4 py-2 px-4">
                        Afspraak Verplaatsen
                      </button>
                    )}
                  </div>
                  {openCancelModal && (
                    <CrudEventCalendlyEdit 
                    openModel={openCancelModal} 
                    setOpenModel={setOpenCancelModal}
                    link={event?.invitees[0]?.cancel_url}
                    user={select}
                    />
                  )}
                  {openModalWijzig && (
                    <CrudEventCalendlyEdit 
                    openModel={openModalWijzig} 
                    setOpenModel={setOpenModalWijzig}
                    link={event?.invitees[0]?.reschedule_url}
                    user={select}
                    />
                  )}
                </>
              )}
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
