import React, { useCallback, useRef, useState } from "react";
import {
  DocumentArrowUpIcon,
  PhotoIcon,
} from "@heroicons/react/24/outline";
import Swal from "sweetalert2";
import {
  CloudArrowDownIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/20/solid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { AXIOS_UPDATE } from "../../server/api/crud";
import { getUser } from "../../Localstorage/localstorage";

const UploadBestand = ({formData, setformData }) => {
  const user = getUser();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const onValueChangeFile = useCallback(
    async (event) => {
      const item = event.target;
      const selectedFile = item.files[0];
      
      try {
        // Create a reference to Firebase Storage with the user's name and file name
        const fileRef = ref(
          storage,
          `${user?.username}-${user?.id}/${selectedFile.name}`
        );

        // Upload the file to Firebase Storage
        await uploadBytes(fileRef, selectedFile);

        // Get the file's URL after upload
        const fileURL = await getDownloadURL(fileRef);

        // Example: Call a function or endpoint to save the fileURL in your database
        await setformData(prev => ({...prev, url:fileURL}));
       // await saveFileURLToDatabase(fileURL);
        Swal.fire(`Bestand ${selectedFile?.name} is opgeladen`, "", "success");
      } catch (error) {
        Swal.fire("Upload failed", "", "error");
      } finally {
        setLoading(false);
      }
    },
    [user]
  );

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  /* const saveFileURLToDatabase = async (fileURL) => {
    try {
      // Example using an Axios call to your backend to save the file URL with formData
      let newObject = {...formData, url:fileURL};
      let id = formData.id;

      await AXIOS_UPDATE(linkbackend, id, newObject);
      console.log("File URL saved successfully");
    } catch (error) {
      console.error("Error saving file URL to database:", error);
    }
  }; */

  return (
    <div className="inline-flex mx-2">
      <input
        type="file"
        id="bestand"
        name="bestand"
        accept="image/*"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={onValueChangeFile}
      />
      <div
        type="button"
        onClick={handleButtonClick}
        className="dark:bg-gray-700 dark:text-white inline-flex items-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {formData?.url ? (
          <span className="flex gap-x-2">
            Afbeelding gelinkt
            <PhotoIcon className="h-5 w-5" />
          </span>
        ) : (
          <div className="flex gap-x-2">
            Afbeelding
            <DocumentArrowUpIcon className="h-5 w-5" />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadBestand;
