import React, { useState } from "react";
import { AXIOS_DELETE } from "../../server/api/crud";
import DashboardDocumenten from "../../Componenten/DashboardDocumenten";
import UploadDocumenten from "../../Componenten/Calender/UploadDocumenten";
import FilterNavigation from "../../Componenten/Dashboard/FilterNavigation";
import { DocumentIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import FileCard from "../../Componenten/MiniCards/FileCard";
import Swal from "sweetalert2";
import { deleteObject, ref } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import FileCardAdvies from "../../Componenten/MiniCards/FileCardAdvies";
import FileCardPlans from "../../Componenten/MiniCards/FileCardPlans";

const filters = [
  { value: 2, name: "block", icon: DocumentIcon, color: "text-green-500" },
  { value: 1, name: "list", icon: TableCellsIcon, color: "text-green-500" },
];

const DocumentClient = ({
  userdetails,
  attachments,
  setRefresh,
  type = "uploads",
}) => {
  const [view, setView] = useState(filters[0]);
  const [open, setOpen] = useState(false);

  const deleteDocument = async (item) => {
    const result = await Swal.fire({
      title: "Bent u zeker deze document te willen verwijderen",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Ja",
      denyButtonText: "Neen",
      confirmButtonColor: "red",
      denyButtonColor: "gray",
    });

    const id = item.id;
    if (result.isConfirmed) {
      await AXIOS_DELETE("documents", id)
        .then((result) => {
          if (result.status === 200) {
            // Create a reference to the file to delete
            const fileRef = ref(storage, item?.url);

            // Delete the file from Firebase Storage
            deleteObject(fileRef);
            setRefresh(true);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Bestands zijn goed verwijderd`,
              showConfirmButton: false,
              timer: 2500,
            });
            
          }
        })
        .catch((e) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `er is iets mis gelopen`,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    }
  };


  return (
    <div className="mx-auto max-w-6xl pt-10 h-full dark:bg-gray-800 h-dvh">
      <div className="px-8 flex items-center justify-between">
        <FilterNavigation
          selectedRadio={view}
          setSelectedRadio={setView}
          options={filters}
        />
          <button
              type="button"
              onClick={() => setOpen(true)}
              className="block rounded-md bg-green-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Voeg een document toe
            </button>
      </div>
      {view?.value === 1 ? (
        <DashboardDocumenten
          attachments={attachments}
          setRefresh={setRefresh}
          deleteDocument={deleteDocument}
          setOpenModal={setOpen}
          user={userdetails}
        />
      ) : (
        <div className="grid grid-cols-3 xxl:grid-cols-4 p-4">
          {attachments?.length > 0
            ? attachments.map((item) => (
                <>
                  {type === "uploads" ? (
                    <FileCard item={item} deleteDocument={deleteDocument} userdetails={userdetails} />
                  ) : type === "advies" ? (
                    <FileCardAdvies item={item} deleteDocument={deleteDocument} userdetails={userdetails} />
                  ) : (
                    <FileCardPlans item={item} deleteDocument={deleteDocument} userdetails={userdetails} />
                  )}
                </>
              ))
            : <div className="text-center p-8 dark:bg-gray-800 w-full dark:text-white bg-gray-100  border-gray-300 w-full grid grid-cols-1">
            <p>Er zijn adviezen documenten gevonden</p>
          </div>}
        </div>
      )}
      <UploadDocumenten
        setRefresh={setRefresh}
        attachments={attachments}
        user={userdetails}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default DocumentClient;
