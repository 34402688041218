import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import UserInfo from "./UserInfo";
import Swal from "sweetalert2";
import moment from "moment";

import { AXIOS_UPDATE, AXIOS_GET } from "../../server/api/crud";

const UserDialog = ({ user, open, setOpen, navigate }) => {
  const cancelButtonRef = useRef(null);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    full_name: "",
    date_of_birth: "",
    phone_number: "",
    cellphone_number: "",
    avatar: "",
    address_full: "",
    street: "",
    housenr: "",
    more_details: "",
    klacht: "",
    beroep: "",
    country: "",
    country_code: "",
    city: "",
    zipcode: "",
    email: "",
  });

  const [isFetched, setisFetched] = useState(false)

  const setData = (obj) => {
    return {
      address_full: obj?.address_full || "",
      avatar: obj?.avatar || "",
      cellphone_number: obj?.cellphone_number || "",
      city: obj?.city || "",
      country: obj?.country || "",
      country_code: obj?.country_code || "",
      date_of_birth: obj?.date_of_birth || "",
      email: obj?.email || "",
      first_name: obj?.first_name || "",
      full_name: obj?.full_name || "",
      housenr: obj?.housenr || "",
      klacht: obj?.klacht || "",
      last_name: obj?.last_name || "",
      more_details: obj?.more_details || "",
      phone_number: obj?.phone_number || "",
      role_id: obj?.role_id || "",
      status_id: obj?.status_id || "",
      street: obj?.street || "",
      zipcode: obj?.zipcode || "",
    };
  };

  const fetchData = async () => {
    try {
      await AXIOS_GET(`users/${user?.id}`).then((res) => {
        if (res.data.data.length > 0) {
          const userDatas = res.data.data[0];
          const getData = setData(userDatas);
          setFormData(getData);
        }
      });
    } catch (error) {
      alert("Er is een fout opgetreden bij het registreren");
      console.error("Error during sign-up:", error);
    }
  };

  useLayoutEffect(() => {
    (async () => {
      if (!isFetched) {
        await fetchData();
        setisFetched(true)
      }
    })();
  }, []);

  const [errors, setErrors] = useState(null); // State to hold validation errors

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the value is null or empty
    const newValue = value; // Remove leading and trailing spaces
    /* const isEmpty = name !== "avatar" && newValue === "" && newValue !== "more_details";

    if(errors[name] == " " && formData[name] !== " "){
      setErrors({});
    }*/

    const address = `${formData.zipcode} ${formData.city}  ${formData.street} ${formData.housenr}`;

    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
      address_full: address,
      full_name: `${formData.first_name} ${formData.last_name}`,
    }));

    // Perform validation based on input name
    //let error = "";

    /*if (isEmpty) {
      error = "Value cannot be empty";
      setErrors({ ...errors, [name]: error });
    }  else {
      switch (name) {
        case "phone_number":
        case "cellphone_number":
          error = !/^\d{10,}$/i.test(newValue) ? "Invalid phone number" : "";
          break;
        case "country_code":
          error = !/^\+\d{1,3}$/i.test(newValue) ? "Invalid country code" : "";
          break;
        case "zipcode":
          error = !/^\d{5,}$/i.test(newValue) ? "Invalid zipcode" : "";
          break;
        // Add more validations for other fields as needed
        default:
          break;
      }
    } */
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      //const hasErrors = Object.values(errors).some((error) => error);

      if (
        (formData.first_name !== "" ||
          formData.first_name !== null ||
          formData.first_name !== undefined) &&
        (formData.last_name !== "" ||
          formData.last_name !== null ||
          formData.last_name !== undefined)
      ) {

        const newObject = formData;
        newObject.full_name = `${newObject?.first_name} ${newObject?.last_name}`;
        newObject.date_of_birth = moment(formData?.date_of_birth).format('YYYY-MM-DD');
       
        await AXIOS_UPDATE("users", user.id, newObject).then((res) => {
          if ((res.data.data.affectedRows = 1)) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Bedankt voor het bij vullen van de gegevens.`,
              showConfirmButton: false,
              timer: 2500,
            });

            setOpen(false);
          }
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `Gelieve de vereiste invoerveld in te vullen`,
          showConfirmButton: false,
          timer: 2500,
        });
      }

      // }
    } catch (error) {
      alert("Er is een fout opgetreden bij het registreren");
      console.error("Error during sign-up:", error);
    }
    // Check if there are any validation errors
  };



  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all z-10 sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <UserInfo
                    formData={formData}
                    setFormData={setFormData}
                    handleChangeInput={handleChange}
                  />
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row">
                    <button
                      onClick={() => setOpen(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-4 py-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSubmit}
                      className="inline-flex w-full justify-center rounded-md bg-blue-800 px-4 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-300 sm:ml-3 sm:w-auto"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UserDialog;
