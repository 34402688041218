import React, { useLayoutEffect, useState } from "react";
import { AXIOS_GET } from "../../server/api/crud";
import { getUser } from "../../Localstorage/localstorage";
import FilterNavigation from "../../Componenten/Dashboard/FilterNavigation";
import { CreditCardIcon, TableCellsIcon } from "@heroicons/react/20/solid";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import DashboardlistPatient from "../../Componenten/Dashboard/DashboardlistPatient";
import DashboardPatientCards from "../../Componenten/Dashboard/DashboardPatientCards";
import DashboardPatientCards2 from "../../Componenten/Dashboard/DashboardPatientCards2";
import { useNavigate } from "react-router-dom";
import SelectDetailsPatient from "../../Componenten/SelectDetailsPatient";
import { fetchAllUsersDetails } from "../../apiCalls/usersApi";

const filters = [
  { value:1,name: 'list', icon : TableCellsIcon,color: 'text-green-500' },
  { value:2,name: 'block', icon : Squares2X2Icon,color: 'text-green-500' },
 // { value:3,name: 'card', icon : CreditCardIcon,color: 'text-green-500' },
]

const DashboardAdmin = () => {
const [countKl, setcountKl] = useState(null);
  const [patienten, setPatienten] = useState(null);
  const [selectFilter, setselectFilter] = useState(2);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const user = getUser();
  const navigate = useNavigate();


  const goToDetails = (item) => {
    // Pass parameters here
    navigate('/dashboarddetail', { state: { user: item } });

};

useLayoutEffect(() => {
    
    if (user) {
      const { id } = user;
      fetchAllUsersDetails(8, 0, '', setLoading, setError, setPatienten ); 
      //Redirect to home page if user is already logged in
    }

  }, []);



  const setSelectedRadio = (e) => {
      setselectFilter(e.value);    
  }

  return (
    <div className="bg-gray-100 px-4 min-h-screen h-fit dark:bg-gray-800 dark:text-gray-100">
      <div className="flex justify-between gap-x-8">
        <FilterNavigation setSelectedRadio={setSelectedRadio} selectedRadio={selectFilter} options={filters}/>
        <SelectDetailsPatient  data={patienten} setData={setPatienten} setLoading={setLoading} setError={setError}/>
      </div>
      {selectFilter == 2 ? (
        <DashboardPatientCards goToDetails={goToDetails}  patienten={patienten}/>
      ) : selectFilter == 1 ? (<DashboardlistPatient goToDetails={goToDetails} patienten={patienten}/>) : (<DashboardPatientCards2 goToDetails={goToDetails} patienten={patienten}/>)
    }
    </div>
  );
};

export default DashboardAdmin;
