import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import {
  CheckBadgeIcon,
  ClockIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import RPModal from "../../../Modal/RPModal";
import { useCallback, useLayoutEffect, useState } from "react";
import {
  getKlacht,
  getStates,
  getUser,
  getUserdetails,
  setStates,
  setVoedingID,
} from "../../../Localstorage/localstorage";
import RpKlachtenRegisterForm from "../../klachtenregister/RpKlachtenRegisterForm";
import { AXIOS_GET } from "../../../server/api/crud";
import { KlachtenValues } from "../../../klantenregister";
import PreIntakeForm from "../preintake/PreIntakeForm";
import VoedingForm from "../voeding/VoedingForm";

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};
const clients = (object) => [
  {
    id: 1,
    name: "Pre-Intake",
    imageUrl: "https://tailwindui.com/plus/img/logos/48x48/tuple.svg",
    icon: ClockIcon,
    iconForeground: "dark:text-gray-50 text-teal-700",
    iconBackground: "dark:bg-gray-800 bg-teal-50",
    status: 1 + object?.preintake_verstuur,
  },
  {
    id: 2,
    name: "Klachtenregistratie formulier",
    imageUrl: "https://tailwindui.com/plus/img/logos/48x48/savvycal.svg",
    icon: CheckBadgeIcon,
    iconForeground: "dark:text-gray-50 text-purple-700",
    iconBackground: "dark:bg-gray-800 bg-purple-50",
    status: object?.klacht_verstuur + object?.preintake_verstuur,
  },
  {
    id: 3,
    name: "Voedingsdagboek",
    imageUrl: "https://tailwindui.com/plus/img/logos/48x48/reform.svg",
    icon: UsersIcon,
    iconForeground: "dark:text-gray-50 text-sky-700",
    iconBackground: "dark:bg-gray-800 bg-sky-50",
    status: object?.voeding_verstuur + object?.klacht_verstuur,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InfoCards({ vragenlijst, user }) {
  const [openPreIntake, setOpenPreIntake] = useState(false);
  const [openklacht, setOpenKlacht] = useState(false);
  const [openVoeding, setOpenVoeding] = useState(false);
  const [formData, setFormData] = useState(null);
  const [formDataVoeding, setFormDataVoeding] = useState(null);

  const admin = getUser();
  const adimn_details = getUserdetails();

  const [klachten, setKlachten] = useState(null);
  const [state, setState] = useState(null);
  const [klacht, setKlacht] = useState(0);
  const klachtslocal = getKlacht();
  const status = getStates();

  const openModal = (id) => {
    if (id == 1) {
      setOpenPreIntake(true);
    } else if (id == 2) {
      setOpenKlacht(true);
    } else if (id == 3) {
      setOpenVoeding(true);
    }
  };

  useLayoutEffect(() => {
    let kl = {};
    let st = {};
    if (user) {
      const fetchDataKlacht = async (id) => {
        try {
          const response = await AXIOS_GET(`klachtenregistratie/all/${id}`);
          // Loop through the object properties

          const dataKlachten = response?.data;
          let newKlacht = {};

          Object.entries(dataKlachten).map(([key, value]) => {
            let remove = key
              .replace("Data", "")
              .replace("ontlastingstoelgang", "ontlasting_stoelgang");
            if (value.length > 0) {
              newKlacht[remove] = value[0];
            } else {
              newKlacht[remove] = KlachtenValues(id)[remove];
            }
          });
          setKlachten(newKlacht);
        } catch (error) {
          setKlachten(KlachtenValues(id));
          console.error("Error fetching data:", error);
        }
      };

      const fetchdata = async () => {
        await AXIOS_GET(`klachtenregistratie/${user?.user_id}`)
          .then((resklacht) => {
            if (resklacht.data.length > 0) {
              kl = resklacht.data[0];
              st = { id: resklacht.data[0]?.status };
              setKlacht(kl);
              setState(st);
              setStates(st);
              fetchDataKlacht(kl?.id);
            } else {
              setKlacht(klachtslocal);
              setState(status);
              setStates(status);
              fetchDataKlacht(klachtslocal?.id);
            }
          })
          .catch(() => {
            setState({ id: 0 });
            fetchDataKlacht(0);
          });
      };

      fetchdata();
    }

    const fetchDataPreIntake = async (p_id) => {
      try {
        const response = await AXIOS_GET(`preintake/${p_id}`);
        if (response.data.data[0]) setFormData(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    
    fetchDataPreIntake(user?.user_id);


    const fetchDataVoeding = async (p_id) => {
      try {
        const response = await AXIOS_GET(`voedsel/${p_id}`);
        if (response.data.data[0]) setFormDataVoeding(response.data.data[0]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataVoeding(user?.user_id)
  }, [user]);


  return (
    <div>
      <ul
        role="list"
        className="mt-8 grid grid-cols-1 cursor-pointer gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
      >
        {clients(vragenlijst).map((client) => (
          <li
            key={client.id}
            {...(client.status > 0) && {onClick:() => openModal(client?.id)}}
            className="overflow-hidden rounded-xl border border-gray-200 bg-white dark:bg-gray-600"
          >
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-white dark:bg-gray-600  p-6">
              <span
                className={classNames(
                  client.iconBackground,
                  client.iconForeground,
                  "inline-flex rounded-lg p-3"
                )}
              >
                <client.icon className="h-6 w-6" aria-hidden="true" />
              </span>
              <div className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-200">
                {client.name}
              </div>
            </div>
            <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
              <div className="flex justify-between gap-x-4 py-3">
                <dt className="text-gray-500 dark:text-gray-200">Status:</dt>
                <dd className="text-gray-700">
                  <span
                    className={classNames(
                      client.status == 2
                        ? "dark:text-green-500 text-green-800"
                        : client.status == 1
                        ? "dark:text-yellow-500 text-yellow-800"
                        : "dark:text-red-500 text-red-800"
                    )}
                  >
                    {client.status == 2
                      ? "Verstuurd"
                      : client.status == 1
                      ? "In verwerking"
                      : "Niet verstuurd"}
                  </span>
                </dd>
              </div>
            </dl>
          </li>
        ))}
      </ul>
      <RPModal
        setOpen={setOpenPreIntake}
        open={openPreIntake}
        Component={
          <PreIntakeForm
            userdetails={user}
            admin={adimn_details}
            setOpen={setOpenPreIntake}
            formData={formData}
            setFormData={setFormData}
          />
        }
      />
      <RPModal
        setOpen={setOpenKlacht}
        open={openklacht}
        Component={
          <RpKlachtenRegisterForm
            setOpen={setOpenKlacht}
            setKlachten={setKlachten}
            admin={admin}
            klachten={klachten}
            status={state}
            klacht={klacht}
          />
        }
      />

      <RPModal
        setOpen={setOpenVoeding}
        open={openVoeding}
        Component={
          <VoedingForm
            userdetails={user}
            admin={admin}
            setOpen={setOpenVoeding}
            formData={formDataVoeding}
            setFormData={setFormDataVoeding}
          />
        }
      />
    </div>
  );
}
