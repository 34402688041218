import { useState, useEffect, useCallback } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import "tailwindcss/tailwind.css";
import { getUser, getVooruitgang } from "../../Localstorage/localstorage";
import moment from "moment";
import { AXIOS_GET, AXIOS_POST } from "../../server/api/crud";
import Swal from "sweetalert2";
import { vooruitgangTabs } from "../../function";

// Register Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend
);

function checking(metric, metricName) {
  const existingValues = metric[metricName] || [];
  const currentDate = moment().format("YYYY-MM-DD");

  // Use .findIndex() to check if an entry with the same date already exists
  const existingIndex = existingValues.findIndex(
    (entry) => entry.date === currentDate && entry.hasOwnProperty("created_at")
  );

  if (existingIndex !== -1) {
    // If the value with the current date is already present, update its score
    return true;
  }

  return false;
}

const EvolutionClient = () => {
  const user = getUser();
  const [metricData, setMetricData] = useState({
    slaap: [],
    energie: [],
    stress: [],
    cravings: [],
  });

  const vooruitgang = getVooruitgang();
  const tabs = vooruitgangTabs(user, vooruitgang);

  const [selectedMetric, setSelectedMetric] = useState(tabs[0]);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const fetchVooruitgangData = useCallback(async (p_id) => {
    try {
      const response = await AXIOS_GET(`vooruitgang/uservooruitgang/${p_id}`);
      if (response.status === 201) {
        const dataVooruitgang = {
          slaap: response.data.slaapDatas?.map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
          })),
          energie: response.data.energieDatas?.map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
          })),
          stress: response.data.stressDatas?.map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
          })),
          cravings: response.data.cravingsDatas?.map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
          })),
        };

        setMetricData(dataVooruitgang);
        if (dataVooruitgang.slaap > 0) {
          setSelectedMetric(selectedMetric || dataVooruitgang.slaap[0]);
        } else {
          setSelectedMetric(tabs[0]);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {
    fetchVooruitgangData(user?.id);
  }, [fetchVooruitgangData]);

  // Check if dark mode is active
  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(
        window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: light)").matches
      );
    };


    checkDarkMode();
    window.addEventListener("resize", checkDarkMode);

    return () => window.removeEventListener("resize", checkDarkMode);
  }, []);

  // Handle metric value changes and update state
  const handleMetricChange = (metricName, value) => {
    setSelectedMetric((prevData) => ({ ...prevData, score: value }));
    setMetricData((prevData) => {
      // Check if the value with the current date already exists for the given metricName
      const existingValues = prevData[metricName] || [];
      const currentDate = moment().format("YYYY-MM-DD");

      // Use .findIndex() to check if an entry with the same date already exists
      const existingIndex = existingValues.findIndex(
        (entry) => entry.date === currentDate
      );

      if (existingIndex !== -1) {
        // If the value with the current date is already present, update its score
  

        const updatedValues = existingValues.map((entry, index) =>
          index === existingIndex ? { ...entry, score: value } : entry
        );

        return {
          ...prevData,
          [metricName]: updatedValues,
        };
      }


      return {
        ...prevData,
        [metricName]: [...existingValues, { date: currentDate, score: value }],
      };
    });
  };

  // Chart options configuration
  const chartOptions = {
    color: isDarkMode ? "#4B5563" : "#374151", // Gray-300 for dark mode, Gray-700 for light mode
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: isDarkMode ? "#4B5563" : "white",
        },
        grid: {
          color: isDarkMode ? "#4B5563" : "#E5E7EB",
        },
      },
      y: {
        ticks: {
          color: isDarkMode ? "#4B5563" : "white",
        },
        grid: {
          color: isDarkMode ? "#4B5563" : "#E5E7EB",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: isDarkMode ? "#4B5563" : "white",
        },
      },
      tooltip: {
        callbacks: {
          // Customize the title of the tooltip
          title: function (tooltipItems) {
            return `${tooltipItems[0].label}`;
          },
          // Customize the label of the tooltip
          label: function (tooltipItem) {
            const index = tooltipItem?.dataIndex; // Get the index of the data point
            //const dataset = tooltipItem?.dataset; // Access the dataset
            const rawData = metricData[selectedMetric.key][index]; // Access the corresponding data object

            return `Score: ${tooltipItem?.raw} ${
              rawData?.opmerking ? " | Meer uitleg : " + rawData?.opmerking : ""
            }`;
          },
          // Optionally add footer
          footer: function (tooltipItems) {
            return `Extra Info: ${tooltipItems.length} points`;
          },
        },
        enabled: false, // Disable the default tooltip
        external: function (context) {
          // Create the custom tooltip element if it doesn't exist
          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.padding = "0px";
            tooltipEl.style.pointerEvents = "none";
            tooltipEl.style.transition = "all 0.2s ease";
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context?.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          // Set tooltip content
          if (tooltipModel?.body) {
            //const bodyLines = tooltipModel?.body.map((item) => item?.lines);
            const index = tooltipModel?.dataPoints[0]?.dataIndex; // Index of the point
            const rawData = metricData[selectedMetric.key][index]; // Custom data

            // Build HTML content
            let innerHtml = `
              <div class="text-xs" ><strong class="dark:text-white" >Date:</strong> <span class="dark:text-white">${tooltipModel?.title[0]}</span></div>
              <div class="text-xs flex items-center gap-x-1">
                <div class="bg-[#818CF833] dark:bg-[#34D39933] border-2 border-[#818CF8] dark:border-[#34D399] w-3 h-3 rounded"></div>  
                <strong class="dark:text-white">Score:</strong> <span class="dark:text-white">${tooltipModel?.dataPoints[0].raw}</span>
              </div>`;

            if (rawData?.uitleg) {
              innerHtml += `
              <div class="text-xs mt-2 dark:text-white"><strong>More Info:</strong> </div>
              <div class="text-xs dark:text-white">${rawData?.uitleg}</div>`;
            }

            tooltipEl.innerHTML =
              '<div class="max-w-56 p-2 bg-white rounded border-4 dark:border-[#34D39933]  border-[#818CF833] dark:bg-gray-700 shadow dark:bg-gray-800">' +
              innerHtml +
              "</div>";
          }

          // Set tooltip position
          const canvas = context.chart.canvas;
          const position = canvas.getBoundingClientRect();

          tooltipEl.style.opacity = "1";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX - 90 + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
        },
      },
    },
  };

  // Generate buttons for selectable metric values
  const generateButtons = (metricName) => {
    return Array.from({ length: 11 }, (_, index) => (
      <button
        key={index}
        onClick={() => handleMetricChange(metricName, index)}
        className={`px-4 py-2 mx-1 font-semibold rounded-md border ${
          selectedMetric.score === index
            ? "bg-green-600 text-white"
            : "bg-transparent dark:text-gray-100 text-gray-700 border-gray-300 hover:bg-green-100"
        }`}
      >
        {index}
      </button>
    ));
  };

  const Opslaan = async () => {
    let newobj = selectedMetric;
    newobj.date = moment(selectedMetric.date).format("YYYY-MM-DD");

    const checkingVooruitBestaat = checking(metricData, selectedMetric.key);

    if (!checkingVooruitBestaat) {
      await AXIOS_POST(`vooruitgang/details`, newobj)
        .then((respons) => {
          if (respons.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `${selectedMetric.naam} is opgeslagen`,
              showConfirmButton: false,
              timer: 2500,
            });
            setRefresh(true);
          }
        })
        .catch((e) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `Er is iets mis gelopen`,
            showConfirmButton: false,
            timer: 2500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `Er bestaat al een Score voor deze categorie op die datum`,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  // Tab content based on the selected metric

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md h-dvh w-full">
      {/* Tabs */}
      <div className="flex mb-4">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            className={`px-6 py-2 text-lg font-medium ${
              selectedMetric.key === tab?.key
                ? "border rounded dark:bg-gray-700 dark:text-white bg-green-200 text-green-800 shadow-md"
                : "dark:text-gray-200 text-gray-600 hover:text-green-500"
            }`}
            onClick={() => setSelectedMetric(tab)}
          >
            {tab.naam}
          </button>
        ))}
      </div>

      {/* Line Chart */}
      <div className="h-96 mb-2 pb-16 border p-4 rounded">
        <Line
          data={{
            labels: metricData[selectedMetric.key]?.map(
              (item) => `Dag ${moment(item.date).format("L")}`
            ),
            datasets: [
              {
                label: `${
                  selectedMetric.key.charAt(0).toUpperCase() +
                  selectedMetric.key.slice(1)
                } Niveau`,
                data: metricData[selectedMetric.key]?.map((item) => item.score),
                borderColor: isDarkMode ? "#818CF8" : "#34D399", // Indigo-400 for dark mode, Green-400 for light mode
                backgroundColor: isDarkMode
                  ? "rgba(129, 140, 248, 0.2)" // Lighter indigo for dark mode
                  : "rgba(52, 211, 153, 0.2)", // Lighter green for light mode
                fill: true,
                tension: 0.3,
              },
            ],
          }}
          options={chartOptions}
        />
      </div>

      {/* Metric Input Section */}
      <div className="bg-transparent flex">
        <div className="p-6 flex-2">
          <h3 className="text-lg font-semibold mb-2">
            {tabs.find((tab) => tab?.key === selectedMetric?.key)?.naam}
          </h3>
          <p className="text-sm text-gray-500 mb-3 dark:text-gray-100">
            {selectedMetric.key === "slaap"
              ? "0 = Slechte nachtrust, 10 = Geslapen als een roos"
              : selectedMetric.key === "energie"
              ? "0 = Geen energie, 10 = Zeer energiek"
              : selectedMetric.key === "stress"
              ? "0 = Geen stress, 10 = Extreme stress"
              : "0 = Goede verzadiging, 10 = Continue cravings"}
          </p>
          <div className="flex flex-wrap mb-4">
            {generateButtons(selectedMetric.key)}
          </div>
          <p className="text-sm mt-2">
            Huidige waarde: {selectedMetric?.score || 0}
          </p>
          <div className="flex space-x-2 mt-4">
            <button
              onClick={Opslaan}
              className="px-4 py-2 bg-green-800 text-white rounded-md hover:bg-green-600"
            >
              Opslaan
            </button>
          </div>
        </div>
        <div className="flex-1">
          <div className="p-6 flex-2">
            <h3 className="text-lg font-semibold mb-2">Aanvulling</h3>
            <textarea
              rows={6}
              name="uitleg"
              value={selectedMetric?.uitleg}
              onChange={(e) =>
                setSelectedMetric((prev) => ({
                  ...prev,
                  uitleg: e.target.value,
                }))
              }
              className="block w-full border-2 rounded dark:text-white  bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvolutionClient;
