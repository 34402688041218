
import React, { useEffect, useState } from 'react'
import RPStepperWithPage from '../../Componenten/Stepper/RPStepperWithPage'
import BeforePreIntake from './BeforePreIntake'
import PreIntake from './PreIntake'
import KlachtenRegisterFormulier from './KlachtenRegisterFormulier'
import VoedingDagboek from './VoedingDagboek'
import { getUser, getUserdetails } from '../../Localstorage/localstorage'
import { AXIOS_GET } from '../../server/api/crud'
import { useNavigate } from 'react-router-dom'
import { DashboardHealth } from './admin/DashboardHealth'

const datas = (states) => ([
  {
    id: 0,
    name:'Stap 1',
    description:`Wat je
    kan
    verwacht
    en tijdens
    de intake`,
    component: BeforePreIntake,
    status: states[0]
  },
  {
    id: 1,
    name:'Stap 2',
    description:'Pre-intake vragenlijst',
    component: PreIntake,
    status: states[1]
  },
  {
    id: 2,
    name:'Stap 3',
    description:'Klachtenregistratie vragenlijst',
    component: KlachtenRegisterFormulier,
    status: states[2]
  },
  {
    id: 3,
    name:'Stap 4',
    description:'Voedingsdagboek',
    component: VoedingDagboek,
    status: states[3]
  }
])

function getStatus(item){
  let name = item?.name
  let status = [];
    if (name === "home"){
      status  = ['complete', 'upcomping', 'upcoming', 'upcoming'] ;
    }else if (name === "preintake"){
      status  = ['complete','current', 'upcoming',  'upcoming'];
    }else if (name === "klachten"){
      status  = ['finish', 'finish', 'current', 'upcoming'];
    }else if (name === "voeding"){
      status  = ['finish', 'finish', 'complete', 'current'];
    }else{
      status  = ['finish', 'finish', 'finish', 'complete'];
    }
    return status;
}

const HealthForms = () => {
  const navigate = useNavigate();

  const userdetails = getUserdetails();

  const [vragenlijst, setVragenlijst] = useState(null)

  useEffect(() => {

    const fetchData = async (p_id) => {
      try {
        const response = await AXIOS_GET(`vragenlijsten/${p_id}`);
        setVragenlijst(response.data.data[0])

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (userdetails) {
      const { user_id } = userdetails;
      fetchData(user_id);
      //Redirect to home page if userdetails is already logged in
    }
  }, []); // Empty dependency array ensures this effect runs only once

 if(userdetails.role_id === 2) return (
    <div>
     { vragenlijst ?  <RPStepperWithPage vragenlijsten={vragenlijst} userdetails={userdetails} datas={datas(getStatus(vragenlijst))} navigation={navigate} /> :
      <div>Aan het laden...</div>
     }
    </div>
  )

  return(
    <DashboardHealth/>
  )
}

export default HealthForms
