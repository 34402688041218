import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select2 from "../../../Componenten/Select2";
import InfoCards from "./InfoCards";
import { AXIOS_GET } from "../../../server/api/crud";
import SelectPatienten from "../../../Componenten/SelectPatienten";
import { getUser, getUserdetails, setKlacht, setVoedingID } from "../../../Localstorage/localstorage";
import { useLocation } from "react-router-dom";

export const DashboardHealth = () => {
  const { patienten, admins } = useSelector((state) => state.user);
  const gebruiker = getUser();
  const location = useLocation(); // To retrieve the state
  const userState = location.state;
  const [selected, setSelected] = useState(userState||patienten[0]);

  const [vragenlijst, setVragenlijst] = useState(null);

  const fetchData = async (user) => {
    try {
      const response = await AXIOS_GET(`vragenlijsten/${user?.user_id}`);
      if(gebruiker?.role == 1){
        const rep = await AXIOS_GET(`voedsel/${user?.user_id}`);
        if(rep?.status == 200){
          setVoedingID(rep.data.data[0]);
        }
      }

      if(response?.status == 200){
        setVragenlijst(response.data.data[0]);
      }

      const complaintRes = await AXIOS_GET(`klachtenregistratie/${user?.user_id}`);
      const complaints = complaintRes.data;

  
      if (complaints.length > 0) {
        setKlacht(complaints[0]);
      } else {
        throw new Error("No complaints found");
      }

 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selected) {
      fetchData(selected);
    }
  }, [selected]);

  return (
    <div className="py-4 px-8 h-dvh dark:bg-gray-800">
      <div>
        {patienten?.length > 0 && (
          <SelectPatienten
            selected={selected ? selected : patienten[0]}
            setSelected={setSelected}
            data={patienten}
          />
        )}
      </div>
      <InfoCards vragenlijst={vragenlijst} user={selected} />
    </div>
  );
};
