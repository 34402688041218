import React, { useState } from "react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import ShowInfoAfspraakInWacht from "../Componenten/Calender/ShowInfoAfspraakInWacht";
import { getUserdetails } from "../Localstorage/localstorage";
import SelectAdmins from "../Componenten/SelectAdmins";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BannerPlannig = ({
  bannerName = "Dashboard",
  Changeview,
  setSelected,
  selected,
  index,
  views,
}) => {
  const userdetails = getUserdetails();
  const [activeTab, setactiveTab] = useState(views[index].name)
  const { appointmentsInWacht } = useSelector((state) => state.appointment);


  const NewView = (item) => {
    Changeview(item.id)
    setactiveTab(item.name);

  }

  const [open, setOpen] = useState();
  return (
    <div className="mt-4 mx-8 items-center">
   
      <div className="flex items-center justify-between w-full">
        {userdetails.role_id == 1 && <SelectAdmins
          label={false}
          selected={selected}
          setSelected={setSelected}
        />}
        <div className="flex border rounded h-9 border-gray-300  sm:flex-none">
          {views.length > 0 &&
            views.map((item, id) => (
              <button
                key={id}
                onClick={() => NewView(item)}
                className={`${
                  activeTab === item.name
                    ? "bg-gray-500 dark:bg-green-800 text-white shadow"
                    : "bg-transparent"
                } m-1 px-4 text-sm dark:bg-gray-800 dark:text-white rounded`}
              >
                {item?.name}
              </button>
            ))}
        </div>
      </div>
      <ShowInfoAfspraakInWacht
        evenments={appointmentsInWacht}
        selected={selected}
        user={userdetails}
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
};

export default BannerPlannig;
