import { AXIOS_GET, AXIOS_POST, AXIOS_UPDATE } from "../server/api/crud";



const { setMailRoomsLoad, setMailMessage, setMailMessageItemsFilterLoad, setMailMessageItemsLoad } = require("../redux/mailSlice");

export const fetchMailRooms = async (user,dispatch,mailMessage = null,limit,offset,query) => {
    try {
      const response = await AXIOS_GET(`mailbox/mailmessageById/filter/${user?.id}?limit=${limit}&offset=${offset}&search=${query}`);
      dispatch(setMailRoomsLoad(response.data.data));

      
      if(!mailMessage){
        dispatch(setMailMessage(response.data.data[0]));
      }
     
    } catch (error) {
      console.error("Error fetching mailrooms", error);
    }
  };

  export const fetchMailRoomsChange = async (user,dispatch,mailMessage = null,limit,offset,query) => {
    try {
      const response = await AXIOS_GET(`mailbox/mailmessageById/filter/${user?.id}?limit=${limit}&offset=${offset}&search=${query}`);
      dispatch(setMailRoomsLoad(response.data.data));
      const findRoom = (response.data.data).find((item) => item.id == mailMessage.id);
      //dispatch(setMailMessage(findRoom));
    } catch (error) {
      console.error("Error fetching mailrooms", error);
    }
  };


  export const seenMessage = async (user,mailMessage,dispatch) => {
    try {
      const response = await AXIOS_UPDATE(`mailbox/message/seen`,mailMessage.id,{});
     
      if(response.status == 200){
        fetchMailRoomsChange(user,dispatch,mailMessage,8,0,'');
      }

    } catch (error) {
      console.error("Error fetching mailrooms", error);
    }
  };


  export const fetchMailMessages = async (user,dispatch) => {
    try {
      const response = await AXIOS_GET(`mailbox/messageItemsByUser/${user?.id}`);
      dispatch(setMailMessageItemsLoad(response.data.data));
      dispatch(setMailMessageItemsFilterLoad(response.data.data));
    } catch (error) {
      console.error("Error fetching mail messages", error);
    }
  };