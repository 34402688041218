import React, { useMemo } from "react";

// Wrapping the component with React.memo to optimize re-renders
const TabsFilter = React.memo(({ data, activeTab }) => {
  return (
    <div className="flex border rounded h-12 border-gray-300  sm:flex-none">
        {data.length > 0 && data.map((item, index) => (
        <button key={index} onClick={item.onClick} className={`${activeTab === item.name ? "dark:bg-green-700 bg-gray-500 text-white shadow" : "dark bg-gray-700 bg-transparent"} m-1 px-4  dark:text-white rounded`}>
          {item.name}
        </button>
      ))}
    </div>
  );
});

export default TabsFilter;
