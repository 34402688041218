import React from 'react'


const Calendar = ({Component, user, select, userdetails, appointments}) => {
  
  return (
    <div className='mt-4 h-full h-max dark:bg-gray-800 w-full px-8 pb-10'>
      {Component && <Component.viewComponent select={select} appointments={appointments} user={user} userdetails={userdetails}/>}
    </div>
  )
}

export default Calendar
