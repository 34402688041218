import Swal from "sweetalert2";
import { AXIOS_POST, AXIOS_UPDATE } from "../server/api/crud";
import moment from "moment";
import uuid from "react-uuid";

export const createComment = async (e,newComments,commentaar,setUpdateComment,resetForm, dispatch, setComments, setCommentsUpdate) => {
    e.preventDefault();
    if (newComments?.body){

      try {
        if (commentaar.id) {
          await AXIOS_UPDATE(`comments`, commentaar?.id, newComments).then(
            (respons) => {
              if (respons.status == 200) {
                Swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: `Uw Opmerking is gewijzigd`,
                  showConfirmButton: false,
                  timer: 2500,
                });
              }
            }
          ).catch((error)=>{
            console.log(error)
          });
          dispatch(setCommentsUpdate(newComments));
          setUpdateComment({})
        } else {
          // Create new event
          let newobject = newComments;
          newobject.id = uuid();
          newobject.datetime = moment().locale('nl-be').format("YYYY-MM-DD HH:mm:ss");

  
          await AXIOS_POST(`comments`, newobject).then((respons) => {
            if (respons.status == 200) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Uw Opmerking is toegevoegd`,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          });
  
          dispatch(setComments(newobject));
        }
        resetForm();
      } catch (error) {
        console.log(error)
      }

    }else{
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Gelieve een commentaar in te vullen`,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  /*

export const getComments = async (user_id) => {
    const response = await AXIOS_UPDATE(`comments/${user_id}`);
    return response.data;
}



export const updateComment = async (comment) => {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/comments/${comment.id}`, comment);
    return response.data;
}

export const deleteComment = async (id) => {
    const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/comments/${id}`);
    return response.data;
}

export const getCommentsByUser = async (user_id) => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/comments/user/${user_id}`);
    return response.data;
}*/