import React from 'react'
import { MutatingDots } from 'react-loader-spinner'

export const SpinnersMutatingDots = () => {
  return (
    <div className="z-40 absolute right-0 w-full top-0  h-dvh flex items-center justify-center">
    <div className="z-1 absolute  w-full top-0 bg-opacity-75 bg-gray-200 h-dvh flex items-center justify-center"/>

      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#4fa94d"
        secondaryColor="#4fa94d"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass="z-10"
      />


    </div>
  )
}

