"use client";

import { CheckCircleIcon, InboxArrowDownIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import { setMailMessage } from "../../redux/mailSlice";
import moment from "moment";
import { getMailId, getUser, setMailId } from "../../Localstorage/localstorage";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import SelectPatientMail from "../../Componenten/SelectPatientMail";
import { useLayoutEffect } from "react";
import { seenMessage } from "../../apiCalls/messageApi";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MailboxKlant = () => {
  const { mailRooms, mailMessage } = useSelector((state) => state.mail);
  const dispatch = useDispatch();
  const user = getUser()

  const updateMailMessage = async (p_items) => {
    setMailId(p_items);
    if(p_items.sum_status > 0){
        seenMessage(user,p_items,dispatch);
    }
    dispatch(setMailMessage(p_items));
  };
  
  useLayoutEffect(() => {
    if(mailRooms?.length > 0)
      updateMailMessage(mailRooms[0])
  }, [])

  return (
    <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
      <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-white dark:bg-gray-600 dark:text-white">
        <div className="flex-shrink-0">
          <div className="flex py-4 flex-col justify-center dark:bg-gray-800 px-6">
            <div className="flex items-baseline space-x-3">
              <h2 className="text-lg font-medium text-gray-900 flex items-center gap-x-2 dark:text-white">
                <span className="bg-transparent inline-flex h-10 w-10  flex-shrink-0 items-center justify-center rounded-lg">
                  <InboxArrowDownIcon
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-400 dark:text-white"
                  />
                </span>
                Postvak In
              </h2>
            </div>
            <div>
              <p className="ml-12 text-xs font-medium text-gray-500 dark:text-white">
                Aantal Berichten {mailRooms?.length || 0}
              </p>
            </div>
            <SelectPatientMail
          dispatch={dispatch}
          mailMessage={mailMessage}
          user={user}
          key={'Mail'}
          />
          </div>
        </div>
        <nav
          aria-label="Message list"
          className="min-h-0 flex-1 overflow-y-auto"
        >
          <ul role="list" className="">
            {mailRooms?.length > 0 &&
              mailRooms?.map((message) => (
                <li
                  onClick={() => updateMailMessage(message)}
                  key={message?.id}
                  className={"m-2 relative rounded-md hover:bg-green-50"}
                >
                   {message?.sum_status > 0 ? <div className=" px-1.5 py-0.5 flex text-xs font-medium text-red-100 rounded absolute bottom-2 right-2 p-1">
                  <div className="relative flex ">
                      <EyeSlashIcon className="h-5 w-5 text-gray-500"/> <span className="absolute -right-2 -top-2 bg-red-900 py-.5 px-1 rounded">{message?.sum_status}</span></div>
                        </div> : <div className=" px-1.5 py-0.5 text-xs font-medium text-gray-200 rounded absolute bottom-2 right-2 p-1">
                      <EyeIcon className="h-5 w-5 text-green-700"/>
                        </div>}
                  <div
                    className={classNames(
                      message.id == mailMessage.id
                        ? "bg-green-50 ring-green-500 dark:bg-gray-800 dark:ring-green-700 ring-2"
                        : "bg-white ring-1 dark:bg-gray-800 dark:ring-gray-700",
                      "rounded-md p-4 dark:hover:bg-gray-700 hover:bg-green-50"
                    )}
                  >
                    {message.id == mailMessage.id && (
                      <CheckCircleIcon className="absolute right-4 h-8 w-8 text-green-700" />
                    )}
                    <div className="flex justify-between space-x-3 rounded-md ring-blue-200 ring-3">
                      <div className="min-w-0 flex-1">
                        <span className="block focus:outline-none">
                          <span
                            aria-hidden="true"
                            className="absolute inset-0"
                          />
                          <p className="truncate text-sm font-medium text-gray-800 dark:text-white">
                            {message?.receiver}
                          </p>
                          <p className="truncate text-sm text-gray-500">
                            {message?.subject}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="mt-1">
                      <time
                        dateTime={message?.date}
                        className="flex-shrink-0 whitespace-nowrap text-xs right-0 text-gray-500"
                      >
                        {moment(message?.datetime).format("LLL")}
                      </time>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MailboxKlant;
