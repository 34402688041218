import React, { useEffect, useLayoutEffect, useState } from 'react'
import DocumentAdmin from '../DocumentAdmin';
import DocumentClient from '../DocumentClient';
import Spinner from '../../../Componenten/Spinner';
import { getUser, getUserdetails } from '../../../Localstorage/localstorage';
import { useSelector } from 'react-redux';
import { AXIOS_GET } from '../../../server/api/crud';
import PlansAdmin from '../PlansAdmin';
import PlansClient from '../PlansClient';

const Plans = () => {
  
  const user = getUser();
  const userdetails = getUserdetails();
  const [refresh, setRefresh] = useState(false);

  const { patienten } = useSelector((state) => state.user);
  const [patient, setPatient] = useState(patienten[0]);
  const [attachments, setAttachments] = useState([]);


  const getDocumentByID = async () => {
    const url = user.role == 1 ? `documents/admin/all` : `documents/admin/user/${user?.id}`;

    await AXIOS_GET(url).then((res) => {
      setAttachments(res.data.data);
    });
    setRefresh(false);
  };

  useLayoutEffect(() => {
    getDocumentByID();
  }, []);

  useEffect(() => {
    if (refresh) {
      getDocumentByID();
    }
  }, [refresh]);

  useEffect(() => {
    if (patient) getDocumentByID(patient.user_id);
  }, [patient]);
  
  if(user.role == 1) return <PlansAdmin type='plans' attachments={attachments} setAttachments={setAttachments} patienten={patienten} setRefresh={setRefresh} setPatient={setPatient} patient={patient} userdetails={userdetails}/>;
  if(user.role == 2) return <PlansClient type='plans' attachments={attachments} setAttachments={setAttachments}  userdetails={userdetails}/>; 

  return (
    <Spinner/>
  );
}

export default Plans