import moment from "moment";
import React from "react";
import { CalculateAge } from "../../function";
import { getUserdetails } from "../../Localstorage/localstorage";
import { Attachments } from "../Documenten/Attachments";
import RP_Avatar from "../RP_Avatar";
import { UserCircleIcon } from "@heroicons/react/24/outline";

const UserDashboardInfo = () => {
  const userdetails = getUserdetails();
  return (
    <div className="pb-4 rounded mt-4">
      {/* Page header */}
              <h1 className="text-3xl font-bold text-gray-900 mb-8 dark:text-white">
                Hallo,  {userdetails?.full_name} <span className="text-sm ">{`(${CalculateAge(
                  userdetails?.date_of_birth
                )} jaar)`} </span>
              </h1>
  
        <div className="border p-8 rounded">
          <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500 dark:text-green-500">
                Geregistreed op
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {moment(userdetails?.created_at).format("LLLL")}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400">
                Email address
              </dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.email}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400">Klacht</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.klacht}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-400">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.phone_number}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-400">Situatie</dt>
              <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                {userdetails?.more_details}
              </dd>
            </div>
            {/*attachments?.length > 0 && <Attachments attachments={attachments}/>*/}
          </dl>
        </div>
      </div> 
  );
};

export default UserDashboardInfo;
