import React, { useState } from "react";
import DashboardDocumenten from "../../Componenten/DashboardDocumenten";
import FilterNavigation from "../../Componenten/Dashboard/FilterNavigation";
import FileCard from "../../Componenten/MiniCards/FileCard";
import { DocumentIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import Select2 from "../../Componenten/Select2";
import FileCardAdvies from "../../Componenten/MiniCards/FileCardAdvies";
import FileCardPlans from "../../Componenten/MiniCards/FileCardPlans";
import SelectPatienten from "../../Componenten/SelectPatienten";

const filters = [
  { value: 1, name: "list", icon: TableCellsIcon, color: "text-green-500" },
  { value: 2, name: "block", icon: DocumentIcon, color: "text-green-500" },
];

const DocumentAdmin = ({
  userdetails,
  setPatient,
  patient,
  patienten,
  attachments,
  type = "uploads",
}) => {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(filters[0]);

  return (
    <div className="mx-auto max-w-6xl lg:max-w-full lg:px-4 pt-10 h-full dark:bg-gray-800 h-dvh">
      <div className="px-4 flex items-center justify-between">
        <FilterNavigation
          selectedRadio={view}
          setSelectedRadio={setView}
          options={filters}
        />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          { patienten?.length > 0 ? (
            <SelectPatienten
              selected={patient ? patient : patienten[0]}
              setSelected={setPatient}
              data={patienten}
            />
          ) : null}
        </div>
      </div>
      {view?.value === 1 ? (
        <DashboardDocumenten
          attachments={attachments}
          patienten={patienten}
          patient={patient}
          user={userdetails}
        />
      ) : (
        <div className="grid grid-cols-3 xxl:grid-cols-4 p-4">
          {attachments?.length > 0
            ? attachments.map((item) => (
                <>
                  {type === "uploads" ? (
                    <FileCard item={item} userdetails={userdetails} />
                  ) : type === "advies" ? (
                    <FileCardAdvies item={item} userdetails={userdetails} />
                  ) : (
                    <FileCardPlans item={item} userdetails={userdetails} />
                  )}
                </>
              ))
            : <div className="text-center p-8 dark:bg-gray-800 w-full dark:text-white bg-gray-100  border-gray-300 w-full grid grid-cols-1">
            <p>Er zijn adviezen documenten gevonden</p>
          </div>}
        </div>
      )}
    </div>
  );
};

export default DocumentAdmin;
