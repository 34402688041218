import moment from "moment";
import { useEffect, useLayoutEffect, useState } from "react";


// Function to calculate the grid row based on start and end times
const getGridRow = (start_time, end_time) => {
  const [startHour, startMinutes] = moment(start_time).format('HH:mm').toString().split(":").map(Number);
  const [endHour, endMinutes] = moment(end_time).format('HH:mm').toString().split(":").map(Number);

  // Calculate start and end grid rows
  const startRow = (startHour- 8) * 12 + Math.round(startMinutes / 5) + 2; // Offset by 2 for header
  const endRow = (endHour - 8) * 12 + Math.round(endMinutes / 5) + 2;

  // Calculate span between start and end rows
  const span = endRow - startRow;

  return `${startRow} / span ${span}`;
};

const Skeleton = () => (
  <>
   <li
          key={1}
          className={`relative mt-px flex col-start-1`} // Apply column class if exists
        ></li>
        <li
          key={2}
          className={`relative mt-px flex col-start-2`} // Apply column class if exists
        ></li>
        <li
          key={3}
          className={`relative mt-px flex col-start-3`} // Apply column class if exists
        ></li>
        <li
          key={4}
          className={`relative mt-px flex col-start-4`} // Apply column class if exists
        ></li>
        <li
          key={5}
          className={`relative mt-px flex col-start-5`} // Apply column class if exists
        ></li>
        <li
          key={6}
          className={`relative mt-px flex col-start-6`} // Apply column class if exists
        ></li>
        <li
          key={7}
          className={`relative mt-px flex col-start-7`} // Apply column class if exists
        ></li>
 
  </>
)

const WeekEvents = ({evenmentes, showAfspraak}) => {
const [events, setevents] = useState(evenmentes)
useEffect(() => {
  
  setevents(evenmentes);
  

  return () => {
    
  };
}, [evenmentes])
  
  return (
    <ol
      className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
      style={{
        gridTemplateRows: "1.75rem repeat(180, minmax(0, 1fr)) auto",
      }}
    >
 {events.map((event, index) => {
  // Check if the event has a 'cols' property for grid column placement
  const colStartClass = event?.cols ? `${event?.cols}` : '';

  return (
    <>
    <Skeleton/>
      {event?.cols && (
        <li
          key={index}
          onClick={() => showAfspraak(event)}
          style={{ gridRow: getGridRow(event?.start_time, event?.end_time) }}
          className={`relative mt-px flex ${event?.cols}`} // Apply column class if exists
        >
          <span
            className={`cursor-pointer group absolute inset-1 shadow-sm flex flex-col overflow-y-auto rounded-sm border-l-4 border-l-gray-400 px-2 text-xs border-1.5 border-green-600 leading-5 ${event?.color}`}
          >
            <p className="order-1 font-semibold text-white">{event?.name}</p>
            <p className="order-1 text-xs text-white">{event?.invitees[0].name}</p>
            <p className="group-hover:text-current text-white">
              <time dateTime={`2022-01-12T${event?.start_time}`}>
                {moment(event?.start_time).format('HH:mm')}
              </time>{" "}
              -{" "}
              <time dateTime={`2022-01-12T${event?.end_time}`}>
                {moment(event?.end_time).format('HH:mm')}
              </time>
            </p>
          </span>
        </li>
      )}
    </>
  );
})}

    </ol>
  );
};


export default WeekEvents;