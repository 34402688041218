import React, { useState, useCallback } from "react";
import Swal from "sweetalert2";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { AXIOS_UPDATE } from "../../server/api/crud";
import RP_Avatar from "../RP_Avatar";
import { getUserdetails } from "../../Localstorage/localstorage";


const Passwoord = ({ user, open, setOpen }) => {
  const userdetails = getUserdetails();
  const [updatePassword, setUpdatePassword] = useState({
    old_password: user?.password || "",
    password: "",
    confirm_password: "",
  });



  const [passwordErrors, setPasswordErrors] = useState({});
  const [confirmColor, setConfirmColor] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value) => {
    const errors = {};
    const minLengthRegex = /^.{10,}$/;
    const uppercaseRegex = /^(?=(?:.*[A-Z]){2,})/;
    const specialCharRegex = /^(?=(?:.*[@$!%*?&]){2,})/;
    const numberRegex = /^(?=(?:.*[0-9]){2,})/; // Minimaal twee cijfers vereist

    if (!minLengthRegex.test(value)) {
      errors.minLength = "Wachtwoord moet minimaal 10 karakters lang zijn";
    }
    if (!uppercaseRegex.test(value)) {
      errors.uppercase = "Wachtwoord moet minstens twee hoofdletters bevatten";
    }
    if (!specialCharRegex.test(value)) {
      errors.specialChar =
        "Wachtwoord moet minstens twee speciale karakters bevatten (@, $, !, %, *, ?, &)";
    }

    if (!numberRegex.test(value)) {
      errors.number = "Wachtwoord moet minstens twee cijfers bevatten (0-9)";
    }

    return errors;
  };

  const handlePasswordChange = (name, value) => {
    setUpdatePassword((prev) => ({ ...prev, [name]: value }));

    if (name === "password") {
      setPasswordErrors(validatePassword(value));
    }

    if (name === "confirm_password") {
      setConfirmColor(value === updatePassword.password);
    }
  };

  const handleChangePasswoord = useCallback(async () => {
    if (!updatePassword.password || updatePassword.password.trim() === "") {
      alert("Het veld wachtwoord mag niet leeg zijn. Voer een wachtwoord in.");
      return;
    }

    if (
      !updatePassword.confirm_password ||
      updatePassword.confirm_password.trim() === ""
    ) {
      alert("Het veld Bevestigd wachtwoord mag niet leeg zijn");
      return;
    }

    if (Object.keys(passwordErrors).length > 0 || !confirmColor) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Corrigeer de fouten in het wachtwoord",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    try {
       
      await AXIOS_UPDATE("auth/password", user?.id, {
        id: user.id,
        password: updatePassword.old_password,
        email: user?.email,
        newpassword: updatePassword.password,
      }).then((response) => {
        if (response.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Uw wachtwoord is gewijzigd",
            showConfirmButton: false,
            timer: 2500,
          });
          setOpen(false);
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "Wachtwoord komt niet overeen. Gelieve deze correct in te voeren.",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    } catch (error) {
      Swal.fire(
        "Er is een fout opgetreden bij het wijzigen van uw wachtwoord",
        "",
        "error"
      );
      console.error("Error during password update:", error);
    }
  }, [passwordErrors, confirmColor, updatePassword, user, setOpen]);

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:w-full sm:max-w-lg sm:p-6">
          <div className="w-full flex justify-center my-4">
          <RP_Avatar avatar={userdetails?.avatar} size={20} alt={` ${userdetails?.full_name}`} />
          </div>

            <h2 className="text-2xl font-bold text-center text-gray-900">
              Wachtwoord wijzigen
            </h2>
            <div className="mt-4 space-y-6">
              {/* Old Password */}
              <input
                type="password"
                name="old_password"
                placeholder="Huidige wachtwoord"
                value={updatePassword.old_password}
                disabled
                className="block w-full px-4 py-4 text-gray-900 rounded-md shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-darkblue-600"
              />

              {/* New Password */}
              <div>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Nieuwe wachtwoord"
                    value={updatePassword.password}
                    onChange={(e) =>
                      handlePasswordChange("password", e.target.value)
                    }
                    className="block w-full px-4 py-4 text-gray-900 rounded-md shadow-sm ring-1 ring-gray-300 focus:ring-2 focus:ring-darkblue-600"
                  />
                  <button
                    type="button"
                    onClick={handleTogglePasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-600 focus:outline-none"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
                {passwordErrors.minLength && (
                  <p className="text-red-500 text-sm mt-1">
                    {passwordErrors.minLength}
                  </p>
                )}
                {passwordErrors.uppercase && (
                  <p className="text-red-500 text-sm mt-1">
                    {passwordErrors.uppercase}
                  </p>
                )}
                {passwordErrors.specialChar && (
                  <p className="text-red-500 text-sm mt-1">
                    {passwordErrors.specialChar}
                  </p>
                )}
                {passwordErrors.number && (
                  <p className="text-red-500 text-sm mt-1">
                    {passwordErrors.number}
                  </p>
                )}
              </div>

              {/* Confirm Password */}
              <div>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirm_password"
                  placeholder="Bevestig nieuwe wachtwoord"
                  value={updatePassword.confirm_password}
                  onChange={(e) =>
                    handlePasswordChange("confirm_password", e.target.value)
                  }
                  className={`block w-full px-4 py-4 rounded-md shadow-sm ring-1 ${
                    confirmColor ? "ring-gray-300" : "ring-red-500"
                  } focus:ring-2 focus:ring-darkblue-600`}
                />
                {!confirmColor && updatePassword.confirm_password && (
                  <p className="text-red-500 text-sm mt-1">
                    Wachtwoorden komen niet overeen
                  </p>
                )}
              </div>

              {/* Submit Button */}
              <button
                onClick={handleChangePasswoord}
                className="w-full py-4 bg-green-900 text-white rounded-md shadow-sm text-lg font-semibold hover:bg-green-700"
              >
                Wijzigen
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Passwoord;
