import React, { useState } from "react";
import { imgrepeat } from "../../image";
import { useNavigate } from "react-router-dom";
import { AXIOS_POST } from "../../server/api/crud";
import Swal from "sweetalert2";
import uuid from "react-uuid";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const RegisterModal = ({ open, setOpen }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState({});
  const [usernameError, setUsernameError] = useState("");
  const [confirmColor, setConfirmColor] = useState(false);

  const navigate = useNavigate();

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (value) => {
    const errors = {};
    const minLengthRegex = /^.{10,}$/;
    const uppercaseRegex = /^(?=(?:.*[A-Z]){2,})/;
    const specialCharRegex = /^(?=(?:.*[@$!%*?&]){2,})/;
    const numberRegex = /^(?=(?:.*[0-9]){2,})/; // Minimaal twee cijfers vereist

    if (!minLengthRegex.test(value)) {
      errors.minLength = "Wachtwoord moet minimaal 10 karakters lang zijn";
    }
    if (!uppercaseRegex.test(value)) {
      errors.uppercase = "Wachtwoord moet minstens twee hoofdletters bevatten";
    }
    if (!specialCharRegex.test(value)) {
      errors.specialChar =
        "Wachtwoord moet minstens twee speciale karakters bevatten (@, $, !, %, *, ?, &)";
    }

    if (!numberRegex.test(value)) {
      errors.number = "Wachtwoord moet minstens twee cijfers bevatten (0-9)";
    }

    return errors;
  };

  const handleSignUp = async () => {
    const usernameRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate username
    if (!username || username.trim() === "") {
      setUsernameError("Gebruikersnaam mag niet leeg zijn");
      return;
    }

    if (!usernameRegex.test(username)) {
      setUsernameError("Ongeldige e-mailadres");
      return;
    }

    if (!password || password.trim() === "") {
      alert("Het veld wachtwoord mag niet leeg zijn. Voer een wachtwoord in.");
      return;
    }

    if (!confirmpassword || confirmpassword.trim() === "") {
      alert("Het veld Bevestigd Wachtwoord mag niet leeg zijn");
      return;
    }

    setUsernameError("");

    // Validate password
    if (Object.keys(passwordErrors).length > 0) {
      alert("Corrigeer de fouten in het wachtwoord");
      return;
    }

    if (confirmpassword.trim() !== password.trim()) {
      alert("Wachtwoorden zijn niet gelijk");
      return;
    }

    try {
      const id = uuid();
      await AXIOS_POST("auth/signup", {
        id: id,
        username: username,
        password: password,
        role: 1,
        email: null,
      }).then((res) => {
        if (res.data.affectedRows === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: `Bedankt voor de registratie.
            Gelieve u aan te melden met deze gebruikersnaam: ${username}`,
            showConfirmButton: false,
            timer: 2500,
          });
          setOpen(false);
        }
      });
    } catch (error) {
      alert("Er is een fout opgetreden bij het registreren");
      console.error("Error during sign-up:", error);
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    setPasswordErrors(validatePassword(value));
  };

  const handleConfirmPassword = (value) => {
    setConfirmPassword(value);
    setConfirmColor(value === password);
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />{" "}
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="flex h-screen w-full flex-col justify-center items-center lg:px-8">
              <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                  className="mx-auto h-20 w-auto"
                  src={imgrepeat.logo_green}
                  alt="RepeatHealth"
                />
                <h1 className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
                  Repeatconnect.
                </h1>
                <h2 className="mt-2 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
                  Gelieve u zich hieronder aan te melden.
                </h2>
              </div>

              <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="space-y-10">
                  {/* Username Input */}
                  <div>
                    <input
                      id="username"
                      name="username"
                      type="text"
                      placeholder="Email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                      className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
                    />
                    {usernameError && (
                      <p className="text-red-500 text-sm mt-2">
                        {usernameError}
                      </p>
                    )}
                  </div>

                  {/* Password Input */}
                  <div>
                    <div className="relative">
                      <input
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Wachtwoord"
                        value={password}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        required
                        className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
                      />
                      <button
                        type="button"
                        onClick={handleTogglePasswordVisibility}
                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-gray-600 focus:outline-none"
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5" />
                        ) : (
                          <EyeIcon className="h-5 w-5" />
                        )}
                      </button>
                    </div>

                    {passwordErrors.minLength && (
                      <p className="text-red-500 text-sm mt-2">
                        {passwordErrors.minLength}
                      </p>
                    )}
                    {passwordErrors.uppercase && (
                      <p className="text-red-500 text-sm mt-2">
                        {passwordErrors.uppercase}
                      </p>
                    )}
                    {passwordErrors.specialChar && (
                      <p className="text-red-500 text-sm mt-2">
                        {passwordErrors.specialChar}
                      </p>
                    )}
                    {passwordErrors.number && (
                      <p className="text-red-500 text-sm mt-1">
                        {passwordErrors.number}
                      </p>
                    )}
                  </div>

                  {/* Confirm Password Input */}
                  <div className="relative">
                    <input
                      id="confirm_password"
                      name="confirm_password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Bevestig Wachtwoord"
                      value={confirmpassword}
                      onChange={(e) => handleConfirmPassword(e.target.value)}
                      required
                      className={`block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-inset ${
                        confirmColor
                          ? "ring-gray-300 ring-1"
                          : "ring-red-500 ring-2"
                      } placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6`}
                    />
                    {!confirmColor && confirmpassword && (
                      <p className="text-red-500 text-sm mt-2">
                        Wachtwoord is niet gelijk
                      </p>
                    )}
                  </div>

                  {/* Sign Up Button */}
                  <div>
                    <button
                      onClick={handleSignUp}
                      className="flex w-full justify-center text-lg rounded-md bg-green-900 px-4 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Aanmelden
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default RegisterModal;
