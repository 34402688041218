import moment from 'moment';
import React from 'react'
import RP_Avatar from './RP_Avatar';

const getColors = (user, item) => {
    let pos = user.user_id == item?.author_id ? 'dark:bg-green-700 bg-gray-100 text-gray-900 dark:text-white shadow' : 'bg-gray-500 text-white dark:bg-gray-700 dark:border-gray-600 shadow';
        return pos
};

const ListChats = ({chats, user}) => {
   
  return (
    <ul role="list" className="space-y-8 py-4 px-4 sm:space-y-4 sm:px-6 lg:px-0">
            {(chats && chats?.length > 0) && chats?.map((chat) => (
              <li key={chat.id} className='mx-4'>
                {(user.user_id == chat?.author_id) ?  <div className="flex space-x-3 justify-start">
                  <div className="flex-shrink-0 ">
                    <RP_Avatar avatar={chat?.avatar} size="10" alt={` ${chat?.author}`} />
                  </div>
                  <div>
                    <div className="text-sm">
                      <a href="#" className="font-medium text-gray-900 dark:text-white">
                        {chat?.author}
                      </a>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: chat.body }}
                        className={`mt-1 rounded-lg space-y-2 text-sm p-2 ${getColors(user,chat)}`}
                    />
                    <div className="mt-2 space-x-2 text-xs">
                      <span className="font-medium text-gray-500 dark:text-gray-300">{moment(chat?.datetime).fromNow()}</span>{' '}
                      <span className="font-medium text-gray-500">&middot;</span>{' '}
                     {/*  <button type="button" className="font-medium text-gray-900">
                        Reply
                      </button> */}
                    </div>
                  </div>
                </div> :  <div className="flex space-x-3 justify-end">
                  <div>
                    <div className="text-sm">
                      <a href="#" className="font-medium text-gray-900 dark:text-white">
                        {chat?.author}
                      </a>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{ __html: chat.body }}
                        className={`mt-1 rounded space-y-2 text-sm p-2 ${getColors(user,chat)}`}
                    />
                    <div className="mt-2 space-x-2 text-xs">
                      <span className="font-medium text-gray-500 dark:text-gray-200">{moment(chat?.datetime).fromNow()}</span>{' '}
                      <span className="font-medium text-gray-500">&middot;</span>{' '}
                     {/*  <button type="button" className="font-medium text-gray-900">
                        Reply
                      </button> */}
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <RP_Avatar avatar={chat?.avatar} size="10" alt={` ${chat?.author}`} />
                  </div>
                </div> }
               
              </li>
            ))}
          </ul>
  )
}

export default ListChats