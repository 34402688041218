export const imgrepeat = { 
    logo : require('./images/rp.png'),
    calendly: require('./images/Calendly_1.png'),
    calendly2: require('./images/Calendly_2.png'),
    calendly3: require('./images/Calendly_3.png'),
    calendly4: require('./images/Calendly_4.png'),
    calendly5: require('./images/Calendly_5.png'),
    calendly6: require('./images/Calendly_6.png'),
    calendly7: require('./images/Calendly_8.png'),
    logo_blue : require('./images/rp_blue.png'),
    logo_green : require('./images/rp_green.png')
}