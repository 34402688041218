import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { imgrepeat } from "../../image";
import { AXIOS_GET, AXIOS_POST } from "../../server/api/crud";
import {
  getKlacht,
  getUser,
  setAccesToken,
  setAdmins,
  setKlacht,
  setStates,
  setUser,
  setVoedingID,
  setVooruitgang,
} from "../../Localstorage/localstorage";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  setAdminsLoad,
  setGebruikersLoad,
  setPatientenLoad,
} from "../../redux/userSlice";
import { AuthContext } from "../../context/AuthContext";
import { setCalendlyafspraak } from "../../redux/userCalendlySlice";
import Spinner2 from "../../Componenten/Spinner2";

const Login = () => {
  const [progress, setProgress] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { login } = useContext(AuthContext); // Get the login function from AuthContext

  const [isloading, setIsLoading] = useState(false);

  useEffect(() => {
    const user = getUser();
    const klacht = getKlacht();
    if ((user?.role == 2 && klacht) || user?.role == 1) {
      navigate("/");
    } else {
      navigate("/signin");
    }
  }, [navigate]);

  const fetchDataCalendly = async () => {
    try {
      const response = await AXIOS_GET(`users/calendly`);
      dispatch(setCalendlyafspraak(response.data.data));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (progress == 100) {
      navigate("/dashboard");
    }
  }, [progress]);

  const handleSignIn = async () => {
    setIsLoading(true);
    setProgress(10);
  
    // Validation
    const validateInputs = () => {
      if (!username || username.trim() === "") {
        alert("Gebruikersnaam mag niet leeg zijn");
        return false;
      }
      if (!password || password.trim() === "") {
        alert("Wachtwoord mag niet leeg zijn");
        return false;
      }
      if (password.length < 3) {
        alert("Wachtwoord moet minimaal 3 karakters lang zijn");
        return false;
      }
      return true;
    };
  
    if (!validateInputs()) {
      setIsLoading(false);
      return;
    }
  
    try {
      // Login Request
      const loginResponse = await AXIOS_POST("auth/login", { username, password });
      const data = loginResponse?.data;


  
      if (!data?.accessToken) {
        throw new Error("Invalid login credentials");
      }
  
      // Store access token
      setAccesToken(data.accessToken);
      login(data.accessToken);
      setUser(data.user);
  
      const userRole = data?.user?.role;
      const HoofdAdmin = data?.user?.hoofd_admin;
      setProgress(30);
  
      // Fetch User Data Based on Role
      if (userRole === 2 ) {
        await fetchAdminData(data.user.id);
        await fetchComplaintData(data.user.id);
      } else {
        await fetchPatientData();
        await fetchAdminDataHoofd();
      }
  
      // Fetch Complaint Data
  
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Welkom ${data.user.username}`,
        showConfirmButton: false,
        timer: 2500,
      });
      setProgress(100);
    } catch (error) {
      console.error("Error during sign-in:", error);
  
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Inloggen mislukt. Controleer uw gebruikersnaam en wachtwoord.`,
        showConfirmButton: false,
        timer: 2500,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  // Helper Functions
  const fetchAdminData = async (userId) => {
    try {
      const [adminsRes, voedingRes, vooruitgangRes] = await Promise.all([
        AXIOS_GET("users/all/admins"),
        AXIOS_GET(`voedsel/${userId}`),
        AXIOS_GET(`vooruitgang/user/${userId}`),
      ]);
  
      dispatch(setAdminsLoad(adminsRes.data.data));
      setAdmins(adminsRes.data.data);
  
      setVoedingID(voedingRes.data.data[0]);
      setVooruitgang(vooruitgangRes.data.data[0]);
  
      setProgress(80);
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  const fetchAdminDataHoofd = async () => {
    try {

      const adminsRes = await AXIOS_GET("users/all/admins");
      const admins = (adminsRes.data.data).filter((item) => item.user_id != '93d14850-bab4-de7d-4c8e-dd1cf3443891')

      dispatch(setAdminsLoad(admins));
      setAdmins(admins);
  
      setProgress(80);
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };
  
  const fetchPatientData = async () => {
    try {
      const [patientsRes, usersRes] = await Promise.all([
        AXIOS_GET("users/all/patienten"),
        AXIOS_GET("users/all"),
      ]);
  
      dispatch(setPatientenLoad(patientsRes.data.data));
      dispatch(setGebruikersLoad(usersRes.data.data));
  
      fetchDataCalendly();
      setProgress(70);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };
  
  const fetchComplaintData = async (userId) => {
    try {
      const complaintRes = await AXIOS_GET(`klachtenregistratie/${userId}`);
      const complaints = complaintRes.data;
  
      if (complaints.length > 0) {
        setKlacht(complaints[0]);
        setStates({ id: complaints[0]?.status });
      } else {
        throw new Error("No complaints found");
      }
    } catch (error) {
      console.error("Error fetching complaint data:", error);
      throw error;
    }
  };
  

  if (isloading) return <Spinner2 progress={progress} />;

  return (
    <div className="flex h-screen w-full flex-col justify-center items-center lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto"
          src={imgrepeat.logo_green}
          alt="RepeatHealth"
        />
        <h1 className="mt-10 text-center text-4xl font-bold leading-9 tracking-tight text-gray-900">
          Repeatconnect.
        </h1>
        <h2 className="mt-2 text-center text-lg font-bold leading-9 tracking-tight text-gray-900">
          Gelieve u hieronder in te loggen.
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="username"
                placeholder="Gebruikersnaam"
                autoComplete="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-darkblue-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Wachtwoord"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="block w-full text-lg rounded-md border-0 px-4 py-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="flex items-center justify-end mt-4">
              <div className="text-sm">
                <a
                  href="mailto:info@repeathealth.com"
                  className="font-semibold text-green-800 hover:text-green-400"
                >
                  Wachtwoord vergeten?
                </a>
              </div>
            </div>
          </div>
          <div>
            <button
              onClick={handleSignIn}
              className="flex w-full justify-center text-lg rounded-md bg-green-900 px-4 py-4 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Inloggen
            </button>
            <div className="mt-4 justify-center flex">
              <p>
                Heb je nog geen account?{" "}
                <a
                  href="/register"
                  className="font-semibold hover:text-green-600 text-green-800"
                >
                  meld je aan.
                </a>
              </p>
            </div>
          </div>
        </div>
        {/* <div className="relative mt-4">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300" />
            </div>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">Of</span>
            </div>
          </div>
          <div className='mt-4'>
          <div className="px-6 sm:px-0 max-w-sm ">
              <button type="button" 
              className="text-white text-lg px-4 py-4 w-full  bg-[#4285F4] hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center mr-2 mb-2">
                <svg className="mr-2 -ml-1 w-6 h-6" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                  <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"></path>
                </svg>
                <span className='flex '>
        
                Log u in met Google
                </span>

              </button>
          </div>
          </div> */}
      </div>
    </div>
  );
};

export default Login;
