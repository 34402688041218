const PORT = process.env['PORT'] || 8080;
const BASE_URL = 'https://repeatconnect.com';
const BASE_URL_API = `${BASE_URL}/api/klantenportaal`;
const CALENDLY_API_KEY = 'eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzM1OTA5MTQ3LCJqdGkiOiI2MThhZmJhNy1mOTE5LTRhYWYtYmI3Yy0zZTM4NWMwOGU2MDMiLCJ1c2VyX3V1aWQiOiJiNzUyNTBkNS0zMmRhLTQyYzYtYTU0Yy0zNzZhYTJhZmJhNGEifQ.fXUCrxfpUZznk1k2eG4WH8WCQ6XOE68YAIki4lP6OXP0ZliPUdtXBjW350JDVlXQ3-MlL2VlCjpB7kEk4W-8dw';
const CALENDLY_USER_ID = 'b75250d5-32da-42c6-a54c-376aa2abba4a';
const CALENDLY_ORGANIZATION_ID = 'e5ba7299-d262-4435-acc9-67a68893e28f';
const CALENDLY_WEBHOOK_ID = 'b4ce34b6-0b3d-4d3b-839b-1bdbc743fd80';
const CALENDLY_SCHEDULED_EVENT_ID = '463gpln5sm50hpldof0kqckcic';
const BASE_URL_API_CAL = 'https://api.calendly.com';
const BASE_URL_API_CAL_WEBHOOK = `${BASE_URL_API_CAL}/webhook_subscriptions`;
const BASE_URL_API_CAL_SCHEDULED_EVENTS = `${BASE_URL_API_CAL}/scheduled_events`;
const BASE_URL_API_CAL_EVENT_TYPES = `${BASE_URL_API_CAL}/event_types`;
const BASE_URL_API_CAL_USERS = `${BASE_URL_API_CAL}/users`;
const BASE_URL_API_CAL_USERS_ME = `${BASE_URL_API_CAL}/users/me`;
const BASE_URL_API_CAL_INVITEES = `${BASE_URL_API_CAL}/invitees`;
const BASE_URL_API_CAL_INVITATIONS = `${BASE_URL_API_CAL}/invitations`;
const BASE_URL_API_CAL_ORGANIZATIONS = `${BASE_URL_API_CAL}/organizations`;
const BASE_URL_API_CAL_WEBHOOKS = `${BASE_URL_API_CAL}/webhooks`;
const BASE_URL_API_CAL_WEBHOOK_SUBSCRIPTIONS = `${BASE_URL_API_CAL}/webhook_subscriptions`;

//YSMIXRRTB6GFDIHZMMDSYZ6QW7JNBP3Z

const IMAGE_URL_API = 'http://localhost:80';
const DOCUMENTS_URL_API = 'http://localhost:8075';
const PAGESIZE = 25;
const ACCESS_TOKEN_SECRET="jsdljfmlkqsdjklfjqdsfqsdfqjsdmfnsjqdnfjksdlnfkjqsndfjknqsdjkfnsdkj-fudhsbfj-sdjkfhbkjdhbfvuezbhyuiavoeyzrez123413241234132&ééyéijezhdfjhdlsfjqhdsklfqsdfqdsf"
const REFRESH_TOKEN_SECRET="jsdljfmlkqsdjdsjfjdskfjsdklfjlsdjflsdfqsdfsdF88EEEZ00Z0ZERrRRZ000Z99Z99ZZz))))sd)fsdfsdjfhqkjshfldsflfjqdsfqsdfqjsdmfnsjqdnfjksdlnfkjqsndfjknqsdjkfnsdkj-fudhsbfj-sdjkfhbkjdhbfvuezbhyuiavoeyzrez123413241234132&ééyéijezhdfjhdlsfjqhdsklfqsdfqdsf"
const TOKEN_EXPIRY="2h"
const REFRESH_TOKEN_EXPIRY="7"

const MAKE_SOCKET_CHANNEL = (id, channel) =>{
 if(!id) return channel 
 else return `${channel}_${id}`;
} 

const SOCKET_CHANNELS = {
  COMMENTS:'RP_COMMENTS',
  USERS:'RP_USERS',
  PREINTAKE:'RP_PREINTAKE',
  EETDAGBOEK:'RP_EETDAGBOEK',
  MAIL:'RP_MAIL',
  MAIL_ITEM:'RP_MAIL_ITEM',
  AFSPRAKEN:'RP_AFSPRAKEN',
  VRAGENLIJSTEN:'RP_VRAGENLIJSTEN',
  KLACHTENFORMULIER:'RP_KLACHTENFORMULIER',
  DOCUMENTEN:'RP_DOCUMENTEN',
  VOORUITGANG:'RP_VOORUITGANG',
}


module.exports = {
  SOCKET_CHANNELS,
  MAKE_SOCKET_CHANNEL,
  PORT,
  BASE_URL,
  BASE_URL_API,
  IMAGE_URL_API,
  BASE_URL_API_CAL,
  CALENDLY_API_KEY,
  CALENDLY_USER_ID,
  CALENDLY_ORGANIZATION_ID,
  CALENDLY_WEBHOOK_ID,
  CALENDLY_SCHEDULED_EVENT_ID,
  BASE_URL_API_CAL_WEBHOOKS,
  BASE_URL_API_CAL_WEBHOOK_SUBSCRIPTIONS,     
  BASE_URL_API_CAL_SCHEDULED_EVENTS,  
  BASE_URL_API_CAL_EVENT_TYPES,
  BASE_URL_API_CAL_USERS,
  BASE_URL_API_CAL_USERS_ME,
  BASE_URL_API_CAL_INVITEES,
  BASE_URL_API_CAL_INVITATIONS,
  BASE_URL_API_CAL_ORGANIZATIONS,
  DOCUMENTS_URL_API,
  PAGESIZE,
  ACCESS_TOKEN_SECRET,
  REFRESH_TOKEN_EXPIRY,
  REFRESH_TOKEN_SECRET,
  TOKEN_EXPIRY
};
