import React, { useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { CalendarDateRangeIcon } from "@heroicons/react/20/solid";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import {
  AXIOS_GET,
  AXIOS_GET_CAL_SCHEDULED_EVENTS,
  AXIOS_POST,
  AXIOS_UPDATE,
} from "../server/api/crud";
import Spinner from "./Spinner";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins, getUserdetails } from "../Localstorage/localstorage";
import { setAppointment } from "../redux/appointmentSlice";
import { sendEmail } from "../function";
import { emailHTML } from "../EmailTemplates";

const Calendly = ({ open, setOpen }) => {
  const [data, setData] = useState([]);
  const [allUsers, setallUsers] = useState([]);
  const [allUsersDet, setallUsersDet] = useState([]);

  const [bulk, setBulk] = useState({ users: [] });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const admins = getAdmins();
  const { patienten } = useSelector((state) => state.user);

  const [newEvent, setNewEvent] = useState({
    id: null,
    title: "",
    description: "",
    startTime: "08:00",
    endTime: "",
    type: "Follow up - 46min",
    color: "",
    name: "",
    date: "",
    cols: 0,
    datetime: "",
    location: "",
  });

  useEffect(() => {
    async function fetchData() {
      // You can await here
      setLoading(true);
      if (open) {
        const params = {
          organization:
            "https://api.calendly.com/organizations/e5ba7299-d262-4435-acc9-67a68893e28f",
            count:'100',

          sort: 'start_time:desc',
          status: "active",
        };
        const response = await AXIOS_GET_CAL_SCHEDULED_EVENTS(params);

        let newData = response?.collection?.filter((item) =>
          moment(item.start_time).isAfter(moment().subtract(1, "day"))
        );


        const respUser = await AXIOS_GET("users/all/patienten/not");
        let datass = respUser.data.data.filter(
          (user) => user?.username != "test2" && user?.username != "test3"
        ); 

        const usernames = datass
          ?.map((obj) => obj.username)
          .filter((user) => user != "test2" && user != "test3");


        const matchingPatients = newData.reduce((result, event) => {
          const isMatching = !datass.find((e) => 
            (e?.email?.trim() == event?.invitees[0]?.email.trim()) || (e?.username.trim() == event?.invitees[0]?.email.trim()));
       
               if (isMatching) {
                 result.push(event); // Add the event to the result array if it matches
               }          
               return result;
             }, []);

        setData(matchingPatients);
        setallUsers(usernames);
        setLoading(false);
      }
      // ...
    }
    fetchData();
  }, [open]);

  const handleSignUp = async (invitee, event) => {
    const { name, email, phone, first_name, last_name } = invitee;
    const { start_time, end_time, created_at, creators } = event;
    const type = event?.name;
    const { user_email, user_name } = creators[0];
    const username = email;
    const password = `${username}_${uuid().split("-")[0]}`;

    //check als gebruiker bestaat;
    const Finduser = patienten.find((user) => user.email == email);

    //check als Admin bestaat;
    const FindAdmin = admins.find((user) => user.email == user_email);

    // Validate user input
    if (!validateUserInput(username, password)) return;

    // Get confirmation
    const result = await Swal.fire({
      title: `Wenst u deze ${username} in te schrijven`,
      showCancelButton: true,
      confirmButtonText: "Ja",
      cancelButtonColor: "gray",
      cancelButtonText: "Annuleer",
      confirmButtonColor: "darkgreen",
    });

    if (!result.isConfirmed) return;

    try {
      const id = uuid();

      // Create user
      await createUser(id, username, password);

      // Update user details
      const userDetails = {
        first_name: name?.split(" ")[0] || first_name,
        last_name: name?.split(" ")[1] || last_name,
        full_name: name || `${first_name} ${last_name}`,
        email,
        phone_number: phone,
        more_details: invitee?.questions_and_answers[0]?.answer,
      };
      await updateUserDetails(id, userDetails);

      const emailData = {
        from_name: "RepeatHealth",
        sender_email: "info@repeathealth.com", // Replace with dynamic sender email
        title: `Hallo ${first_name}, Welkom bij RepeatConnect!`,
        receiver_email: email, // Replace with dynamic receiver email
        gebruikersnaam: email, // Replace with dynamic username
        name: name || `${first_name} ${last_name}`,
        wachtwoord: password, // Replace with dynamic password
        inloglink: "https://repeatconnect.com", // Replace with the login URL
      };



      const emailHtml = emailHTML(emailData);


    sendEmail('template_qdoh9mv',{ message_html: emailHtml,title:`Hallo ${first_name}, Welkom bij RepeatConnect!`,receiver_email: emailData?.receiver_email}) // Replace with dynamic receiver email
   

    } catch (error) {
      console.error("Error:", error);
      alert("Er is een fout opgetreden tijdens het proces.");
    }
  };

  // Helper functions
  const validateUserInput = (username, password) => {
    const usernameRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$|^[a-zA-Z0-9_]+$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{3,}$/;

    if (!username?.trim() || !usernameRegex.test(username)) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Ongeldige gebruikersnaam of e-mailadres",
        showConfirmButton: false,
        timer: 2500,
      });
      return false;
    }

    if (!password?.trim()) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title:
          "Wachtwoord moet minimaal 3 karakters lang zijn, minstens één hoofdletter, één nummer en één speciaal karakter bevatten",
        showConfirmButton: false,
        timer: 2500,
      });
      return false;
    }

    return true;
  };

  const createUser = async (id, username, password) => {
    const response = await AXIOS_POST("auth/signup", {
      id,
      username,
      password,
      role: 2,
      email: null,
      platform: "calendly",
    });

    if (response.data.affectedRows === 1) {
      await Swal.fire({
        position: "top-start",
        icon: "success",
        title: `Bedankt voor de registratie.\nKlant ${username} is aangemaakt`,
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const updateUserDetails = async (id, details) => {
    const response = await AXIOS_UPDATE("users", id, details);
    if (response.data.data.affectedRows === 1) {
      await Swal.fire({
        position: "center",
        icon: "success",
        title: "Bedankt voor het bij vullen van de gegevens.",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  const createAppointment = async (details) => {
    const response = await AXIOS_POST("afspraak", details);
    if (response.status === 200) {
      await Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Afspraak is aangemaakt",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-full overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-0 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative w-2/3 dark:bg-gray-900 transform overflow-hidden rounded-lg px-1 pb-4 pt-0 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:py-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {loading && <Spinner />}
            <div className="w-full">
              <div className="px-4 bg-white dark:bg-transparent rounded">
                <div className="mt-3 m-2 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle grid grid-cols-3 sm:px-2 lg:px-4 gap-2">
                      {data?.length > 0 ? (
                        data.map((item, index) => {
                          return (
                            <div
                              className="border rounded border-gray-300 p-2"
                              key={index}
                            >
                              {" "}
                              <CalendarDateRangeIcon className="w-10 h-10 text-gray-500" />
                              <p className="dark:text-white flex items-center">
                                {" "}
                                <div className="w-2 h-2 bg-green-500 rounded-full mr-2" />{" "}
                                {item.name}
                              </p>
                              <p className="dark:text-white">
                                {moment(item.start_time).format("LL")}
                              </p>
                              <time
                                dateTime={item.start_time}
                                className="dark:text-white italic text-sm"
                              >
                                {moment(item.start_time).format("HH:mm")} -{" "}
                              </time>
                              <time
                                dateTime={item.end_time}
                                className="dark:text-white italic text-sm"
                              >
                                {moment(item.end_time).format("HH:mm")}
                              </time>
                              <div>
                                {item.invitees.map((invitee, index) => (
                                  <div
                                    title={
                                      invitee?.questions_and_answers[0]?.answer
                                    }
                                    className="cursor-pointer flex flex-col border rounded p-2 m-4"
                                    key={index}
                                  >
                                    <p className="dark:text-white text-sm italic">
                                      Klant
                                    </p>
                                    <p className="dark:text-white text-sm">
                                      {invitee?.name}
                                    </p>
                                    <p className="dark:text-white text-xs">
                                      {invitee?.email}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <div>
                                {item.creators.map((invitee, index) => (
                                  <div
                                    className="flex flex-col border border-green-500 dark:bg-gray-800 rounded p-2 m-4"
                                    key={index}
                                  >
                                    <p className="dark:text-green-500 text-sm italic">
                                      Specialist
                                    </p>
                                    <p className="dark:text-white text-sm">
                                      {invitee?.user_name}
                                    </p>
                                    <p className="dark:text-white text-xs">
                                      {invitee?.user_email}
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <div className="flex gap-2">
                                {!allUsers.find(
                                  (user) => user == item.invitees[0].email
                                ) && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleSignUp(item.invitees[0], item)
                                    }
                                    className="block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                  >
                                    Inschrijven
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="sm:flex sm:items-center">
                          <div className="sm:flex-auto mt-4">
                            <p className="mt-2 text-lg text-gray-700 dark:text-gray-300">
                              Afspraken voor de komende 24 uur of meer gemaakt
                              in calendly
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default Calendly;
