"use client";

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Input,
  Label,
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  fetchDataAlleGebruikers,
  fetchDataAlleGebruikersFilter,
  fetchPatienten,
} from "../apiCalls/usersApi";
import Pagination from "./Pagination";
import TabsFilter from "./TabsFilter";

const people = [
  {
    id: 1,
    full_name: "Leslie Alexander",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 1,
    full_name: "Peter",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 1,
    full_name: "Tom",
    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  // More users...
];

const SelectGebruikers = ({
  selected,
  setSelected,
  page,
  setPage,
  pageSize,
  setPageSize,
  desiredColumns,
  setType,
  setRole,
  role,
  type,
  data,
  setData,
  setLoading,
  setError,
  activeTab,
  setActiveTab,
}) => {
  const { patienten } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");

  // Add debounced search function
  const selectTab = useCallback((role, type) => {
    setType(type);
    setRole(role);
  }, []);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      
      fetchDataAlleGebruikersFilter(
        page,
        pageSize,
        setLoading,
        setError,
        setData,
        desiredColumns,
        role,
        type,
        searchQuery
      );
    }, 300),
    []
  );

  const tabs = [
    {
      id: 1,
      name: "Alle gebruikers",
      onClick: () => {
        selectTab(null, "all");
        setActiveTab("Alle gebruikers");
      },
    },
    {
      id: 2,
      name: "Specialisten",
      onClick: () => {
        selectTab(1, "role");
        setActiveTab("Specialisten");
      },
    },
    {
      id: 3,
      name: "Patienten",
      onClick: () => {
        selectTab(2, "role");
        setActiveTab("Patienten");
      },
    },
    {
      id: 4,
      name: "Niet Ingeschreven",
      onClick: () => {
        selectTab(999, "role");
        setActiveTab("Niet Ingeschreven");
      },
    },
  ];

  // Add debounced search function

  return (
    <div className="dark:bg-gray-700 ">
      <div className="flex justify-between items-center rounded-md m-2">
        <TabsFilter data={tabs} activeTab={activeTab} />
        <div className="flex w-full items-center px-2">
          <Input
            placeholder="Zoeken"
            className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
            onChange={(event) => {
              setSearch(event.target.value);
              debouncedSearch(event.target.value);
            }}
            onBlur={() => setSearch("")}
            displayValue={(person) =>
              person?.full_name ||
              person?.email ||
              person?.first_name ||
              person?.last_name ||
              person?.firstname ||
              person?.lastname
            }
          />

          <Pagination
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default SelectGebruikers;
