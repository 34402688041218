import React, { useCallback, useState } from "react";
import Papa from "papaparse";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { UserPlusIcon } from "@heroicons/react/20/solid";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import { AXIOS_POST, AXIOS_UPDATE } from "../server/api/crud";
import Spinner from "./Spinner";


const CSVReader = ({ open, setOpen }) => {
  const [data, setData] = useState([]);
  const [bulk, setBulk] = useState({users:[]});
  const [loading, setLoading] = useState(false)

  const desiredColumns = ["firstname", "lastname", "phone", "email", "notes"]; // Replace with your desired column names
  const [select, setSelect] = useState(null);

  const Change = (item) => {
  
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {

           // Create arrays to track already seen emails and phones
           const duplicateEmails = new Set();
           const duplicatePhones = new Set();
 

          const newArray = results.data.map((item) => ({
            id:uuid(),
            firstname: item["First Name"],
            lastname: item["Last Name"],
            phone: item["Phone"],
            email: item["Email"],
            notes: item["Notes"],
            appointment: item["Days Since Last Appointment"],
            password:`${item["First Name"]}_${item["Last Name"]}_${uuid().substring(0, 8)}`
          })).filter((row) => {
            const isUniqueEmail = !duplicateEmails.has(row.email);
            const isUniquePhone = !duplicatePhones.has(row.phone);

            // If either email or phone is unique, add it to the respective set
            if (isUniqueEmail) duplicateEmails.add(row.email);
            if (isUniquePhone) duplicatePhones.add(row.phone);

            // Return true to keep the row if it's unique by email or phone
            return isUniqueEmail && isUniquePhone;
          });

          setBulk({users:newArray})

          // Create arrays to track already seen emails and phones
          const seenEmails = new Set();
          const seenPhones = new Set();

          // Filter the data to include only the desired columns and remove duplicates
          const filteredData = newArray
            .map((row) =>
              desiredColumns.reduce((acc, col) => {
                if (row[col] !== undefined) {
                  acc[col] = row[col];
                }
                return acc;
              }, {})
            )
            .filter((row) => {
              const isUniqueEmail = !seenEmails.has(row.email);
              const isUniquePhone = !seenPhones.has(row.phone);

              // If either email or phone is unique, add it to the respective set
              if (isUniqueEmail) seenEmails.add(row.email);
              if (isUniquePhone) seenPhones.add(row.phone);

              // Return true to keep the row if it's unique by email or phone
              return isUniqueEmail && isUniquePhone;
            });

          setData(filteredData);
        },
        error: (error) => {
          console.error("Error reading CSV file: ", error);
        },
      });
    }
  };

  const saveCVS = useCallback(async () => {
    const chunkSize = 200; // Define the chunk size
    const delay = 2000; // Delay in milliseconds (2 seconds)
    setLoading(true);
    
    if (!bulk?.users?.length) {
      console.error("No users to process.");
      return;
    }
  
  
    // Function to introduce a delay
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const users = bulk?.users; // Extract users array from bulk
    for (let i = 0; i < users?.length; i += chunkSize) {
      const chunk = users.slice(i, i + chunkSize);
  
      // Send chunk to the server
      await AXIOS_POST(`userlist/bulk`, { users: chunk })
        .then((response) => {
          if (response.success === true) {
           // console.log(`Chunk of ${chunk?.length} users inserted successfully.`);
          } else {
            console.error("Error inserting users in chunk:", response?.message);
          }
        })
        .catch((error) => {
          console.error("Error with bulk request:", error);
        });
  
      // Wait before sending the next chunk
      await sleep(delay);
    }
  
    // Show success notification after all chunks are processed
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: `All ${bulk.users.length} users processed successfully.`,
      showConfirmButton: false,
      timer: 2500,
    });
    setLoading(false)
  }, [bulk]);
  

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-full overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative max-w-7xl dark:bg-gray-900 transform overflow-hidden rounded-lg bg-white px-1 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:py-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            {loading&& <Spinner/>}
            <div className="w-full">
              <div className="px-4 border rounded dark:border-gray-700">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto mt-4">
                    <input
                      type="file"
                      accept=".csv"
                      className="dark:text-gray-300"
                      onChange={handleFileUpload}
                    />{" "}
                    {data?.length > 0 && <span className="dark:text-gray-300">Aantal klanten in uw lijst: ({data.length})</span>}
                    <p className="mt-2 text-sm text-gray-700 dark:text-gray-300">
                      Geneer een de lijst van de patienten
                    </p>
                  </div>
                  {data?.length > 0 &&<div className="mt-4 flex sm:ml-16 sm:mt-0 sm:flex-none">
                    <button
                      type="button"
                      onClick={saveCVS}
                      className="block rounded-md bg-gray-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    >
                      Alles importeren
                    </button>
                  </div>}
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-2 lg:px-4">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50 dark:bg-gray-900">
                          <tr>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                            ></th>
                            {/* Dynamically render table headers based on data */}
                            {data.length > 0 &&
                              Object.keys(data[0]).map((key) => (
                                <th
                                  scope="col"
                                  className="relative py-3.5 pl-3 dark:text-white"
                                >
                                  {key}
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 dark:divide-gray-700  bg-white dark:bg-gray-800 ">
                          {data.length > 0 &&
                            data.map((row, index) => (
                              <tr key={index}>
                                <td>
                                  <div
                                    onClick={() => Change(row)}
                                    className="p-2 bg-green-100 hover:bg-green-200 m-2 cursor-pointer rounded-full border border-green-500"
                                  >
                                    <UserPlusIcon className="h-4 w-4 text-green-600" />
                                  </div>
                                </td>
                                {Object.values(row).map((value, i) => (
                                  <td
                                    className="relative dark:text-white whitespace-nowrap w-10 py-1 pl-3 pr-4 text-left text-sm font-medium sm:pr-6"
                                    key={i}
                                  >
                                    {value}
                                  </td>
                                ))}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default CSVReader;
