import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ArrowUturnLeftIcon, ChevronDownIcon, ChevronUpIcon, EllipsisVerticalIcon, FolderArrowDownIcon, PencilIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import TopSectionMail from "./TopSectionMail";
import { useMail } from "./MailContext";
import { useDispatch, useSelector } from "react-redux";
import { setCommand, setMailMessage, setMailMessageItems, setMailMessageItemsLoad } from "../../redux/mailSlice";
import moment from "moment";
import { getUser, getUserdetails } from "../../Localstorage/localstorage";
import ListChats from "../../Componenten/ListChats";

const MailList = ({ setOpen}) => {
 const dispatch = useDispatch();
 const userdetails = getUserdetails();

 const {
  mailMessage,
  mailMessageItemsFilter,
  mailMessageItems} = useSelector((state) => state.mail);

  useEffect(() => {
    let filterMessageItems = mailMessageItemsFilter.filter((mail) => mail?.mailmessage_id === mailMessage?.id);
    dispatch(setMailMessageItemsLoad(filterMessageItems));
  }, [mailMessage,mailMessageItemsFilter])


  const openModal = (p_type = 'insert') => {
      dispatch(setCommand(p_type))
      setOpen(true);
  } 


  return (
    <section
      aria-labelledby="message-heading"
      className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last "
    >
    {/* Top section */}
    <TopSectionMail mailMessage={mailMessage} setOpenModal={openModal} />

    <div className="min-h-0 flex-1 overflow-y-auto dark:bg-gray-800">
      <div className="bg-white dark:bg-gray-700 pb-6 pt-5 shadow dark:bg-gray-800">
        <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
          <div className="sm:w-0 sm:flex-1">
            <h1
              id="message-heading"
              className="text-lg font-medium text-gray-900 dark:text-white"
            >
              {mailMessage?.subject}
            </h1>
            <p className="mt-1 truncate text-sm text-gray-500  dark:text-gray-200">
              {userdetails.role_id == 1 ? `Patient: ${mailMessage?.sender || 'Geen patient gevonden'}` : `Spécialist: ${mailMessage?.receiver || 'Geen Specialist gevonden'}`}
            </p>
          </div>

          <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
            <span className="inline-flex items-center rounded bg-gray-100 px-3 py-0.5 text-sm font-medium text-cyan-800 dark:bg-gray-600 dark:text-white">
              {mailMessage?.category}
            </span>
          </div>
        </div>
      </div>
      {/* Thread section*/}
      <ListChats chats={mailMessageItems} user={userdetails}/>
    </div>
  </section>
  );
};

export default MailList;
