import React, { useEffect, useState } from "react";
import moment from "moment";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/firebase"; // Adjust the import path as needed
import { setDocumenten } from "../redux/documentenSlice";
import { PaperClipIcon } from "@heroicons/react/24/outline";
import ReInput from "./Layout/ReInput";
import { getUserdetails } from "../Localstorage/localstorage";

const Dropzone2 = ({
  setDocumenten,
  patient,
  setFile,
  user,
  setProgresses,
  progresses,
  updateFile,
  type = "documenten",
}) => {
  const [dragging, setDragging] = useState(false);
  const userdetails = getUserdetails();
  const gebruiker = userdetails?.role_id == 2 ? patient : userdetails;

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => setDragging(false);

  const uploadFileWithProgress = (file) => {
    return new Promise((resolve, reject) => {
      const newUuid = uuid();
      const fileRef = ref(storage, `${gebruiker?.full_name}/${type}/${file?.name}`);

      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Update progress
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          if (updateFile) {
            // If updateFile is true, just update the specific file progress without spreading the previous state.
            setProgresses((prev) => ({
              [file.name]: {
                title: "", // Initialize title as an empty string
                progress: progress, // Set the current progress value
              },
            }));
          } else {
            // If updateFile is false, update the file's progress and retain the previous state using spread.
            setProgresses((prev) => ({
              ...prev, // Keep the previous state intact
              [file.name]: {
                title: "", // Initialize title as an empty string
                progress: progress, // Set the current progress value
              },
            }));
          }
        },
        (error) => {
          console.error("Upload failed", error);
          reject(error);
        },
        async () => {
          // Get download URL once upload is complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve({ url, file });
        }
      );
    });
  };

  useEffect(() => {
    if (updateFile)
      setProgresses((prev) => ({
        [updateFile.bestand]: {
          title: updateFile.title, // Initialize the title as an empty string
          progress: 100, // Set the current progress value
        },
      }));
  }, [updateFile]);

  const handleFilesUpload = async (files) => {
    const uploadPromises = Array.from(files).map(uploadFileWithProgress);

    try {
      const uploadedFiles = await Promise.all(uploadPromises);
    
      // Function to save file details
      const saveFileDetails = (url, file) => {
        const commonDetails = {
          id: updateFile?.id || uuid(),
          datum: moment(file.lastModified).format("YYYY-MM-DD"),
          datetime: moment(file.lastModified).format("YYYY-MM-DD HH:mm:ss"),
          bestand: file.name,
          size: file.size,
          type: file.type,
          url, // Firebase file URL
          title: "",
          description: "",
        };
    
        if (userdetails.role_id === 1 && type === "advies") {
          return {
            ...commonDetails,
            user_id: patient?.user_id,
            admin_id: user?.user_id || userdetails?.user_id,
          };
        } else {
          const roleBasedDetails = 
            user?.role_id === 2 || userdetails?.role_id === 2
              ? { user_id: user?.user_id || userdetails?.user_id }
              : { admin_id: user?.user_id || userdetails?.user_id };
    
          const additionalDetails = 
            (user?.role_id === 1 || userdetails?.role_id === 1)
              ? { users: "[]", type }
              : {};
    
          return {
            ...commonDetails,
            ...roleBasedDetails,
            ...additionalDetails,
          };
        }
      };
    
      // Map uploaded files to saved file details
      const updatedDocuments = uploadedFiles.map(({ url, file }) => saveFileDetails(url, file));

    
      // Update state with new document details
      setDocumenten(updatedDocuments);
    
      // Show success notification
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Bestand is opgeladen`,
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      console.error("Error uploading files", error);
    
      // Show error notification
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Upload failed",
        showConfirmButton: false,
        timer: 2500,
      });
    } finally {
      // Optionally clear progress after upload completes
      // setProgresses({});
    }
  }
    

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    handleFilesUpload(files);
  };

  const handleClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*,application/pdf";
    fileInput.multiple = updateFile ? false : true;
    fileInput.onchange = handleDrop;
    fileInput.click();
  };

  const handleInput = (e, fileName) => {
    if (updateFile) {
      setFile({ ...updateFile, title: e.target.value });
    }
    setProgresses((prev) => ({
      ...prev,
      [fileName]: {
        ...prev[fileName],
        title: e.target.value,
      },
    }));
  };

  return (
    <div className="inline-flex gap-x-4 w-full items-start">
      <div
        className={`border-2 border-dashed rounded-lg p-10  text-center cursor-pointer transition-colors flex-none w-72 ${
          dragging ? "border-blue-500" : "border-gray-300"
        } bg-gray-50`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <svg
          className="w-16 text-gray-400 mx-auto light:text-neutral-400"
          width="70"
          height="46"
          viewBox="0 0 70 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* SVG Content */}
        </svg>
        <p className="mt-2 text-sm text-gray-500">
          Drag and drop files here, or click to upload
        </p>

        {/* Display progress bars */}
      </div>
      <div className="flex-1">
        <div className="grid grid-cols-1 gap-4 overflow-scroll max-h-90">
          {progresses && Object.keys(progresses).map((fileName) => (
            <div
              key={fileName}
              className="border border-gray-200 rounded-md p-4 flex items-center space-x-4 bg-white shadow-sm"
            >
              <div className="flex-1">
                <p className="text-gray-900 text-sm font-medium">{fileName}</p>
                <input
                  type={"text"}
                  className="p-2 border w-full text-sm my-2 rounded"
                  value={progresses[fileName]?.title}
                  onChange={(e) => handleInput(e, fileName)}
                  name={"title"}
                  placeholder={"Titel"}
                  id={"titel"}
                />
                <div className="w-full bg-gray-200 rounded h-2 mt-1">
                  <div
                    className="bg-green-500 h-3 rounded"
                    style={{ width: `${progresses[fileName]?.progress}%` }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropzone2;
