import React from "react";
import { imgrepeat } from "../image";
import "./loading.css"; // We'll create this CSS file for the loading animation
import { MutatingDots } from "react-loader-spinner";

const Spinner2 = ({ progress = 100 }) => {
  return (
    <div className="flex items-center justify-center w-auto min-h-screen bg-white z-50">
      <div className="w-72">
        <div role="status" className="flex justify-center">
        <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#4fa94d"
        secondaryColor="#4fa94d"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass="z-10"
      />
          <span className="sr-only">Loading...</span>
        </div>
        <div className="w-auto bg-gray-200 rounded mt-4">
          <div
            className="bg-green-800 text-xs font-medium text-white text-center p-0.5 leading-none rounded"
            style={{ width: `${progress}%` }}
          >
            {progress}%
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spinner2;
