import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Localstorage/localstorage";
import MailboxKlant from "./MailboxKlant";
import MailboxAdmin from "./MailboxAdmin";
import MailList from "./MailList";
import RPDrawerMessaging from "../../Componenten/Drawers/RPDrawerMessaging";
import { 
  setMailMessage, 
  setMailMessageItemsFilterLoad, 
  setMailMessageItemsLoad,  
  setMailRoomsLoad 
} from "../../redux/mailSlice";
import { AXIOS_GET } from "../../server/api/crud";
import { IOSOCKETCLIENT, setIOSocketListeners } from "../../linksocket/linksocket";
import { MAKE_SOCKET_CHANNEL, SOCKET_CHANNELS } from "../../constants/constants";
import { fetchMailMessages, fetchMailRooms } from "../../apiCalls/messageApi";

// Main MailBox Component
const MailBox = () => {
  const user = getUser();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const { mailMessage } = useSelector((state) => state.mail);

  const getMail = useCallback(async () => {
    await Promise.all([fetchMailRooms(user,dispatch,mailMessage,8,0,''), fetchMailMessages(user,dispatch)]);
  }, [dispatch, user?.id]); // Only depend on dispatch and user?.id

  const refreshMail = useCallback(() => {
    fetchMailRooms(user,dispatch,mailMessage,8,0,'')
    fetchMailMessages(user,dispatch);
  }, [getMail]);

  useLayoutEffect(() => {
    if (user?.id) {
      getMail(); // Fetch initial mail data
    }
  }, [user?.id, getMail]); // Depend only on user?.id

  useEffect(() => {
    if (user?.id && mailMessage?.id) {
      
      const channel = MAKE_SOCKET_CHANNEL(mailMessage?.id, SOCKET_CHANNELS.MAIL_ITEM);
      // Set socket listeners  console.log('mailMessage direct',mailMessage)  

      setIOSocketListeners(channel, SOCKET_CHANNELS.MAIL_ITEM, refreshMail);
      // Cleanup function on unmount
      return () => {
        IOSOCKETCLIENT.emit("JOINLEAVEROOM", { action: "leave", channel });
      };
    }
  }, [user, mailMessage?.id, refreshMail]); // Clean dependencies

  return (
    <div className={`flex ${user.role == 1 ? 'bg-gray-100' : 'bg-white'} h-full flex-col dark:bg-gray-900`}>
      <div className="flex min-h-0 flex-1 overflow-hidden">
        <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex h-dvh">
          <MailList setOpen={setOpenDialog} />
          {user?.role === 1 ? <MailboxAdmin /> : <MailboxKlant />}
        </main>
      </div>
      <RPDrawerMessaging open={openDialog} setOpen={setOpenDialog} />
    </div>
  );
};

export default MailBox;
