import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import RP_Avatar from "../RP_Avatar";

// UpcomingEvents Component
const DoubleMeetingEvents = ({ events }) => {
  return (
    <section className="mt-12">
      <h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">
        Komende Afspraken
      </h2>
      <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
        {events?.length === 0 ? (
          <li className="py-4 sm:flex">
            <p className="mt-2 flex-auto sm:mt-0 dark:text-white">
              Geen geplande afspraken
            </p>
          </li>
        ) : (
          events.map((event, index) => (
            <li key={index} className="py-4 sm:flex">
              <p className="mt-2 flex-auto flex items-center gap-x-2  text-gray-900 sm:mt-0 ">
{/*                 <RP_Avatar avatar={event?.user_avatar} size={12} alt={` ${event.user}`} />
 */}                <div>
                  <div>
                    <span className=" dark:text-gray-100 text-md mr-2">
                      {event?.invitees[0].name}
                    </span>
                    <span className="font-semibold dark:text-green-600">
                  {" "}
                   - {event.name}
                </span>
                  </div>
                  <time
                    dateTime={event.start_time}
                    className="w-40 flex-none mr-8 dark:text-gray-300 text-md"
                  >
                    {moment(event.start_time)
                      .locale("nl-be")
                      .format("dd, D MMMM YYYY")}
                  </time>
                </div>

               
              </p>
              {event.start_time && event.end_time && (
                <p className="flex-none sm:ml-6 dark:text-green-100">
                  <time dateTime={event.start_time}>
                    {moment(event.start_time).format("HH:mm")}
                  </time>{" "}
                  -{" "}
                  <time dateTime={event.end_time}>
                    {moment(event.end_time).format("HH:mm")}
                  </time>
                </p>
              )}
            </li>
          ))
        )}
      </ol>
    </section>
  );
};

export default DoubleMeetingEvents;
