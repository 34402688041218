import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid'
import moment from 'moment'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

const VragenlijstenResumer = ({timeline, user, vragenlijst}) => {

  const detail = {
    address_full: user?.userdetails_address_full,
    avatar : user?.userdetails_avatar,
    cellphone_number: user?.userdetails_cellphone_number,
    city: user?.userdetails_city,
    country:user?.userdetails_country,
    country_code :user?.userdetails_country_code,
    date_of_birth : user?.userdetails_date_of_birth,
    email: user?.userdetails_email,
    first_name :user?.userdetails_first_name,
    last_name :user?.userdetails_last_name,
    full_name: user?.userdetails_full_name,
    housenr: user?.userdetails_housenr,
    id :user?.userdetails_id,
    klacht : user?.userdetails_klacht,
    more_details : user?.userdetails_more_details,
    phone_number : user?.userdetails_phone_number,
    role_id : user?.userdetails_role_id,
    street : user?.userdetails_street,
    user_id: user?.userdetails_user_id
  }

  const navigate = useNavigate();


  const goToUser = () => {
    navigate("/healthforms", { state: detail});
  };

  return (
    <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
        Vragenlijsten
      </h2>

      {/* Activity Feed */}
      <div className="mt-6 flow-root">
        <ul role="list" className="-mb-8">
          {timeline.map((item, itemIdx) => {
             
             let pr = (vragenlijst?.preintake_verstuur == 1 && item.id == 1);  
              let kl = (vragenlijst?.klacht_verstuur == 1 && item.id == 2);
              let voed = (vragenlijst?.voeding_verstuur == 1 && item.id == 3);
            
            return (
            <li key={item.id}>
              <div className="relative pb-8">
                {itemIdx !== timeline.length - 1 ? (
                  <span
                    aria-hidden="true"
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        ( pr  || kl || voed ) ? 'bg-green-700' : 'bg-red-700',
                        'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white',
                      )}
                    >
                      {( pr  || kl || voed  ) ? <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" /> : <XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />  }
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">

                        {item.content}{' '}

                      </p>
                      <p className="text-xs text-gray-200">
                      <a href="#" className={`font-medium ${ ( pr  || kl || voed ) ? 'text-green-700' :'text-red-700'}`}>
                          {( pr  || kl || voed ) ? 'Verstuurd' : 'Niet verstuurd'}
                        </a>
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-sm text-gray-500">
                      <time dateTime={item.datetime}>{( pr  || kl || voed ) ? moment(vragenlijst.updated_at).format('DD/MM/YYYY') : ''}</time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
)})}
        </ul>
      </div>
      <div className="mt-6 flex flex-col justify-stretch">
        <button
          onClick={goToUser}
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
        >
         Ga naar Vragenlijsten
        </button>
      </div>
    </div>
  </section>  )
}

export default VragenlijstenResumer