import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, } from "react-redux";
import { AXIOS_DELETE, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import moment from "moment";
import {
  setAppointment,
  setAppointmentDelete,
  setAppointmentUpdate,
} from "../../redux/appointmentSlice";
import CrudEvent from "./CrudEvent";

export default function ShowInfoAfspraakInWacht({
  open = false,
  setOpen,
  selected,
  evenments,
  user,
}) {
  const [events, setEvents] = useState(evenments);
  const [openModal, setOpenModal] = useState(false);
  const [event, setEvent] = useState(null);
  const dispatch = useDispatch();


  useEffect(() => {
    setEvents(evenments);
  }, [evenments]);

  const Wijzigen = (item) => {
    setEvent(item);
    setOpenModal(true);
  };

  // Edit an event
  const handleEdit = async (event) => {
    let newObject = { ...event, admin_accept: 1 };
    try {
      const result = await Swal.fire({
        icon: "question",
        title: `Wilt u deze afspraak bevestigen opslaan?`,
        showCancelButton: true,
        confirmButtonText: "Opslaan",
        cancelButtonColor: "gray",
        confirmButtonColor: "#111827",
        cancelButtonText: "Annuleer",
      });
      if (result.isConfirmed) {
        await AXIOS_UPDATE(`afspraak`, event?.id, newObject).then((respons) => {
          if (respons.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Afspraak is bevestigd`,
              showConfirmButton: false,
              timer: 2500,
            });
            setOpen(false);
          }
        });
        dispatch(setAppointment(newObject));
      }
    } catch (error) {}
  };

  const handleBevestigen = async (e) => {
    const newObject = { ...event, admin_accept: 1 };
    
          try {
      const result = await Swal.fire({
        icon: "question",
        title: `Wilt u deze afspraak bevestigen en opslaan?`,
        showCancelButton: true,
        confirmButtonText: "Opslaan",
        cancelButtonText: "Annuleer",
        cancelButtonColor: "gray",
        confirmButtonColor: "#111827",
      });

      if (result.isConfirmed) {
        const response = await AXIOS_UPDATE(
          `afspraak/bevestigd`,
          event?.id,
          newObject
        );

        if (response.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Afspraak is bevestigd`,
            showConfirmButton: false,
            timer: 2500,
          });

          

          dispatch(setAppointmentUpdate(newObject));
          setOpen(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Er is een fout opgetreden",
            text: "Kon de afspraak niet bevestigen.",
            confirmButtonText: "OK",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Er is een fout opgetreden",
        text: error.message || "Er ging iets mis.",
        confirmButtonText: "OK",
      });
    }
  };

  // Delete an event
  const handleDelete = async (event) => {
    await AXIOS_DELETE(`afspraak`, event?.id, event).then((respons) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `Afspraak is bevestigd`,
        showConfirmButton: false,
        timer: 2500,
      });
      setOpen(false);
      dispatch(setAppointmentDelete(event));
    });
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <h1 className="text-2xl font-semibold mb-4">
              Afspraak <span className="text-sm">({events?.length})</span>
            </h1>
            <div className="overflow-x-auto whitespace-nowrap py-4">
              {events?.length > 0 &&
                events.map((event) => (
                  <div key={event.id} className="inline-block w-80 mr-2">
                    <div
                      className={`p-4 border rounded-md ${
                        event.color || "bg-gray-50"
                      }`}
                    >
                      {event.admin_accept === 0 && (
                        <h2 className="bg-orange-200 p-2 rounded mb-2 text-orange-800 font-semibold">
                          Deze afspraak is nog niet bevestigd
                        </h2>
                      )}
                      <h2 className="font-semibold">{event.type}</h2>
                      <p>{event.user}</p>
                      <p>
                        {moment(event.date, "YYYY-MM-DD")
                          .locale("nl-be")
                          .format("dddd LL")}
                      </p>
                      <p>
                        {moment(event.startTime, "HH:mm:ss").format("HH:mm")} -{" "}
                        {moment(event.endTime, "HH:mm:ss").format("HH:mm")}
                      </p>

                      <label>Omschrijving: </label>
                      <p>{event.description}</p>
                      <div className="flex justify-between">
                        <button
                          onClick={() => setOpen(false)}
                          className="bg-gray-800 text-white text-sm rounded mr-2 mt-2 py-2 px-4"
                        >
                          Sluiten
                        </button>
                        <div className="flex justify-end">
                          {event.user_id === user?.id && (
                            <button
                              onClick={() => Wijzigen(event)}
                              className="bg-green-700 text-white text-sm rounded mr-2 mt-2 py-2 px-4"
                            >
                              Wijzigen
                            </button>
                          )}
                          {event.user_id === user?.user_id && user?.user_id === selected?.user_id && (
                            <button
                              onClick={() => handleDelete(event)}
                              className="bg-red-800 text-sm text-white rounded mt-2 py-2 px-4"
                            >
                              Verwijderen
                            </button>
                          )}
                        </div>
                        {user?.role_id == 1 && (  <button
                            onClick={(e) => handleBevestigen(e, event)}
                            className="bg-green-800 text-white text-sm rounded mr-4 mt-2 py-2 px-4"
                          >
                            Bevestigen
                          </button>)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </DialogPanel>
        </div>
      </div>
      <CrudEvent
        handleDelete={handleDelete}
        setOpenModal={setOpenModal}
        openModel={openModal}
        afspraak={event}
      />
    </Dialog>
  );
}
