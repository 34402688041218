import React, { useCallback, useLayoutEffect, useState } from "react";
import Banner from "../../Layout/Banner";

/* 
import DynamicChart from "../../Componenten/Dashboard/DynamicChart";
import {
  faCalendar,
  faHeartbeat,
  faComments,
  faFile,
  faChartLine,
  faLifeRing,
} from "@fortawesome/free-solid-svg-icons"; */
import { AXIOS_GET } from "../../server/api/crud";
import {
  getKlacht,
  getUser,
  getVooruitgang,
} from "../../Localstorage/localstorage";
import RPProgressbar from "../../Componenten/Progressbar/RPProgressbar";
import RPCard from "../../Componenten/Cards/RPCard";
import RPProgressbarVragenlijst from "../../Componenten/Progressbar/RPProgressbarVragenlijst";
import { Line } from "react-chartjs-2";
import { vooruitgangTabs } from "../../function";
import moment from "moment";
import VooruitgangChart from "../../Componenten/Chartjs/VooruitgangChart";
import UserDashboardInfo from "../../Componenten/User/UserDashboardInfo";
import { Attachments } from "../../Componenten/Documenten/Attachments";

const calculateAverage = (data) => {
  const total = data?.reduce((sum, item) => sum + item.score, 0) || 0;
  return data?.length ? parseFloat((total / data.length).toFixed(2)) : 0;
};

const DashboardClient = () => {

  const [countKl, setcountKl] = useState(null);
  const [klachten, setklachten] = useState(null);
  const [preintake, setPreintake] = useState(null);
  const [averageScore, setAverageScore] = useState({
    slaap: 0,
    energie: 0,
    stress: 0,
    cravings: 0,
  })
  const [voedingsdagboek, setVoedingsdagboek] = useState(null);
  const [attachments, setAttachments] = useState([]);


  const user = getUser();
  const vooruitgang = getVooruitgang();
  const tabs = vooruitgangTabs(user, vooruitgang);

  const [metricData, setMetricData] = useState({
    slaap: [],
    energie: [],
    stress: [],
    cravings: [],
  });

  const [selectedMetric, setSelectedMetric] = useState(tabs[0]);
  useLayoutEffect(() => {
    const klachid = getKlacht();

    if (user) {
      const { id } = user;

      // Use Promise.all to fetch all data concurrently
      Promise.all([
        fetchDataKlacht(klachid?.id),
        fetchDataPreintake(id),
        fetchDataVoedingsdagboek(id),
        fetchVooruitgangData(id),
        getDocumentByID(id),
      ])
        .then(() => {
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  // Fetch Functions
  const fetchDataKlacht = useCallback(async (userId) => {
    try {
      const response = await AXIOS_GET(`klachtenregistratie/all/${userId}`);
      let totalValue = 0;

      const dataKlachten = response?.data;
      for (let key in dataKlachten) {
        if (dataKlachten.hasOwnProperty(key) && dataKlachten[key].length > 0) {
          totalValue += 1;
        }
      }


      const klacht = {
        title: "Klachtenregistratie formulier",
        subtitle: `U heeft al ${totalValue}/18 
                      van de vragenlijst ingevuld`,
        lijst: dataKlachten,
      };
      setklachten(klacht);
    } catch (error) {
      console.error("Error fetching data (klacht):", error);
      throw error; // Ensure errors are propagated to Promise.all
    }
  }, []);

  const fetchDataPreintake = useCallback(async (userId) => {
    try {
      const response = await AXIOS_GET(`preintake/${userId}`);
      if (response.data.data[0]) setPreintake(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data (preintake):", error);
      throw error; // Ensure errors are propagated to Promise.all
    }
  }, []);

  const fetchDataVoedingsdagboek = useCallback(async (userId) => {
    try {
      const response = await AXIOS_GET(`voedsel/${userId}`);
      if (response.data.data[0]) setVoedingsdagboek(response.data.data[0]);
    } catch (error) {
      console.error("Error fetching data (preintake):", error);
      throw error; // Ensure errors are propagated to Promise.all
    }
  }, []);
  const fetchVooruitgangData = useCallback(
    async (userId) => {
      try {
        const response = await AXIOS_GET(
          `vooruitgang/uservooruitgang/${userId}`
        );

        if (response.status !== 201) {
          console.warn("Unexpected response status:", response.status);
          return; // Exit early if the status isn't as expected
        }

        // Destructure data from the response
        const {
          slaapDatas = [],
          energieDatas = [],
          stressDatas = [],
          cravingsDatas = [],
        } = response.data;

    

        // Map and format the datasets only if they contain data
        const formatData = (data) =>
          data?.map((item) => ({
            ...item,
            date: moment(item.date).format("YYYY-MM-DD"),
          })) || [];

        const dataVooruitgang = {
          slaap: formatData(slaapDatas),
          energie: formatData(energieDatas),
          stress: formatData(stressDatas),
          cravings: formatData(cravingsDatas),
        };

        const averageScores = {
          slaap: calculateAverage(slaapDatas),
          energie: calculateAverage(energieDatas),
          stress: calculateAverage(stressDatas),
          cravings: calculateAverage(cravingsDatas),
        };

        // Update states
        setAverageScore(averageScores);

        // Update state efficiently
        setMetricData(dataVooruitgang);

        // Set selected metric based on data availability
        const defaultMetric = "slaap"; // Select the first available slaap data or default to tabs[0]
        setSelectedMetric(defaultMetric);
      } catch (error) {
        console.error("Error fetching data (vooruitgang):", error);
      }
    },
    [tabs] // Ensure tabs is included in dependencies
  );

  const getDocumentByID = useCallback(
    async (p_user_id) => {
      await AXIOS_GET(`documents/user/${p_user_id}`).then((res) => {
        setAttachments(res.data.data);
      });
    },
    [] // Ensure tabs is included in dependencies
  );

  return (
    <div className="p-8 dark:bg-gray-800 dark:text-gray-100 h-max">
      <UserDashboardInfo />
      <div className="mb-4 grid grid-cols-1 lg:grid-cols-2 gap-x-4">
        <div className="flex">
          {preintake &&
          klachten?.lijst &&
          Object.keys(klachten?.lijst).length > 0 ? (
            <div className="gap-4 flex-1">
              <RPProgressbarVragenlijst
                preintake={preintake}
                klachtenregistratie={klachten}
                voedingsdagboek={voedingsdagboek}
              />{" "}
            </div>
          ) : (
            <div className="w-full bg-gray-700 border-2 border-gray-600 rounded-md flex items-center justify-center">
              Geen vragenlijsten gevonden...
            </div>
          )}
        </div>
        <div className="flex flex-1">
          {attachments?.length > 0 ? (
            <Attachments attachments={attachments} />
          ) : (
            <div className="w-full dark:bg-gray-700 dark:text-gray-100 dark:border-gray-600 border-2 border-gray-200 rounded-md flex items-center justify-center">
              Geen documenten gevonden...
            </div>
          )}
        </div>
        
      </div>
      <VooruitgangChart
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        averageScore={averageScore}
        metricData={metricData}
      />

    </div>
  );
};

export default DashboardClient;
