import React, { useLayoutEffect, useState } from "react";
import Swal from "sweetalert2";
import { AXIOS_GET, AXIOS_POST, AXIOS_UPDATE } from "../../../../server/api/crud";
import { getUserdetails } from "../../../../Localstorage/localstorage";

const EtenNotities = ({ setSelected, setOpen, voeding, admin = null, userdetails}) => {
  
  const [foodDiary, setFoodDiary] = useState([
    {
      id: 1,
      maaltijd_type: "Ontbijt",
      eten: "",
      hoeveelheid: "",
      tijd: "08:00",
      opmerking: "",
      user_id: userdetails?.user_id,
      voeding_id: voeding?.id
    },
    {
      id: 2,
      maaltijd_type: "Tussendoor",
      eten: "",
      hoeveelheid: "",
      tijd: "10:00",
      opmerking: "",
      user_id: userdetails?.user_id,
      voeding_id: voeding?.id
    },
    {
      id: 3,
      maaltijd_type: "Middageten",
      eten: "",
      hoeveelheid: "",
      tijd: "12:00",
      opmerking: "",
      user_id: userdetails?.user_id,
      voeding_id: voeding?.id
    },
    {
      id: 4,
      maaltijd_type: "Tussendoor",
      eten: "",
      hoeveelheid: "",
      tijd: "14:00",
      opmerking: "",
      user_id: userdetails?.user_id,
      voeding_id: voeding?.id
    },
    {
      id: 5,
      maaltijd_type: "Avondeten",
      eten: "",
      hoeveelheid: "",
      tijd: "16:00",
      opmerking: "",
      user_id: userdetails?.user_id,
      voeding_id: voeding?.id
    },
    {
      id: 6,
      maaltijd_type: "Late avond snack",
      eten: "",
      hoeveelheid: "",
      tijd: "18:00",
      opmerking: "",
      user_id: userdetails?.user_id,
      voeding_id: voeding?.id
    },
  ]);

  const getData = async () => {
    await AXIOS_GET(
      `voedsel/voedsellog/user/${userdetails?.user_id}`).then((res) => {
      if (res.status == 200) {
        if(res.data.data.length > 0)
        setFoodDiary(res.data.data);
      }
    });
  };

  const disabled = admin != null ? true : false; 
  
  useLayoutEffect(() => {
    getData();
  }, []);

  const handleChange = (index, field, value) => {
    const newDiary = [...foodDiary];
    newDiary[index][field] = value;
    setFoodDiary(newDiary);
  };

  const handleNextPage = async() => {
    const newObject = {voedsel:foodDiary};

    if(userdetails?.role_id == 2){
      await AXIOS_POST(`voedsel/voedsellog`, newObject).then(
        (respons) => {
          if (respons.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Uw Eetgewoonten succesvol opgeslagen`,
              showConfirmButton: false,
              timer: 2500,
            });
            const {id} = voeding;
            setSelected(1);
            AXIOS_UPDATE(`voedsel/status`,id, {status:1}).then((respons) => {
              if (respons.status == 200) {
              }
            }).catch((error)=> {})
  
          }
        }
      ).then((error)=>{

      });
    }else{
      setSelected(1);
    }

  };



  return (
    <div className="mt-4 p-8 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 sm:max-w-6xl">
      <p className="text-center p-4 rounded-md bg-gray-200 ">
        1 Daags Eetdagboek
      </p>
      <div className="mt-6">
        {foodDiary.map((entry, index) => (
          <div key={index} className="mb-6">
            <div className="grid grid-cols-5 gap-4">
              <h3 className="font-semibold mb-2">{entry.maaltijd_type}</h3>

              <input
                type="text"
                placeholder="Wat"
                disabled={disabled}
                value={entry.eten}
                onChange={(e) => handleChange(index, "eten", e.target.value)}
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="number"
                placeholder="Hoeveel"
                disabled={disabled}
                value={entry.hoeveelheid}
                onChange={(e) =>
                  handleChange(index, "hoeveelheid", e.target.value)
                }
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="tijd"
                value={entry.tijd}
                disabled={disabled}
                onChange={(e) => handleChange(index, "tijd", e.target.value)}
                className="p-2 border border-gray-300 rounded"
              />
              <input
                type="text"
                placeholder="Opmerking"
                disabled={disabled}
                value={entry.opmerking}
                onChange={(e) =>
                  handleChange(index, "opmerking", e.target.value)
                }
                className="p-2 border border-gray-300 rounded"
              />
            </div>
          </div>
        ))}
        <div className="flex justify-between">
          <button
            onClick={() => setOpen(false)}
            className="mt-6 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded"
          >
            Annuleer
          </button>
          <button
            onClick={handleNextPage}
            className="mt-6 bg-green-700 hover:bg-green-800 text-white py-2 px-4 rounded"
          >
            Volgende 
          </button>
        </div>
      </div>
    </div>
  );
};

export default EtenNotities;
