import Swal from "sweetalert2";
import { benamingKlachten } from "../klantenregister";
import { AXIOS_POST, AXIOS_UPDATE } from "../server/api/crud";
import { getKlacht } from "../Localstorage/localstorage";

const updateKlachten = (categoryKey, itemKey, newValue,setKlachten,setCategorie) => {
  setKlachten((prevState) => ({
    ...prevState,
    [categoryKey]: {
      ...prevState[categoryKey],
      [itemKey]: newValue,
    },
  }));

  setCategorie((prevCate) => ({
    ...prevCate,
    [itemKey]: newValue,
  }));
};

export const versturenKlachten = async (klacht, setOpen) => {
    let verstuur = { versturen: 1 };
    await AXIOS_UPDATE(`klachtenregistratie/versturen`, klacht.id, verstuur).then(
      (res) => {
        if (res.status == 201) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Uw KlachtenFormulier is verstuurd`,
            showConfirmButton: false,
            timer: 2500,
          });
        }else{
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: `Uw KlachtenFormulier is niet verstuurd`,
            showConfirmButton: false,
            timer: 2500,
          });
        }

        setOpen(false);
      }
    );
  };
  
  export const opslaanKlachten = async (klacht, selectKlacht, setStates, categorie,setKlachten,setCategorie, setExtra) => {
    try {
      if (categorie?.klachtr_id == klacht.id){
        await AXIOS_UPDATE(
          `klachtenregistratie/${benamingKlachten[selectKlacht].link}`,klacht.id,
          {
            [benamingKlachten[selectKlacht].key]: categorie,
            klachtenregister: klacht,
          }
        ).then((respons) => {
          if(respons.status == 201){
            if (benamingKlachten[selectKlacht].id <= 16) {
              updateKlachten(benamingKlachten[selectKlacht].key,'klachtr_id',klacht?.id,setKlachten,setCategorie);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Categorie ${benamingKlachten[selectKlacht].title} is gewijzigd`,
              showConfirmButton: false,
              timer: 2500,
            });
           } else{
              Swal.fire(
                "De klachtenregistratie formulier is volledig wijzigd",
                "",
                "success"
              );
              setExtra(1);
            }

        
          }
        })
      }else{

        categorie.klachtr_id = klacht?.id;
        await AXIOS_POST(
          `klachtenregistratie/${benamingKlachten[selectKlacht].link}/${klacht.id}`,
          {
            [benamingKlachten[selectKlacht].key]: categorie,
            klachtenregister: klacht,
          }
        ).then((res) => {
          

          if (benamingKlachten[selectKlacht].id <= 16) {
            setStates({ id: benamingKlachten[selectKlacht].id + 1 });
            updateKlachten(benamingKlachten[selectKlacht].key,'klachtr_id',klacht?.id,setKlachten,setCategorie);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Categorie ${benamingKlachten[selectKlacht].title} is ingevuld`,
              showConfirmButton: false,
              timer: 2500,
            });
          } else {
            Swal.fire(
              "De klachtenregistratie formulier is volledig ingevuld",
              "",
              "success"
            );
            setExtra(1);
          }
        });
      }
      // If successful, you might want to handle the response here
      // For example, redirecting the user to another page
    } catch (error) {
      // Handle the error
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Waardes van de Categorie ${benamingKlachten[selectKlacht].title} zijn niet goed opgeslagen`,
        showConfirmButton: false,
        timer: 2500,
      });
      console.error("Error during sign-in:", error); // Log the error for debugging purposes
    }
  };
  export const opslaanOpmerkingKlachten = async (klacht, selectKlacht, setStates, categorie,admin, setOpen) => {
   try {
      categorie.admin_id = admin?.id;

      await AXIOS_UPDATE(
          `klachtenregistratie/${benamingKlachten[selectKlacht].link}/admin`,categorie.klachtr_id,
          {
            [benamingKlachten[selectKlacht].key]: categorie,
            klachtenregister: klacht,
          }
        ).then((respons) => {
          if(respons.status == 201){
            setStates({ id: benamingKlachten[selectKlacht].id + 1 });
            Swal.fire({
              position: "center",
              icon: "success",
              title: `Uw opmerking is goed opgeslagen`,
              showConfirmButton: false,
              timer: 1500,
            });
            if(benamingKlachten[selectKlacht].id > 16)
            setOpen(false);
          }
        })

      // If successful, you might want to handle the response here
      // For example, redirecting the user to another page
    } catch (error) {
      // Handle the error
      Swal.fire({
        position: "center",
        icon: "error",
        title: `Waardes van de Categorie ${benamingKlachten[selectKlacht].title} zijn niet goed opgeslagen`,
        showConfirmButton: false,
        timer: 2500,
      });
      console.error("Error during sign-in:", error); // Log the error for debugging purposes
    }
  };
  