import React from "react";
import { imgrepeat } from "../image";
import "./loading.css"; // We'll create this CSS file for the loading animation
import { MutatingDots } from "react-loader-spinner";

const Spinner = ({ versie = "repeat" }) => {
  if (versie != "repeat")
    return (
      <div className="flex  items-center justify-center w-auto min-h-screen opacity-20 bg-white z-50">
        <div role="status">
        <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#4fa94d"
        secondaryColor="#4fa94d"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass="z-10"
      />
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  return (
    <div className="flex  items-center justify-center w-auto min-h-screen bg-white z-50 opacity-80">
      <div role="status">
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#4fa94d"
        secondaryColor="#4fa94d"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass="z-10"
      />
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
