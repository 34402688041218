'use client'

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchPatienten } from '../apiCalls/usersApi'

const people = [
  {
    id: 1,
    full_name: 'Leslie Alexander',
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 1,
    full_name: 'Peter',
    avatar:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  {
    id: 1,
    full_name: 'Tom',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More users...
]

const  SelectPatienten = ({selected, setSelected, text="Zoek op patient"}) =>  {
  const [query, setQuery] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const {patienten} = useSelector(state => state.user);
  const [data, setData] = useState(patienten);


  // Add debounced search function
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      fetchPatienten(50, 0, searchQuery, setLoading, setError, setData);
    }, 300),
    []
  );

  return (
    <Combobox
      as="div"
      value={selected || data[0]}
      onChange={(person) => {
        setQuery('')
        setSelected(person)
      }}
    >
      {text && <Label className="dark:text-white block text-sm/6 font-medium text-gray-900">{text}</Label>}
      <div className="relative mt-2">
        <ComboboxInput
          className="block w-full dark:bg-gray-800 dark:text-white rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
          onChange={(event) => {
            setQuery(event.target.value);
            debouncedSearch(event.target.value);
          }}
          onBlur={() => setQuery('')}
          displayValue={(person) => person?.full_name}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {data.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-56 w-full dark:bg-gray-800 dark:text-white overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
            {data.map((person) => (
              <ComboboxOption
                key={person.id}
                value={person}
                className="group relative  cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white data-[focus]:outline-none"
              >
                <div className="flex items-center dark:text-white">
                  <img src={person?.avatar} alt="" className="size-6 shrink-0 rounded-full" />
                  <span className="ml-3 truncate group-data-[selected]:font-semibold">{person?.full_name}</span>
                </div>

                <span className="absolute inset-y-0 right-0 hidden items-center pr-4 text-indigo-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="size-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  )
}

export default SelectPatienten;