import React, { useEffect, useLayoutEffect, useState } from 'react'
import DocumentAdmin from '../DocumentAdmin';
import DocumentClient from '../DocumentClient';
import Spinner from '../../../Componenten/Spinner';
import { useLocation } from 'react-router-dom';
import { getUser, getUserdetails } from '../../../Localstorage/localstorage';
import { useSelector } from 'react-redux';
import { AXIOS_GET } from '../../../server/api/crud';
import AdviezenAdmin from '../AdviezenAdmin';
import AdviezenClient from '../AdviezenClient';

const Advies = () => {
  const user = getUser();
  const userdetails = getUserdetails();
  const [refresh, setRefresh] = useState(false);

  const { patienten } = useSelector((state) => state.user);
  const [patient, setPatient] = useState(patienten[0]);
  const [attachments, setAttachments] = useState([]);


  const getDocumentByID = async (id) => {
    await AXIOS_GET(`adviezen/user/${id}`).then((res) => {
      setAttachments(res.data.data);
    });
    setRefresh(false);
  };

  useLayoutEffect(() => {
    const id = (user?.role === 2) ? user?.id : patienten[0]?.user_id;
    getDocumentByID(id);
  }, []);

  useEffect(() => {
    if (refresh) {
      const id = (user?.role === 2) ? user?.id : patient?.user_id;
      getDocumentByID(id);
    }
  }, [refresh]);

  useEffect(() => {
    if (patient) getDocumentByID(patient.user_id);
  }, [patient]);

  if(user.role === 1) return <AdviezenAdmin  attachments={attachments} patienten={patienten} setPatient={setPatient} setRefresh={setRefresh} patient={patient} userdetails={userdetails}/>;
  if(user.role === 2) return <AdviezenClient attachments={attachments}  userdetails={userdetails}/>; 

  return (
    <Spinner/>
  );
}

export default Advies