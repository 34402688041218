import React from "react";
import {
  BookOpenIcon,
  CalendarDaysIcon,
  DocumentIcon,
  EnvelopeIcon,
  InboxIcon,
  ListBulletIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import RP_Avatar from "../RP_Avatar";
import { UserCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

/*appointment_admin_accept
appointment_admin_id
appointment_color
appointment_cols
appointment_date
appointment_datetime
appointment_details
appointment_end_time
appointment_id
appointment_location
appointment_name
appointment_start_time
appointment_title
appointment_type
appointment_user_id
eetdagboek_date
eetdagboek_id
eetdagboek_title
unread_messages_count
user_id
userdetails_address_full
userdetails_avatar
userdetails_cellphone_number
userdetails_city
userdetails_country
userdetails_country_code
userdetails_date_of_birth
userdetails_email
userdetails_first_name
userdetails_full_name
userdetails_housenr
userdetails_id
userdetails_last_name
userdetails_more_details
userdetails_phone_number
userdetails_role_id
userdetails_street
userdetails_user_id
userdetails_zipcode
vragenlijsten_klacht_verstuur
vragenlijsten_preintake_verstuur */

const DashboardPatientCards = ({ patienten, goToDetails }) => {
  return (
    <ul
      role="list"
      className="grid grid-cols-1  gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
    >
      {patienten?.length > 0 ? (
        patienten?.map((person) => (
          <li
            key={person.userdetails_email}
            className="dark:bg-gray-800 rounded-lg shadow dark:border-gray-600 dark:border-2 col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow cursor-pointer"
          >
            <div
              onClick={() => goToDetails(person)}
              className="flex flex-1 flex-col p-8 items-center"
            >
              <>
                {person?.userdetails_avatar ? (
                  <img
                    className={`h-32 w-32 flex-none rounded-full  object-cover`}
                    src={person?.userdetails_avatar}
                    alt={person?.userdetails_full_name}
                  />
                ) : (
                  <UserCircleIcon
                    className={`h-32 w-32 text-gray-300 rounded-full  object-cover`}
                    aria-hidden="true"
                  />
                )}
              </>

              <h3 className="mt-6 text-sm font-medium text-gray-900 dark:text-gray-100">
                {person?.userdetails_full_name}
              </h3>
              <dl className="mt-1 flex flex-grow flex-col justify-between">
                <dt className="sr-only">Klackt</dt>
                <dd className="text-xs text-gray-500 align-left p-1  dark:text-gray-200">
                  {person?.userdetails_klacht}
                </dd>
                {/*    <dd className="mt-3">
                  <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                    {person.role_1 == 2 ? "Klant" : "Klant"}
                  </span>
                </dd> */}
                {/*  <dd className="text-sm text-gray-500 mt-4 dark:text-gray-200 ">
                  <div className="grid grid-cols-3 justify-center gap-2">
                    <div
                      data-tooltip-target={`tooltip-vragenlijsten_${person?.user_id}`}
                      className="h-8 w-8 bg-gray-100 rounded-md p-1.5 dark:bg-gray-900"
                    >
                      <ListBulletIcon />
                    </div>
                    <div
                      id={`tooltip-vragenlijsten_${person?.user_id}`}
                      role="tooltip"
                      class="dark:bg-gray-900 absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                     {`tooltip-vragenlijsten_${person?.user_id}`}                      
                     <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <div
                      data-tooltip-target={`tooltip-afspraken_${person?.user_id}`}
                      className="h-8 w-8 bg-gray-100 rounded-md p-1.5 dark:bg-gray-900"
                    >
                      <CalendarDaysIcon />
                    </div>
                    <div
                      id={`tooltip-afspraken_${person?.user_id}`}
                      role="tooltip"
                      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                      tooltip-afspraken
                      <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <div
                      data-tooltip-target={`tooltip-eetdagboek_${person?.user_id}`}
                      className="h-8 w-8 bg-gray-100 rounded-md p-1.5 dark:bg-gray-900"
                    >
                      <BookOpenIcon />
                    </div>
                    <div
                      id={`tooltip-eetdagboek_${person?.user_id}`}
                      role="tooltip"
                      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                      Tooltip content
                      <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <div
                      data-tooltip-target={`tooltip-mail_${person?.user_id}`}
                      className="h-8 w-8 bg-gray-100 rounded-md p-1.5 dark:bg-gray-900"
                    >
                      <InboxIcon />
                    </div>
                    <div
                      id={`tooltip-mail_${person?.user_id}`}
                      role="tooltip"
                      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                      Tooltip content
                      <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                    <div
                      data-tooltip-target={`tooltip-documenten_${person?.user_id}`}
                      className="h-8 w-8 bg-gray-100 rounded-md p-1.5 dark:bg-gray-900"
                    >
                      <DocumentIcon />
                    </div>
                    <div
                      id={`tooltip-documenten_${person?.user_id}`}
                      role="tooltip"
                      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
                    >
                      Tooltip content
                      <div class="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </div>
                </dd> */}
                <dt className="sr-only">Role</dt>
              </dl>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <a
                    href={`mailto:${person?.userdetails_email}`}
                    className="dark:text-gray-100 relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <EnvelopeIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                    Email
                  </a>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <a
                    href={`tel:${person?.userdetails_phone_number}`}
                    className="dark:text-gray-100 relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <PhoneIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                    Call
                  </a>
                </div>
              </div>
            </div>
          </li>
        ))
      ) : (
        <li>Geen gegevens gevonden</li>
      )}
    </ul>
  );
};

export default DashboardPatientCards;
