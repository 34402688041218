import React, { useContext, useEffect, useLayoutEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './pages/dashboard/Dashboard'
import './App.css';
import Signin from './pages/signin/Signin.jsx';
import Layout from './Layout/Layout.jsx';
import Profiel from './pages/profiel/Profiel.jsx';
import Eetdagboek from './pages/eetdagboek/Eetdagboek.jsx';
import Evolution from './pages/evolution/Evolution.jsx';
import Payment from './pages/payment/Payment.jsx';
import Quiz from './pages/quiz/Quiz.jsx';
import RepeatSource from './pages/source/RepeatSource.jsx';
import Error404 from './pages/errors/Error404.jsx';
import Error500 from './pages/errors/Error500.jsx';
import Error503 from './pages/errors/Error503.jsx';
import NoPage from './pages/errors/NoPage.jsx';
import HealthForms from './pages/forms/HealthForms';
import Planning from './pages/Plannig/Planning';
import Documents from './pages/documents/Documents';
import MailBox from './pages/Mail/MailBox';
import Register from './pages/signin/Register';
import ChatRoom from './pages/chat/ChatRoom';
import DetailProfiel from './pages/dashboard/DatailPagina/DetailProfiel';
import { useDispatch } from 'react-redux';
import { APP_VERSION, getAccesToken, getAppVersion, getUser, getVersionClear, setAdmins, setAppVersion } from './Localstorage/localstorage';
import { setAdminsLoad, setGebruikersLoad, setPatientenLoad } from './redux/userSlice';
import { AXIOS_GET } from './server/api/crud';
import Admins from './pages/Admins/Admins';
import ProtectedRoute from './context/ProtectedRoute';
import { AuthContext } from './context/AuthContext';
import Advies from './pages/documents/Detail/Advies';
import Intakes from './pages/documents/Detail/Intakes';
import Plans from './pages/documents/Detail/Plans';
import Uploads from './pages/documents/Detail/Uploads';
import Gebruikers from './pages/Gebruikers/Gebruikers.jsx';
import CalendlyEvents from './pages/Calendly/CalendlyEvents.jsx';


function RoutesApp() {
  const dispatch = useDispatch();
  const user = getUser();
  const accestoken = getAccesToken();
  const {login} = useContext(AuthContext)


  useLayoutEffect(() => {
    const ophalenData = async () => {
      try {
            if (user?.role == 2){
              await AXIOS_GET(`users/all/admins`).then((response)=>{
                const admins = (response.data.data).filter((item) => item.user_id != '93d14850-bab4-de7d-4c8e-dd1cf3443891')
                setAdmins(admins);
                dispatch(setAdminsLoad(admins));
             }); 
            }else{
  
              await AXIOS_GET(`users/all/patienten`).then((response)=>{
                const patienten = response.data.data;
                dispatch(setPatientenLoad(patienten)) 
             }); 
  
             await AXIOS_GET(`users/all`).then((response)=>{
              const gebruikers = response.data.data;
              dispatch(setGebruikersLoad(gebruikers))
           }); 
            }
      } catch (error) {
        // Handle the error
        //Swal.fire("Er is een fout opgetreden bij het opslaan", "", "error");
       // alert('Er is een fout opgetreden bij het inloggen'); // Changed alert message for generic error handling
        console.error('Error during sign-in:', error); // Log the error for debugging purposes
      }
    };

    if(user){
      ophalenData();
    }

    login(accestoken)

  }, [])

  useEffect(() => {
    const localVersion = getAppVersion();

    if (localVersion !== APP_VERSION) {
      clearLocalData();
      setAppVersion(APP_VERSION); // Mettre à jour la version locale
    }
  }, []);

  const clearLocalData = () => {
    // Supprimer les données locales
    localStorage.clear();
    sessionStorage.clear();

    // Supprimer les cookies
    document.cookie.split(';').forEach((cookie) => {
      const name = cookie.split('=')[0].trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
    });

    // Supprimer les caches du service worker (si utilisés)
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => caches.delete(name));
      });
    }
  };


  return (
    <>
    {/*<Notifications item={3} />*/}
    <BrowserRouter>
      <Routes>
        <Route  path="/signin" element={<Signin />} />
        <Route  path="register" element={<Register />} />
        <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route index element={<Dashboard />} />
          <Route path="calendly" element={<CalendlyEvents/>} />
          <Route path="dashboarddetail" element={<DetailProfiel />} />
          <Route path="profiel" element={<Profiel />} />
          <Route path="eetdagboek" element={<Eetdagboek />} />
          <Route path="evolution" element={<Evolution />} />
          <Route path="payment" element={<Payment />} />
          <Route path="quiz" element={<Quiz />} />
          <Route path="admins" element={<Admins />} />
          <Route path="source" element={<RepeatSource />} />
          <Route path="error500" element={<Error500 />} />
          <Route path="error404" element={<Error404 />} />
          <Route path="error503" element={<Error503 />} />
          <Route path="healthforms" element={<HealthForms />} />
          <Route path="planning" element={<Planning />} />
          <Route path="documents" element={<Documents />} />
          <Route path="users" element={<Gebruikers />} />
          <Route path="documents/intakes" element={<Intakes />} />
          <Route path="documents/advies" element={<Advies />} />
          <Route path="documents/plans" element={<Plans />} />
          <Route path="documents/uploads" element={<Uploads />} />
          <Route path="mailbox" element={<MailBox />} />
          <Route path="chatroom" element={<ChatRoom />} />
          <Route path="*" element={<Dashboard />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default RoutesApp;
