import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import { AXIOS_POST, AXIOS_UPDATE } from "../../server/api/crud";
import Swal from "sweetalert2";
import moment from "moment";
import {
  setAppointment,
  setAppointmentInWacht,
  setAppointmentLoad,
  setAppointmentUpdate,
} from "../../redux/appointmentSlice";
import Select2 from "../Select2";
import {
  getAdmins,
  getUser,
  getUserdetails,
} from "../../Localstorage/localstorage";
import SelectAdmins from "../SelectAdmins";
import SelectPatienten from "../SelectPatienten";
import SelectAdmins2 from "../SelectAdmins2";

function calculateFollowHour(type, time = "12:45:00") {
  // Create a Date object with the starting time
  const [hours, minutes] = time.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes);

  // Add 46 minutes
  if (type === "Follow up - 46min") {
    date.setMinutes(date.getMinutes() + 46);
  } else {
    date.setMinutes(date.getMinutes() + 60);
  }

  // Format the result in HH:MM:SS
  const result = date.toTimeString().split(" ")[0].slice(0, 5); // Extract HH:mm
  return result;
}

function getColor(type) {
  let result = "";
  // Add 46 minutes
  if (type == "Follow up - 46min") {
    result = "green";
  } else {
    result = "green";
  }

  return result;
}

export default function CrudEvent({
  openModel = false,
  setOpenModal,
  setOpen,
  afspraak = null,
  handleEdit,
  handleDelete,
}) {
  const userdetails = getUserdetails();
  const user = getUser();

  const { patienten } = useSelector((state) => state.user);
  const admins = getAdmins();

  const gebruikers = user.role == 2 ? admins[0] : patienten[0];
  const userdetail = userdetails || getUserdetails();
  const gebruiker = user || getUser();
  const updateGebruiker = afspraak ? patienten.find((geb) => geb?.full_name == afspraak?.user): gebruikers;

  const [selected, setSelected] = useState(updateGebruiker);
  const [selectedAdmin, setSelectedAdmin] = useState(userdetail);
  const [event, setEvent] = useState(null);
  const dispatch = useDispatch();
  const [newEvent, setNewEvent] = useState(
    afspraak || {
      id: null,
      title: "",
      description: "",
      startTime: "08:00",
      endTime: "",
      type: "Follow up - 46min",
      color: "",
      name: "",
      date: "",
      cols: 0,
      datetime: "",
      user_id: gebruiker?.role == 2 ? userdetail?.user_id : selected?.user_id,
      user: gebruiker?.role == 2 ? userdetail?.full_name : selected?.full_name,
      user_avatar: gebruiker?.role == 2 ? userdetail?.avatar : selected?.avatar,
      admin_id: gebruiker?.role == 1 ? userdetail?.user_id : selected?.user_id,
      admin: gebruiker?.role == 1 ? userdetail?.full_name : selected?.full_name,
      location: "",
    }
  );

  const eventUserMapping = {
    "Follow up - 46min": [
      "Marleen Verschroeven",
      "Natasja Voorspoels",
      "Cara Rosiers",
      "Lindsey Pauwels",
    ],
    "Follow up EFT - 1uur": [
      "Marleen Verschroeven",
      "Natasja Voorspoels",
      "Cara Rosiers",
      "Lindsey Pauwels",
    ],
    "Free Health Talk": ["Marleen Verschroeven"],
    "EFT intake online": ["Natasja Voorspoels"],
    "EFT intake in person": ["Natasja Voorspoels"],
    "Intake online": [
      "Marleen Verschroeven",
      "Natasja Voorspoels",
      "Cara Rosiers",
      "Lindsey Pauwels",
    ],
    "Intake in person": [
      "Marleen Verschroeven",
      "Natasja Voorspoels",
      "Cara Rosiers",
      "Lindsey Pauwels",
    ],
  };

  useEffect(() => {
    if (afspraak) setNewEvent(afspraak);
    return setSelected(null);
  }, [afspraak, openModel]);

  // Create or Update event
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (afspraak) {
        let newobject = newEvent;
        newobject.date = moment(newEvent?.datetime).format("YYYY-MM-DD");

        await AXIOS_UPDATE(`afspraak`, newEvent?.id, newobject).then(
          (respons) => {
            if (respons.status == 200) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: `Afspraak is aangemaakt`,
                showConfirmButton: false,
                timer: 2500,
              });
            }
          }
        );
        dispatch(setAppointmentUpdate(newEvent));
        setOpenModal(false);
        setOpen(false);
      } else {
        // Create new event
        // Update existing event
        let newobject = newEvent;
        newobject.id = uuid();
        newobject.date = moment(newEvent?.datetime).format("YYYY-MM-DD");
        newobject.user_id =
          gebruiker?.role == 2 ? userdetail?.user_id : selected?.user_id;
        newobject.user =
          gebruiker?.role == 2 ? userdetail?.full_name : selected?.full_name;
        newobject.user_avatar =
          gebruiker?.role == 2 ? userdetail?.avatar : selected?.avatar;
        if (user.hoofd_admin == 1) {
          newobject.admin_id = selectedAdmin?.user_id;
          newobject.admin = selectedAdmin?.full_name;
          newobject.admin_accept = 1;
        } else {
          newobject.admin_id =
            gebruiker?.role == 1 ? userdetail?.user_id : selected?.user_id;
          newobject.admin =
            gebruiker?.role == 1 ? userdetail?.full_name : selected?.full_name;
          newobject.admin_accept = userdetail?.role_id == 1 ? 1 : 0;
        }

        newobject.datetime = moment(newEvent?.datetime).format(
          "YYYY-MM-DD HH:MM:SS"
        );

        await AXIOS_POST(`afspraak`, newobject).then((respons) => {
          if (respons.status == 200) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: `Afspraak is aangemaakt`,
              showConfirmButton: false,
              timer: 2500,
            });
          }
        });
        if (userdetail?.role_id == 1) {
          dispatch(setAppointment(newobject));
        } else {
          dispatch(setAppointmentInWacht(newobject));
        }
        resetForm();
        setOpenModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setNewEvent((prev) => ({
      ...prev,
      endTime: calculateFollowHour(newEvent.type, newEvent.startTime),
    }));
  }, [newEvent.startTime, newEvent.type]);

  useEffect(() => {
    setNewEvent((prev) => ({
      ...prev,
      cols:
        moment(newEvent.datetime).day() == 0
          ? "col-start-7"
          : `col-start-${moment(newEvent.datetime).day()}`,
    }));
  }, [newEvent.datetime]);

  // Reset form to initial state
  const resetForm = () => {
    setNewEvent({
      id: null,
      title: "",
      description: "",
      startTime: "",
      endTime: "",
      type: "Follow up - 46min",
      color: "",
      name: "",
      date: "",
      datetime: "",
      user_id: gebruiker.role == 2 ? userdetail.user_id : "",
      gebruiker: gebruiker.role == 2 ? userdetail.full_name : "",
      user_avatar: userdetail.avatar,
      admin_id: gebruiker.role == 1 ? userdetail.user_id : "",
      admin: gebruiker.role == 1 ? userdetail.full_name : "",
      location: "",
    });
    setOpenModal(false);
  };

  const filteredAdmins = newEvent?.type
    ? admins.filter((admin) =>
        eventUserMapping[newEvent?.type]?.includes(admin?.full_name)
      )
    : admins;


  return (
    <Dialog open={openModel} onClose={setOpenModal} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-10 w-screen overflow-y-auto ">
        <div className="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform dark:bg-gray-800 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="container mx-auto p-4 ">
              <h1 className="text-2xl font-semibold mb-4 dark:text-white">
                Afspraak
              </h1>

              {/* Event Form */}
              <form className="mb-6" onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 my-4">
                  <select
                    id="category"
                    name="category"
                    defaultValue={newEvent.category}
                    onChange={(e) => {
                      setNewEvent({
                        ...newEvent,
                        type: e.target.value,
                        color: getColor(newEvent.type),
                      });
                      setSelectedAdmin({});
                    }}
                    className="border p-2 rounded dark:bg-gray-800 dark:text-white"
                  >
                    <option value={"Follow up - 46min"}>
                      Follow up - 46min
                    </option>
                    <option value={"Follow up EFT - 1uur"}>
                      Follow up EFT - 1uur
                    </option>
                    <option value="Free Health Talk">Free Health Talk</option>
                    <option value="EFT intake online">EFT intake online</option>
                    <option value="EFT intake in person">
                      EFT intake in person
                    </option>
                    <option value="Intake online">Intake online</option>
                    <option value="Intake in person">Intake in person</option>
                  </select>
                </div>

                <div className=" my-4">
                  {user?.hoofd_admin == 1 && (
                    <SelectAdmins2
                      selected={
                        selectedAdmin
                          ? selectedAdmin
                          : filteredAdmins?.length > 0
                          ? filteredAdmins[0]
                          : userdetails
                      }
                      setSelected={setSelectedAdmin}
                      data={filteredAdmins || []}
                    />
                  )}

                  {user?.role == 2 && admins?.length > 0 && (
                    <SelectAdmins2
                      selected={selected ? selected : admins[0]}
                      setSelected={setSelected}
                      data={filteredAdmins || []}
                    />
                  )}

                  {user?.role == 1 && patienten?.length > 0 && (
                    <SelectPatienten
                      selected={selected ? selected : updateGebruiker}
                      setSelected={setSelected}
                      data={patienten}
                    />
                  )}
                </div>

                <div className="grid grid-cols-1 my-4">
                  <input
                    type="date"
                    placeholder="datum"
                    className="border p-2 dark:bg-gray-800 dark:text-white rounded"
                    value={moment(newEvent.datetime).format("YYYY-MM-DD")}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, datetime: e.target.value })
                    }
                    required
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <select
                    className="border p-2 dark:bg-gray-800 dark:text-white rounded"
                    value={moment(newEvent.startTime, "HH:mm:ss").format(
                      "HH:mm"
                    )}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, startTime: e.target.value })
                    }
                    required
                  >
                    {/* Display time options in :00 and :30 increments */}
                    {Array.from({ length: 11 * 4 }, (_, i) => {
                      const totalMinutes = 10 * 60 + i * 15; // Start at 10:00 (10 * 60) and add 15-minute intervals
                      const hour = Math.floor(totalMinutes / 60)
                        .toString()
                        .padStart(2, "0"); // Calculate hour
                      const minutes = (totalMinutes % 60)
                        .toString()
                        .padStart(2, "0"); // Calculate minutes
                      return (
                        <option
                          key={`${hour}:${minutes}`}
                          value={`${hour}:${minutes}`}
                        >
                          {`${hour}:${minutes}`}
                        </option>
                      );
                    })}
                  </select>
                  {/*             <input
                    type="time"
                    placeholder="Start Time"
                    className="border p-2"
                    value={newEvent.startTime}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, startTime: e.target.value })
                    }
                    step={"1800"}
                    required
                  /> */}
                  <span
                    placeholder="End Time"
                    className="border p-2 dark:bg-gray-800 dark:text-white rounded"
                  >
                    {moment(newEvent.endTime, "HH:mm:ss").format("HH:mm")}
                  </span>
                </div>
                <div className="grid grid-cols-1 mt-4">
                  <textarea
                    type="text"
                    rows={3}
                    placeholder="Opmerking"
                    className="border p-2 dark:bg-gray-800 dark:text-white rounded"
                    value={newEvent?.description}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, description: e.target.value })
                    }
                  />
                </div>
                <button
                  type="submit"
                  className="bg-green-800 text-white px-4 py-2 mt-4 rounded"
                >
                  {afspraak ? "Afspraak wijzigen" : "Afspraak maken"}
                </button>
                {afspraak && (
                  <button
                    type="button"
                    onClick={resetForm}
                    className="bg-gray-500 text-white px-4 py-2 mt-4 ml-4 rounded"
                  >
                    Cancel
                  </button>
                )}
              </form>

              {/* Event List */}
              {/*  <div className="grid gap-4">
                  <div
                    key={event.id}
                    className={`p-4 border rounded-md ${event.color}`}
                  >
                    <h2 className="font-semibold">{event.title}</h2>
                    <p>{event.date}</p>
                    <p>
                      {event.startTime} - {event.endTime}
                    </p>
                    <p>Person: {event.gebruiker}</p>
                    <label>Omschrijving: </label>
                    <p>{event.description}</p>
                    <button
                      onClick={() => handleEdit(event)}
                      className="text-blue-500 mr-4"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(event.id)}
                      className="text-red-500"
                    >
                      Delete
                    </button>
                  </div>
                </div>*/}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
