import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import {
  InlineWidget,
  PopupWidget,
  useCalendlyEventListener,
} from "react-calendly";
import { getAdmins, getUserdetails } from "../../Localstorage/localstorage";
import RP_Avatar from "../RP_Avatar";
import { filterAdminsAgenda } from "../../function";
import { classNames } from "./calenderUtilties";

function phoneNumber(phoneNumber) {
  // Check if the first character is '0' and remove it
  if (phoneNumber.startsWith("0")) {
    const numberphone = phoneNumber.substring(1);
    return `32${numberphone}`; // or phoneNumber.slice(1)
  }
  return `32${phoneNumber}`; // Return as-is if no leading '0'
}

const CrudEventCalendly = ({ openModel = false, setOpenModel }) => {
 /* useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload, e, e.data),
    onPageHeightResize: (e) => console.log(e.data.payload.height),
  });*/

  const admins = getAdmins();
  const usersdetails = getUserdetails();
  const eventUserMapping = {
    "free-health-talk": filterAdminsAgenda(admins, ["repeat-marleen"]),
    "eft-intake-online": filterAdminsAgenda(admins, ["repeat-natasja"]),
    "eft-intake-in-person": filterAdminsAgenda(admins, ["repeat-natasja"]),
    "intake-online": filterAdminsAgenda(admins, [
      "repeat-marleen",
      "repeat-natasja",
      "repeat-cara",
      "repeat-lindsey",
    ]),
    "intake-in-person": filterAdminsAgenda(admins, [
      "repeat-marleen",
      "repeat-natasja",
      "repeat-cara",
      "repeat-lindsey",
    ]),
    "eft-follow-up-in-person": filterAdminsAgenda(admins, ["repeat-natasja"]),
    "eft-follow-up-online": filterAdminsAgenda(admins, ["repeat-natasja"]),
    "follow-up-in-person": filterAdminsAgenda(admins, [
      "repeat-marleen",
      "repeat-natasja",
      "repeat-cara",
      "repeat-lindsey",
    ]),
    "follow-up-online": filterAdminsAgenda(admins, [
      "repeat-marleen",
      "repeat-natasja",
      "repeat-cara",
      "repeat-lindsey",
    ]),
  };

  const [selectedEvent, setSelectedEvent] = useState("free-health-talk");
  const [selectedUser, setSelectedUser] = useState(admins[0]);

  const [availableUsers, setAvailableUsers] = useState(
    eventUserMapping[selectedEvent]
  );

  useEffect(() => {
    setAvailableUsers(eventUserMapping[selectedEvent]);
    setSelectedUser(eventUserMapping[selectedEvent][0]);
  }, [selectedEvent]);

  const handleEventChange = (e) => {
    setSelectedEvent(e.target.value);
  };

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const calendlyUrl = `https://calendly.com/${selectedUser?.calendly_info?.slug}/${selectedEvent}?primary_color=306535&hide_gdpr_banner=1`;

  const handleOpenWidget = () => {
    setOpenModel(true);
  };

  const handleCloseWidget = () => {
    setOpenModel(false);
  };

  return (
    <Dialog open={openModel} onClose={setOpenModel} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-full overflow-y-auto">
        <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative w-3/4 dark:bg-gray-900 transform overflow-hidden rounded-lg bg-transparent text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div style={{ padding: "8px" }}>
              <div className="grid grid-cols-8 border border-gray-200 p-4 rounded relative">
                {/* Event Dropdown */}
                <select
                  className="m-4 rounded border p-2 text-white col-span-2 dark:text-white bg-transparent dark:bg-gray-900 flex-1"
                  value={selectedEvent}
                  onChange={handleEventChange}
                  style={{ marginBottom: "8px" }}
                >
                  <option value="free-health-talk">Free Health Talk</option>
                  <option value="eft-intake-online">EFT intake online</option>
                  <option value="eft-intake-in-person">
                    EFT intake in person
                  </option>
                  <option value="intake-online">Intake online</option>
                  <option value="intake-in-person">Intake in person</option>
                  <option value="eft-follow-up-online">
                    EFT Follow-Up Online
                  </option>
                  <option value="eft-follow-up-in-person">
                    EFT Follow-Up In Person
                  </option>
                  <option value="follow-up-in-person">
                    Follow-Up In Person
                  </option>
                  <option value="follow-up-online">Follow-Up Online</option>
                </select>
                <div className="absolute bg-gray-500 bg-opacity-90 w-full h-full top-0 -z-10" />

                {/* User Dropdown */}

                <div className="flex col-span-6 grid grid-cols-4">
                  {availableUsers.map((user, index) => (
                    <div
                      onClick={() => setSelectedUser(user)}
                      key={index}
                      className={classNames(
                        "mx-2 gap-4 flex p-2 items-center rounded-lg",
                        selectedUser?.calendly_info?.slug ==
                          user?.calendly_info?.slug
                          ? " shadow bg-green-700 dark:bg-gray-800"
                          : "dark:bg-gray-900 border border-gray-200"
                      )}
                    >
                      <RP_Avatar avatar={user?.avatar} size={12} />
                      <div>
                        <p className="text-white text-xs">
                          {user?.first_name}
                        </p>
                        <p className="text-white text-xs">
                          {user?.last_name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Calendly Embed */}
              <div
                id="calendlyEmbed"
                style={{
                  marginTop: "  0px",
                  display: selectedUser && selectedEvent ? "block" : "none",
                }}
              ></div>
              <InlineWidget
                url={calendlyUrl}
                prefill={{
                  email: usersdetails?.email,
                  firstName: usersdetails?.first_name,
                  lastName: usersdetails?.last_name,
                  name: usersdetails?.full_name,
                  smsReminderNumber: "32471634353",
                  guests: [],
                  customAnswers: {
                    a1: usersdetails?.phone_number
                      ? phoneNumber(usersdetails?.phone_number)
                      : "",
                  },
                  date: new Date(Date.now() + 86400000),
                }}
                styles={{ height: "700px", width: "100%", marginTop: 0 }}
              />
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default CrudEventCalendly;
