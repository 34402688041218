import { faFileImage, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import emailjs from '@emailjs/browser';
import Swal from "sweetalert2";


export function capitalizeFirstLetter(string) {
    if (!string) return ''; // Handle empty or null strings
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  export const getFormattedMonth = (locale = 'nl-be') => {
    return moment().locale(locale).format("MMMM YYYY");
  };

  export const documentenTab = (aantal) => [ 
    {
      id: 1,
      title: "Adviezen",
      href:"/documents/advies",
      button:'Ga naar',
      subtitle:`(De adviezen van de specialisten)`,
    },
    {
      id: 2,
      title: "Plans",
      href:"/documents/plans",
      button:'Ga naar',
      subtitle:`(Mealplans - Voedingsmiddelentabel - Recepten) `,    
    },
    {
      id: 3,
      title: "Documenten",
      href:"/documents/uploads",
      button:'Ga naar',
      subtitle:`(Uw opgeladen documenten) `,    
    },
  ];

  export const vooruitgangTabs = (user,vooruitgang) => [
    {
      id: 1,
      naam: "Slaapkwaliteit",
      key: "slaap",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
    {
      id: 2,
      naam: "Energieniveau",
      key: "energie",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
    {
      id: 3,
      naam: "Stressniveau",
      key: "stress",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
    {
      id: 4,
      naam: "Cravings",
      key: "cravings",
      vooruitgang_id: vooruitgang?.id || 0,
      user_id: user?.id,
      score: 0,
      uitleg:"",
      date: moment().format("YYYY-MM-DD"),
    },
  ];

  export  const CalculateAge = ( birthDate ) => {
    // Get the current date
    const currentDate = moment();
    
    // Create a moment object for the birthdate
    const birthDateMoment = moment(birthDate, 'YYYY-MM-DD'); // Adjust format as needed

    // Calculate the age
    const age = currentDate.diff(birthDateMoment, 'years');
    
    return age
  };

  export const typeFile = (type) => {

    return type === 'application/pdf' ?  faFilePdf : faFileImage;
  
  }

  export const nvl = (pa,pb) => {
    if(pa == '' || pa == undefined || pa == null)
     return pb;

     return pa;
  }

  export const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  }

  export  const sendEmail = (template = 'template_qdoh9mv', emailData, succestitle = `Mail is verstuurd`, errortitle = `Mail is niet verstuurd` ) => {
    // Object with dynamic data

    emailjs
      .send(
        'service_7sqyhaa', // Replace with your Email.js Service ID
        template, // Replace with your Email.js Template ID
        emailData, // Send the object as the email data
        'O46K1IuzXms48aGlu' // Replace with your Email.js Public Key
      )
      .then(
        (result) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: succestitle,
            showConfirmButton: false,
            timer: 2500,
          });
        },
        (error) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: errortitle,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      );
  };

  export const appointmentss = {
    "collection": [
      {
        "calendar_event": {
          "external_id": "463gpln5sm50hpldof0kqckcic",
          "kind": "google"
        },
        "created_at": "2024-10-29T17:23:30.232447Z",
        "end_time": "2024-10-30T18:00:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2024-10-30T18:00:00.000000Z",
            "buffered_start_time": "2024-10-30T17:30:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/3dcf63e1-dc1e-416a-9d7f-5fbfa43dda11",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "location": null,
          "type": "custom"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "30 Minute Meeting",
        "start_time": "2024-10-30T17:30:00.000000Z",
        "status": "active",
        "updated_at": "2024-10-29T17:23:32.161602Z",
        "uri": "https://api.calendly.com/scheduled_events/62c47bc6-5323-4edf-b723-c07caac02beb"
      },
      {
        "calendar_event": {
          "external_id": "ohk9141cunrund4ipk0iql6sp0",
          "kind": "google"
        },
        "created_at": "2025-01-02T20:34:00.582947Z",
        "end_time": "2025-01-03T15:00:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-01-03T15:00:00.000000Z",
            "buffered_start_time": "2025-01-03T14:00:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/8016dfc7-2996-42ad-982b-46fed26c88b8",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "additional_info": "",
          "location": "Grotehondstraat 32 - 2018 Antwerpen",
          "type": "physical"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Intake In Person ",
        "start_time": "2025-01-03T14:00:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-02T20:34:01.873109Z",
        "uri": "https://api.calendly.com/scheduled_events/e23a1554-6737-4385-bfe2-3e9704be8893"
      },
      {
        "calendar_event": {
          "external_id": "ii7q64jga144qn7h1mr1bshlg4",
          "kind": "google"
        },
        "created_at": "2025-01-02T22:06:42.216046Z",
        "end_time": "2025-01-07T11:00:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-01-07T11:00:00.000000Z",
            "buffered_start_time": "2025-01-07T10:00:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/8016dfc7-2996-42ad-982b-46fed26c88b8",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "additional_info": "",
          "location": "Grotehondstraat 32 - 2018 Antwerpen",
          "type": "physical"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Intake In Person ",
        "start_time": "2025-01-07T10:00:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-02T22:06:43.221787Z",
        "uri": "https://api.calendly.com/scheduled_events/1f6a1464-8878-4ec1-ae90-133f3e8a9250"
      },
      {
        "calendar_event": {
          "external_id": "kjvq22kuvid977a1262jc58n30",
          "kind": "google"
        },
        "created_at": "2025-01-02T23:54:13.594730Z",
        "end_time": "2025-01-07T18:15:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-01-07T18:15:00.000000Z",
            "buffered_start_time": "2025-01-07T18:00:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/50350778-3550-43e3-9932-885c32f6d41b",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "data": {
            "id": 81924646573,
            "settings": {
              "global_dial_in_numbers": [
                {
                  "country_name": "US",
                  "number": "+1 646 931 3860",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Washington DC",
                  "number": "+1 301 715 8592",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 305 224 1968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 309 205 3325",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Chicago",
                  "number": "+1 312 626 6799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "New York",
                  "number": "+1 646 876 9923",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 719 359 4580",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 253 205 0468",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Tacoma",
                  "number": "+1 253 215 8782",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Houston",
                  "number": "+1 346 248 7799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 360 209 5623",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 386 347 5053",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 408 638 0968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 507 473 4847",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 564 217 2000",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 669 444 9171",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 669 900 6833",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 689 278 1000",
                  "type": "toll",
                  "country": "US"
                }
              ]
            },
            "extra": {
              "intl_numbers_url": "https://us06web.zoom.us/u/kvmz0QapZ"
            },
            "password": "962661"
          },
          "join_url": "https://us06web.zoom.us/j/81924646573?pwd=RySEfTv2iF746yq1v4GJUyDn6l2pek.1",
          "status": "pushed",
          "type": "zoom"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Free Health Talk",
        "start_time": "2025-01-07T18:00:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-02T23:54:15.624308Z",
        "uri": "https://api.calendly.com/scheduled_events/ec3c6f85-bbfe-4199-968c-7e3e1baff5ab"
      },
      {
        "calendar_event": {
          "external_id": "gk0lru9ee4o2qn1mh5vhc7dbp4",
          "kind": "google"
        },
        "created_at": "2025-01-06T12:54:42.527958Z",
        "end_time": "2025-01-14T18:15:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-01-14T18:15:00.000000Z",
            "buffered_start_time": "2025-01-14T18:00:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/50350778-3550-43e3-9932-885c32f6d41b",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "data": {
            "id": 84660280545,
            "settings": {
              "global_dial_in_numbers": [
                {
                  "country_name": "US",
                  "city": "Chicago",
                  "number": "+1 312 626 6799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "New York",
                  "number": "+1 646 876 9923",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 646 931 3860",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Washington DC",
                  "number": "+1 301 715 8592",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 305 224 1968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 309 205 3325",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 360 209 5623",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 386 347 5053",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 408 638 0968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 507 473 4847",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 564 217 2000",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 669 444 9171",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 669 900 6833",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 689 278 1000",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 719 359 4580",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 253 205 0468",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Tacoma",
                  "number": "+1 253 215 8782",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Houston",
                  "number": "+1 346 248 7799",
                  "type": "toll",
                  "country": "US"
                }
              ]
            },
            "extra": {
              "intl_numbers_url": "https://us06web.zoom.us/u/kb0QXJ12AE"
            },
            "password": "181560"
          },
          "join_url": "https://us06web.zoom.us/j/84660280545?pwd=rbXQ8YRLTu6siiqLGUx9BX2fYtb4to.1",
          "status": "pushed",
          "type": "zoom"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Free Health Talk",
        "start_time": "2025-01-14T18:00:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-06T12:54:44.765202Z",
        "uri": "https://api.calendly.com/scheduled_events/2c7920d4-cd5f-4653-a64e-4858e048950d"
      },
      {
        "calendar_event": {
          "external_id": "tm160q4isclhkqsdt81o0o29oo",
          "kind": "google"
        },
        "created_at": "2025-01-06T18:28:16.208770Z",
        "end_time": "2025-01-14T19:00:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-01-14T19:00:00.000000Z",
            "buffered_start_time": "2025-01-14T18:45:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/50350778-3550-43e3-9932-885c32f6d41b",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "data": {
            "id": 88035501673,
            "settings": {
              "global_dial_in_numbers": [
                {
                  "country_name": "US",
                  "number": "+1 646 931 3860",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Washington DC",
                  "number": "+1 301 715 8592",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 305 224 1968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 309 205 3325",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Chicago",
                  "number": "+1 312 626 6799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "New York",
                  "number": "+1 646 876 9923",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 719 359 4580",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 253 205 0468",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Tacoma",
                  "number": "+1 253 215 8782",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Houston",
                  "number": "+1 346 248 7799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 360 209 5623",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 386 347 5053",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 408 638 0968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 507 473 4847",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 564 217 2000",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 669 444 9171",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 669 900 6833",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 689 278 1000",
                  "type": "toll",
                  "country": "US"
                }
              ]
            },
            "extra": {
              "intl_numbers_url": "https://us06web.zoom.us/u/kbL9pclQO1"
            },
            "password": "291630"
          },
          "join_url": "https://us06web.zoom.us/j/88035501673?pwd=aX8ioc5AewDN3EGOVsFKJOpqQNCktP.1",
          "status": "pushed",
          "type": "zoom"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Free Health Talk",
        "start_time": "2025-01-14T18:45:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-06T18:28:19.756761Z",
        "uri": "https://api.calendly.com/scheduled_events/456a621d-3695-438a-8c0e-68c4ff599159"
      },
      {
        "calendar_event": {
          "external_id": "4g7losp289ii14u2h4vgl36abs",
          "kind": "google"
        },
        "created_at": "2025-01-07T20:32:35.175419Z",
        "end_time": "2025-03-25T17:00:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-03-25T17:00:00.000000Z",
            "buffered_start_time": "2025-03-25T16:00:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/8016dfc7-2996-42ad-982b-46fed26c88b8",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "additional_info": "",
          "location": "Grotehondstraat 32 - 2018 Antwerpen",
          "type": "physical"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Intake In Person ",
        "start_time": "2025-03-25T16:00:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-07T20:32:37.561368Z",
        "uri": "https://api.calendly.com/scheduled_events/90f30d84-7a10-4ee0-b6cb-a3af596b80e3"
      },
      {
        "calendar_event": {
          "external_id": "h130nbk8puc5iiqqem5p20tvqk",
          "kind": "google"
        },
        "created_at": "2025-01-07T20:50:14.479095Z",
        "end_time": "2025-01-14T18:45:00.000000Z",
        "event_guests": [],
        "event_memberships": [
          {
            "buffered_end_time": "2025-01-14T18:45:00.000000Z",
            "buffered_start_time": "2025-01-14T18:30:00.000000Z",
            "user": "https://api.calendly.com/users/b75250d5-32da-42c6-a54c-376aa2afba4a",
            "user_email": "marleen@repeathealth.com",
            "user_name": "Marleen Verschroeven"
          }
        ],
        "event_type": "https://api.calendly.com/event_types/50350778-3550-43e3-9932-885c32f6d41b",
        "invitees_counter": {
          "active": 1,
          "limit": 1,
          "total": 1
        },
        "location": {
          "data": {
            "id": 89555732798,
            "settings": {
              "global_dial_in_numbers": [
                {
                  "country_name": "US",
                  "city": "New York",
                  "number": "+1 646 876 9923",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 646 931 3860",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Washington DC",
                  "number": "+1 301 715 8592",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 305 224 1968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 309 205 3325",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Chicago",
                  "number": "+1 312 626 6799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 689 278 1000",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 719 359 4580",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 253 205 0468",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Tacoma",
                  "number": "+1 253 215 8782",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "Houston",
                  "number": "+1 346 248 7799",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 360 209 5623",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 386 347 5053",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 408 638 0968",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 507 473 4847",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 564 217 2000",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "number": "+1 669 444 9171",
                  "type": "toll",
                  "country": "US"
                },
                {
                  "country_name": "US",
                  "city": "San Jose",
                  "number": "+1 669 900 6833",
                  "type": "toll",
                  "country": "US"
                }
              ]
            },
            "extra": {
              "intl_numbers_url": "https://us06web.zoom.us/u/keFyPAJBo0"
            },
            "password": "568313"
          },
          "join_url": "https://us06web.zoom.us/j/89555732798?pwd=HQVyKtkKobBH06XF54ABvpAd69mAmg.1",
          "status": "pushed",
          "type": "zoom"
        },
        "meeting_notes_html": null,
        "meeting_notes_plain": null,
        "name": "Free Health Talk",
        "start_time": "2025-01-14T18:30:00.000000Z",
        "status": "active",
        "updated_at": "2025-01-07T20:50:18.184766Z",
        "uri": "https://api.calendly.com/scheduled_events/2c8c70eb-d3f0-4182-a3a3-ae11508c4f06"
      }
    ],
    "pagination": {
      "count": 8,
      "next_page": null,
      "next_page_token": null,
      "previous_page": null,
      "previous_page_token": null
    }
  }

  export const filterEventsByAdminsEmail = (collection, email) =>{
    return collection.filter(event => 
      event.creators.some(membership => membership.user_email === email)
    );
  }

  export const filterEventsByUserEmail = (collection, email) => {
    return collection.filter(event => 
      event.invitees.some(invitee => invitee.email === email)
    );
  }

  export const filterAdminsAgenda = (admins,array) => {
    return admins.filter(admin => 
      array.some(slug => slug === admin?.calendly_info?.slug)
    );
  }

    export const eventUserColor = {
      "Free Health Talk": "bg-cyan-900",
      "EFT Intake Online": "bg-green-700",
      "EFT Intake In Person": "bg-teal-700",
      "Intake Online": "bg-blue-700",
      "Intake In Person": "bg-amber-800",
      "Follow-Up In Person" : "bg-amber-900",
      "Follow-Up Online" : "bg-blue-900",
      "EFT Follow-Up In Person" : "bg-teal-900",
      "EFT Follow-Up Online" : "bg-green-900",
    };


    export function phoneNumber(phoneNumber) {
      // Check if the first character is '0' and remove it
      if (phoneNumber.startsWith("0")) {
        const numberphone = phoneNumber.substring(1);
        return `32${numberphone}`; // or phoneNumber.slice(1)
      }
      return `32${phoneNumber}`; // Return as-is if no leading '0'
    }
  