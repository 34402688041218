import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import React, { useEffect, useLayoutEffect, useState } from "react";

import moment from "moment";
import { getUserdetails } from "../../Localstorage/localstorage";
import RP_Avatar from "../RP_Avatar";
import { classNames } from "./calenderUtilties";
import { InlineWidget } from "react-calendly";
import { phoneNumber } from "../../function";


function getColor(type) {
    let result = "";
    // Add 45 minutes
    if (type == "Follow up - 45min") {
        result = "green";
    } else {
        result = "green";
    }

    return result;
}

const eventUserMapping = {
    "repeat-marleen": [
        "free-health-talk",
        "intake-online",
        "intake-in-person",
        "follow-up-in-person",
        "follow-up-in-person",
    ],
    "repeat-natasja": [
        "eft-intake-online",
        "eft-intake-in-person",
        "intake-online",
        "intake-in-person",
        "follow-up-in-person",
        "follow-up-online",
        "eft-follow-up-in-person",
        "eft-follow-up-online",
    ],
    "repeat-cara": [
        "intake-online",
        "intake-in-person",
        "follow-up-in-person",
        "follow-up-online",
    ],
    "repeat-lindsey": [
        "intake-online",
        "intake-in-person",
        "follow-up-in-person",
        "follow-up-online",
    ],
};

export default function CrudEventAdminCalendly({
    openModel = false,
    setOpenModal,
    admin,
}) {
    const userdetails = getUserdetails();
    const [selectEvent, setSelectEvent] = useState(null)

    const optionsObject = {
        "free-health-talk": "Free Health Talk",
        "eft-intake-online": "EFT intake online",
        "eft-intake-in-person": "EFT intake in person",
        "intake-online": "Intake online",
        "intake-in-person": "Intake in person",
        "eft-follow-up-in-person": "EFT Follow-Up In Person",
        "eft-follow-up-online": "EFT Follow-Up Online",
        "follow-up-in-person": "Follow-Up In Person",
        "follow-up-online": "Follow-Up Online",
    };


    const [availableEvents, setAvailableEvents] = useState(
        []
    );

    useLayoutEffect(() => {
        if (admin) {
            setAvailableEvents(eventUserMapping[admin?.calendly_info?.slug]);
            setSelectEvent(eventUserMapping[admin?.calendly_info?.slug][0]);
        }


    }, [admin])


    const calendlyUrl = `https://calendly.com/${admin?.calendly_info?.slug}/${selectEvent}?primary_color=306535&hide_gdpr_banner=1`;

    return (
        <Dialog open={openModel} onClose={setOpenModal} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500 bg-opacity-75 items-center transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />
            <div className="fixed inset-0 z-10 w-full overflow-y-auto">
                <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative w-3/4 dark:bg-gray-900 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="">
                            <div className="flex border border-gray-200 p-4 rounded justify-center">

                                {/* User Dropdown */}

                                    {availableEvents?.length > 0 && availableEvents?.map((event, index) => (
                                        <div
                                            onClick={() => setSelectEvent(event)}
                                            key={index}
                                            className={classNames("cursor-pointer mx-2 gap-4 flex p-2 items-center rounded-lg",
                                                event == selectEvent ? " shadow bg-green-900 " : "border border-gray-100")}
                                        >
                                            <div>
                                                <p className={classNames("dark:text-white text-gray-900 text-sm",
                                                    event == selectEvent ? " text-white " : "text-green-900"
                                                )}>{optionsObject[event]}</p>
                                            </div>
                                        </div>
                                    ))}
                            </div>

                            {/* Calendly Embed */}
                            <div className="flex-1">
                            <InlineWidget
                                url={calendlyUrl}
                                prefill={{
                                    email: userdetails?.email,
                                    firstName: userdetails?.first_name,
                                    lastName: userdetails?.last_name,
                                    name: userdetails?.full_name,
                                    smsReminderNumber: "32471634353",
                                    guests: [],
                                    customAnswers: {
                                        a1: userdetails?.phone_number
                                            ? phoneNumber(userdetails?.phone_number)
                                            : "",
                                    },
                                    date: new Date(Date.now() + 86400000),
                                }}
                                styles={{ height: "70vh", width: "100%", marginTop: 0 }}
                            />
                            </div>

                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    );
}

