import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ArchiveBoxIcon, ArrowUturnLeftIcon, ChevronDownIcon, ChevronUpIcon, FolderArrowDownIcon, PaperAirplaneIcon, PencilIcon, PlusIcon, UserPlusIcon } from '@heroicons/react/20/solid'
import React from 'react'

const TopSectionMail = ({setOpenModal,mailMessage}) => {

  return (
    <div className="flex-shrink-0 border-b border-gray-200 bg-white dark:bg-gray-700 dark:border-gray-500">
    {/* Toolbar*/}
    <div className="flex h-16 flex-col justify-center">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between py-3">
          {/* Left buttons */}
          <div>
            <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
              <span className="inline-flex sm:shadow-sm">
                { mailMessage && <button
                  type="button"
                  onClick={() => setOpenModal('update')}
                  className="relative inline-flex dark:bg-gray-600 dark:text-white items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
                >
                  <ArrowUturnLeftIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-gray-400"
                  />
                  Beantwoorden
                </button>}
                <button
                  type="button"
                  onClick={() => setOpenModal('insert')}
                  className="relative -ml-px dark:bg-gray-600 dark:text-white hidden items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                >
                  <PlusIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-gray-400"
                  />
                  Nieuw
                </button> 
              </span>

              {/* <span className="hidden space-x-3 lg:flex">
                <button
                  type="button"
                  className="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                >
                  <ArchiveBoxIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-gray-400"
                  />
                  Archive
                </button>
                <button
                  type="button"
                  className="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                >
                  <FolderArrowDownIcon
                    aria-hidden="true"
                    className="-ml-0.5 h-5 w-5 text-gray-400"
                  />
                  Move
                </button>
              </span> */}

              <Menu
                as="div"
                className="relative -ml-px block sm:shadow-sm lg:hidden"
              >
                <div>
                  <MenuButton className="relative inline-flex  items-center gap-x-1.5 rounded-r-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 sm:rounded-md sm:px-3">
                    <span className="sr-only sm:hidden">More</span>
                    <span className="hidden sm:inline">More</span>
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400 sm:-mr-1"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute  right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem>
                      <a
                        href="#"
                        className="dark:bg-gray-600 dark:text-white block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 sm:hidden"
                      >
                        Note
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 sm:hidden"
                      >
                        Assign
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      >
                        Archive
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      >
                        Move
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
            </div>
          </div>

          {/* Right buttons */}
          <nav aria-label="Pagination">
            <span className="isolate inline-flex rounded-md shadow-sm">
              <a
                href="#"
                className="relative dark:bg-gray-600 dark:text-white inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
              >
                <span className="sr-only">Next</span>
                <ChevronUpIcon
                  aria-hidden="true"
                  className="h-5 w-5"
                />
              </a>
              <a
                href="#"
                className="relative -ml-px inline-flex dark:bg-gray-600 dark:text-white items-center rounded-r-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
              >
                <span className="sr-only">Previous</span>
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5"
                />
              </a>
            </span>
          </nav>
        </div>
      </div>
    </div>
    {/* Message header */}
  </div>
  )
}

export default TopSectionMail
