import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";

function phoneNumber(phoneNumber) {
  // Check if the first character is '0' and remove it
  if (phoneNumber.startsWith("0") || phoneNumber.startsWith("0") ) {
    const numberphone = phoneNumber.substring(1);
    return `32${numberphone}`; // or phoneNumber.slice(1)
  }
  return `32${phoneNumber}`; // Return as-is if no leading '0'
}

const CrudEventCalendlyEdit = ({
  openModel = false,
  setOpenModel,
  user,
  link,
}) => {

   /* useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => console.log(e.data.payload, e, e.data),
    onPageHeightResize: (e) => console.log(e.data.payload.height),
  });*/
  return (
    <Dialog open={openModel} onClose={setOpenModel} className="relative z-10 w-full">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed flex bg-gray-900 bg-opacity-75 justify-center inset-0 z-10 overflow-y-auto">
          <DialogPanel
            transition
            className="relative flex-1 transform overflow-hidden rounded-lg text-left transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="relative">
            <XMarkIcon onClick={() => setOpenModel(false)} className="absolute h-20 w-20 right-2 top-4 text-white " />
            <InlineWidget
              url={link}
              prefill={{
                email: user?.email,
                firstName: user?.first_name,
                lastName: user?.last_name,
                name: user?.full_name,
                smsReminderNumber: "32471634353",
                guests: [],
                customAnswers: {
                  a1: user?.phone_number ? phoneNumber(user?.phone_number) : "",
                },
                date: new Date(Date.now() + 86400000),
              }}
              styles={{ height: "700px", width: "100%", marginTop: 0 }}
            />
            </div>
          </DialogPanel>
        </div>
    </Dialog>
  );
};

export default CrudEventCalendlyEdit;
