export const radiobutton1 = [
    {id : 0 , title : 'Nooit/ zelden'},
    {id : 1 , title : 'Soms,niet belastend '},
    {id : 2 , title : 'Regelmatig, belastend '},
    {id : 3 , title : 'Bijna altijd, zeer belastend'},
 ];

export const KR_hoofd = (id = 0, defaultValue = 0) =>  ({
        klachtr_id:id, 
        vol_geh_og_voch:{ title: '1. Vol gevoel in hoofd, voor- en bijholten Opgezwollen ogen', value: null, type:0},
        niesaanv: { title: '2. Niesaanvallen', value: null, type:0 },
        opgez_ogen: { title: '3. Opgezwollen ogen', value: null, type:0 },
        wallen_ogen: { title: '4. Wallen onder de ogen', value: null, type:0 },
        droge_ogen: { title: '5. Droge ogen', value: null, type:0 },
        dicht_ogen: { title: '6. Dichtgeplakte ogen - vuil in de ooghoeken', value: null, type:0 },
        staar: { title: '7. Staar - glaucoom - vlekken voor de ogen', value: null, type:0 },
        brand_ogen: { title: '8. Branderige ogen', value: null, type:0 },
        tran_jeuk_ogen: { title: '9. Tranende, jeukende ogen', value: null, type:0 },
        verstop_neus: { title: '10. Verstopte neus', value: null, type:0 },
        bloedneus: { title: '11. Regelmatig een bloedneus', value: null, type:0 },
        oorpijn: { title: '12. Oorpijn - oorinfecties', value: null, type:0 },
        hoofd_ontw: { title: '13. Hoofdpijn bij het ontwaken (vermindert in de loop van de dag)', value: null, type:0 },
        hoofd_stress: { title: '14. Hoofdpijn na stress', value: null, type:0 },
        hoofd_midd: { title: '15. Hoofdpijn ’s middags', value: null, type:0 },
        hoofd_b_ogen: { title: '16. Hoofdpijn boven de ogen - tussen de wenkbrauwen - bij de slapen', value: null, type:0 },
        migraine: { title: '17. Migraine', value: null, type:0 },
        droog_haar: { title: '18. Dor en droog haar', value: null, type:0 },
        vet_haar: { title: '19. Vet haar', value: null, type:0 },
        neus_opstaan: { title: '20. ’s Morgens bij het opstaan volle neus', value: null, type:0 },
        water_neus_ogen: { title: '21. Waterige neus en/of ogen', value: null, type:0 },
        hooikoorts: { title: '22. Hooikoorts', value: null, type:0 },
        kringen_ogen: { title: '23. Donkere kringen onder de ogen', value: null, type:0 },
        dik_ogen: { title: '24. Dikke oogleden', value: null, type:0 },
        oogontst: { title: '25. Oogontsteking', value: null, type:0 },
        geel_ogwit: { title: '26. Geel oogwit - blauw oogwit - rood oogwit (adertjes)', value: null, type:0 },
        troeb_zicht: { title: '27. Troebel zicht - nachtblindheid', value: null, type:0 },
        jeuk_neus: { title: '28. Jeuk aan de neus', value: null, type:0 },
        droge_neus: { title: '29. Droge neus', value: null, type:0 },
        jeuk_oren: { title: '30. Jeukende oren - looporen', value: null, type:0 },
        oorsuiz: { title: '31. Oorsuizingen (hoge piep - lage piep)', value: null, type:0 },
        hoofd_geh: { title: '32. Hoofdpijn: bonzend/stekend/zwaar/vol/kloppend/stuwend/aangesnoerd gevoel', value: null, type:0 },
        geluiden: { title: '33. Geluiden in het hoofd', value: null, type:0 },
        hoofd_menst: { title: '34. Hoofdpijn voor/tijdens/na de menstruatie', value: null, type:0 },
        hoofd_boven: { title: '35. Hoofdpijn boven op het hoofd/zijkant van het hoofd/achterhoofd', value: null, type:0 },
        haaruitv: { title: '36. Haaruitval', value: null, type:0 },
        roos: { title: '37.Roos', value: null, type:0 },
        opm_klant: "",
        opm_admin: "",
    });

export const KR_spijsvertering = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    nare_smaak: { title: '1. Nare smaak in de mond: metaal - bitter - zuur - ander', value:null, type: 0 },
    s_adem: { title: '2. Slechte adem', value: null, type: 0 },
    verm_eetlust: { title: '3. Verminderde eetlust', value: null, type: 0 },
    besl_tong: { title: '4. Beslagen tong (kleur?)', value: null, type: 0 },
    gezw_tandvlees: { title: '5. Gezwollen tandvlees', value: null, type: 0 },
    amandelontst: { title: '6. Amandelontsteking/ vatbaarheid voor infecties', value: null, type: 0 },
    vastz_slijm: { title: '7. Vastzittend slijm - slijmproppen in de keel', value: null, type: 0 },
    gezw_lymf: { title: '8. Gezwollen lymfeklieren', value: null, type: 0 },
    krac_stem: { title: '9. Krachteloze stem - stemverlies - heesheid', value: null, type: 0 },
    boeren: { title: '10. Boeren, oprispingen', value: null, type: 0 },
    misselijkheid: { title: '11. Misselijkheid - braken', value: null, type: 0 },
    zuurbranden: { title: '12. Zuurbranden of geïrriteerde maag (verbetert na het eten)', value: null, type: 0 },
    wind_enum: { title: '13. Winderigheid en/of opgeblazen gevoel uren na de maaltijd', value: null, type: 0 },
    maagpijn: { title: '14. Maagpijn', value: null, type: 0 },
    onver_rest_ontl: { title: '15. Onverteerde resten in de ontlasting', value: null, type: 0 },
    brand_jeuk_anus: { title: '16. Brandende of jeukende anus', value: null, type: 0 },
    maagzweer: { title: '17. Maagzweer/ Darmzweer', value: null, type: 0 },
    droge_mond: { title: '18. Droge mond, dorstig', value: null, type: 0 },
    verlies_smaak: { title: '19. Verlies van smaak - verlies van reuk', value: null, type: 0 },
    gezw_tong: { title: '20. Gezwollen tong', value: null, type: 0 },
    brand_tong: { title: '21. Branderige tong', value: null, type: 0 },
    gezw_lippen: { title: '22. Gezwollen lippen', value: null, type: 0 },
    zweren_mond: { title: '23. Zweren in de mond (aften)', value: null, type: 0 },
    keelontst: { title: '24. Keelontsteking - angina', value: null, type: 0 },
    moeite_slikken: { title: '25. Moeite met slikken', value: null, type: 0 },
    kuchen: { title: '26. Kuchen - brok in de keel', value: null, type: 0 },
    opgebl_gev: { title: '27. Opgeblazen gevoel', value: null, type: 0 },
    galaanvallen: { title: '28. Galaanvallen/ galstenen', value: null, type: 0 },
    zwaar_gev: { title: '29. Zwaar, vol gevoel (steen) in de maag', value: null, type: 0 },
    pijnl_buik: { title: '30. Pijnlijke buik half uur/uur na de maaltijd (duurt 3 à 4 uur)', value: null, type: 0 },
    s_vertering: { title: '31. Slechte vertering van groenten en/of fruit', value: null, type: 0 },
    const_diarree: { title: '32. Constipatie/ diarree', value: null, type: 0 },
    reg_wat_ontl: { title: '33. Regelmatig waterige, dunne ontlasting', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",
});

export const KR_ontlasting_stoelgang = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    geur: { title: '1. Geur: zurig - rotte eieren - vettig', value: null, type: 0 },
    vorm: { title: '2. Vorm: waterdun - gistend - brijachtig - hard - keutels', value: null, type: 0 },
    vet_drijv_ontl: { title: '3. Vette, drijvende ontlasting', value: null, type: 0 },
    bloed_ontl: { title: '4. Bloed in de ontlasting', value: null, type: 0 },
    kleur: { title: '5. Kleur: zwart - okergeel - groen - lichtbruin - grijs', value: null, type: 0 },
    plak_ontl: { title: '6. Plakkerige ontlasting', value: null, type: 0 },
    vastz_slijm: { title: '7. Vastzittend slijm - slijmproppen in de keel', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_transpiratie = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    geur: { title: '1. Geur: zoet - zuur - zout - scherp - ammoniak', value: null, type: 0 },
    zweetaanvallen: { title: '2. Zweetaanvallen', value: null, type: 0 },
    hele_lichaam_zweetaanvallen: { title: '3. Zweetaanvallen over het hele lichaam', value: null, type: 0 },
    nachtelijke_zweetaanvallen: { title: '4. Zweetaanvallen ’s nachts', value: null, type: 0 },
    zweten_na_maaltijd: { title: '5. Zweten na de maaltijd', value: null, type: 0 },
    blozen: { title: '6. Blozen', value: null, type: 0 },
    overmatig_zweten: { title: '7. Overmatig zweten', value: null, type: 0 },
    zweten_op_hoofd_handen_voeten: { title: '8. Alleen aan hoofd, handen, voeten', value: null, type: 0 },
    sterke_zweetgeur: { title: '9. Sterke geur', value: null, type: 0 },
    overdag_veel_zweten: { title: '10. Veel zweten overdag', value: null, type: 0 },
    zweetvoeten_brandende_voeten: { title: '11. Zweetvoeten - brandende voeten', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",
});

export const KR_urine = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    geur: { title: '1. Geur: zoet - zuur - asperge', value: null, type: 0 },
    bloed_in_urine: { title: '2. Bloed in de urine', value: null, type: 0 },
    kleur: { title: '3. Kleur: groen - rood - geel - kleurloos', value: null, type: 0 },
    vlokken_in_urine: { title: '4. Vlokken in de urine', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",
});

export const KR_allergie_overgevoeligheden = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    insectenbeten: { title: '1. Gevoelig voor insectenbeten (muggen)', value: null, type: 0 },
    geluid_overgevoeligheid: { title: '2. Overgevoelig voor geluid', value: null, type: 0 },
    parfum: { title: '3. Gevoelig voor parfum', value: null, type: 0 },
    zonneallergie: { title: '4. Zonneallergie', value: null, type: 0 },
    additieven: { title: '5. Gevoelig voor additieven (E-nummers)', value: null, type: 0 },
    nikkel_metaal: { title: '6. Gevoelig voor nikkel - metaal', value: null, type: 0 },
    medicijnen_allergie: { title: '7. Allergisch voor medicijnen (welke?)', value:"", type: 'string' },
    hyperactiviteit: { title: '8. Hyperactiviteit, onrustigheid', value: null, type: 0 },
    licht_overgevoeligheid: { title: '9. Overgevoelig voor licht', value: null, type: 0 },
    chemische_stoffen: { title: '10. Gevoelig voor chemische stoffen', value: null, type: 0 },
    formaldehyde: { title: '11. Gevoelig voor formaldehyde', value: null, type: 0 },
    galbulten: { title: '12. Galbulten', value: null, type: 0 },
    chinese_maaltijden: { title: '13. Gevoelig voor Chinese maaltijden/ mononatrium-glutamaat/ smaakversterkers', value: null, type: 0 },
    voeding_allergie: { title: '14. Allergisch voor bepaalde voeding (welke?)', value:"", type: 'string' },
    vaccinatie_reacties: { title: '15. Heftige reacties op vaccinaties (welke?)', value:"", type: 'string' },
    opm_klant: "",
    opm_admin: "",

});

export const KR_huid = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    acne: { title: '1. Acne', value: null, type: 0 },
    droge_huid: { title: '2. Droge huid - schilferige huid', value: null, type: 0 },
    slechte_wondgenezing: { title: '3. Slechte wondgenezing', value: null, type: 0 },
    naaldenprikgevoel: { title: '4. Naaldenprikgevoel - gevoelloosheid van de huid', value: null, type: 0 },
    blauwe_plekken: { title: '5. Snel blauwe plekken', value: null, type: 0 },
    huidstriemen: { title: '6. Huidstriemen', value: null, type: 0 },
    vette_huid: { title: '7. Vette huid', value: null, type: 0 },
    jeuk: { title: '8. Jeuk', value: null, type: 0 },
    eczeem: { title: '9. Eczeem (nat/ droog/ vurig)', value: null, type: 0 },
    huiduitslag: { title: '10. Huiduitslag', value: null, type: 0 },
    bruine_verkleuring: { title: '11. Bruine vlekken of verkleuringen', value: null, type: 0 },
    cellulitis: { title: '12. Cellulitis', value: null, type: 0 },
    opm_klant: "",
    opm_admin: ""
});

export const KR_nagels = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    witte_vlekjes: { title: '1. Witte vlekjes in de nagels', value: null, type: 0 },
    scheurende_nagels: { title: '2. Snel scheurende nagels', value: null, type: 0 },
    nagelriem_ontstekingen: { title: '3. Nagelriem-ontstekingen', value: null, type: 0 },
    broze_nagels: { title: '4. Broze, breekbare nagels', value: null, type: 0 },
    lepelvormige_nagels: { title: '5. Lepelvormige nagels', value: null, type: 0 },
    nagel_ribbels: { title: '6. Ribbels in de nagels', value: null, type: 0 },
    kalknagel: { title: '7. Kalknagel(s)', value: null, type: 0 },
    dunne_nagels: { title: '8. Dunne nagels (continue - periodiek)', value: null, type: 0 },
    nagel_verkleuring: { title: '9. Verkleuringen', value: null, type: 0 },
    opm_klant: "",
    opm_admin: ""
});

export const KR_gebit = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    slecht_gebit: { title: '1. Slecht gebit', value: null, type: 0 },
    kunstgebit: { title: '2. Kunstgebit', value: null, type: 0 },
    kronen_bruggen: { title: '3. Kronen - bruggen', value: null, type: 0 },
    bleekbehandelingen: { title: '4. Bleekbehandelingen in het verleden', value: null, type: 0 },
    kaakoperatie: { title: '5. Kaakoperatie', value: null, type: 0 },
    vullingen_amalgaam: { title: '6. Vullingen (aantal?) - Amalgaam?', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",
});

export const KR_hormonen = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    zin_in_seks: { title: '1. Toegenomen zin in seks', value: null, type: 0 },
    abnormale_dorst: { title: '2. Abnormale dorst', value: null, type: 0 },
    veel_suiker: { title: '3. Veel suiker kunnen eten zonder bijverschijnselen', value: null, type: 0 },
    warmte_intolerantie: { title: '4. Warmte niet kunnen verdragen', value: null, type: 0 },
    verminderd_initiatief: { title: '5. Verminderd initiatief/ mentale traagheid', value: null, type: 0 },
    gevoelig_voor_kou: { title: '6. Gevoelig voor kou/ slechte bloedcirculatie', value: null, type: 0 },
    flushes: { title: '7. Flushes (rode, jeukerige, geprikkelde huid)', value: null, type: 0 },
    mannelijk_voorkomen: { title: '8. Mannelijk voorkomen (vrouw)', value: null, type: 0 },
    middags_geeuwen: { title: '9. ’s Middags geeuwen', value: null, type: 0 },
    langzaam_opstarten: { title: '10. Langzaam op gang komen ’s ochtends', value: null, type: 0 },
    afgenomen_zin_in_seks: { title: '11. Afgenomen zin in seks', value: null, type: 0 },
    gewichtstoename_heupen_taille: { title: '12. Gewichtstoename rond de heupen - taille', value: null, type: 0 },
    moeilijk_aankomen: { title: '13. Moeilijk aankomen', value: null, type: 0 },
    moeilijk_afvallen: { title: '14. Moeilijk afvallen', value: null, type: 0 },
    snel_moe: { title: '15. Snel moe/ Slaperig overdag', value: null, type: 0 },
    opvliegers: { title: '16. Opvliegers', value: null, type: 0 },
    haargroei: { title: '17. Haargroei in het gezicht of op het lichaam (vrouw)', value: null, type: 0 },
    chronisch_moe: { title: '18. Chronisch moe, suf', value: null, type: 0 },
    zwakte_na_verkoudheid: { title: '19. Zwakte na verkoudheid, langzaam herstel', value: null, type: 0 },
    slechte_temperatuurregulatie: { title: '20. Slechte regulatie van de lichaamstemperatuur', value: null, type: 0 },
    opm_klant: "",
    opm_admin: ""
});

export const KR_slaappatroon = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    slapeloosheid: { title: '1. Slapeloosheid', value: null, type: 0 },
    slecht_inslapen: { title: '2. Slecht inslapen, lang wakker liggen', value: null, type: 0 },
    vroeg_wakker_worden: { title: '3. ’s Morgens vroeg wakker worden', value: null, type: 0 },
    vast_wakker_worden: { title: '4. Wakker worden op vaste tijdstippen (hoelaat?)', value: null, type: 0 },
    goed_inslapen_maar_wakker: { title: '5. Goed inslapen, maar na enkele uren wakker worden en vervolgens niet goed meer kunnen inslapen', value: null, type: 0 },
    moe_wakker_worden: { title: '6. ’s Morgens moe/uitgeput wakker worden', value: null, type: 0 },
    opm_klant: "",
    opm_admin: ""
});

export const KR_psychisch = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    verveeld_ontevreden: { title: '1. Verveeld/ontevreden/gefrustreerd', value: null, type: 0 },
    depressief_somber: { title: '2. Zwaarmoedig/depressief/somber', value: null, type: 0 },
    angsten_paniekaanvallen: { title: '3. Fobieën/angsten/paniekaanvallen', value: null, type: 0 },
    snel_verward_nerveus: { title: '4. Snel in verwarring/onzeker/nerveus', value: null, type: 0 },
    coördinatie_stoornissen: { title: '5. Coördinatie-stoornissen', value: null, type: 0 },
    stemmingswisselingen: { title: '6. Wisselende stemmingen', value: null, type: 0 },
    verminderd_initiatief: { title: '7. Verminderd initiatief', value: null, type: 0 },
    malende_negatieve_gedachten: { title: '8. Malende gedachten/negatieve gedachten', value: null, type: 0 },
    nachtmerries: { title: '9. Nachtmerries', value: null, type: 0 },
    burn_out_overspannen: { title: '10. Burn-out/overspannenheid', value: null, type: 0 },
    niet_onder_druk_werken: { title: '11. Niet onder druk kunnen werken', value: null, type: 0 },
    emotioneel_zorgelijk: { title: '12. Bedroefd/emotioneel/zorgelijk', value: null, type: 0 },
    onduidelijk_praten: { title: '13. Onduidelijk praten/woorden omdraaien', value: null, type: 0 },
    prikkelbaar_opvliegend: { title: '14. Prikkelbaarheid/opvliegend/snel boos', value: null, type: 0 },
    leermoeilijkheden: { title: '15. Leermoeilijkheden', value: null, type: 0 },
    labiel: { title: '16. Labiel', value: null, type: 0 },
    slecht_geheugen: { title: '17. Slecht geheugen (korte termijn - lange termijn)', value: null, type: 0 },
    dwanghandelingen: { title: '18. Dwanghandelingen', value: null, type: 0 },
    dromen_niet_herinneren: { title: '19. Dromen niet kunnen herinneren', value: null, type: 0 },
    concentratieproblemen: { title: '20. Concentratieproblemen', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_longen = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    zware_onreg_ademhaling: { title: '1. Zware en/of onregelmatige ademhaling', value: null, type: 0 },
    kortademigheid: { title: '2. Kortademigheid', value: null, type: 0 },
    kortademigheid_bij_inspanning: { title: '3. Kortademigheid tijdens/na inspanningen', value: null, type: 0 },
    benauwd_gevoel: { title: '4. Benauwd gevoel', value: null, type: 0 },
    neiging_flauwvallen: { title: '5. Neiging tot flauwvallen', value: null, type: 0 },
    astma: { title: '6. Astma', value: null, type: 0 },
    onwel_op_hoogten: { title: '7. Onwel worden op grote hoogten', value: null, type: 0 },
    kortademigheid_bij_temp: { title: '8. Kortademigheid bij warmte/koude', value: null, type: 0 },
    vaak_zuchten_geeuwen: { title: '9. Vaak zuchten/geeuwen', value: null, type: 0 },
    hyperventilatie: { title: '10. Hyperventilatie', value: null, type: 0 },
    bronchitis_emfyseem: { title: '11. Regelmatig bronchitis/emfyseem', value: null, type: 0 },
    piepende_ademhaling: { title: '12. Piepende ademhaling', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_cardiovasculair = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    gezwollen_enkels: { title: "1. Gezwollen enkels ('s nachts erger)", value: null, type: 0 },
    onreg_hartslag: { title: '2. Onregelmatige hartslag', value: null, type: 0 },
    hartruis: { title: '3. Hartruis', value: null, type: 0 },
    bonkend_hart_bij_liggen: { title: '4. Last van bonkend hart bij liggen', value: null, type: 0 },
    spataders: { title: '5. Spataders', value: null, type: 0 },
    hoge_bloeddruk: { title: '6. Hoge bloeddruk', value: null, type: 0 },
    rusteloze_benen: { title: '7. Rusteloze benen', value: null, type: 0 },
    koude_handen_voeten: { title: '8. Koude handen en voeten', value: null, type: 0 },
    bloedarmoede_bleekheid: { title: '9. Bloedarmoede/bleekheid', value: null, type: 0 },
    pijn_borst_uitstralend_naar_linkerarm: { title: '10. Pijn in de borst/uitstralend naar linkerarm (erger bij inspanning)', value: null, type: 0 },
    hartkloppingen: { title: '11. Hartkloppingen', value: null, type: 0 },
    snelle_hartslag_na_maaltijd: { title: '12. Snelle hartslag na de maaltijd/bonkend hart na rust', value: null, type: 0 },
    snelle_hartslag_in_rust: { title: '13. Snelle hartslag in rust', value: null, type: 0 },
    aambeien: { title: '14. Aambeien', value: null, type: 0 },
    lage_bloeddruk: { title: '15. Lage bloeddruk', value: null, type: 0 },
    pijn_in_kuiten: { title: '16. Pijn in de kuiten', value: null, type: 0 },
    kloppende_vaten: { title: '17. Kloppende vaten/stuwingen', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_spieren_en_gewrichten = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    slappe_zwakke_spieren: { title: '1. Slappe, zwakke spieren', value: null, type: 0 },
    slechte_motoriek: { title: '2. Slechte motoriek, moeite met fijne bewegingen', value: null, type: 0 },
    krampen_spieren_benen_tenen: { title: '3. Krampen in spieren, benen, tenen tijdens rust/slaap', value: null, type: 0 },
    dikke_vingers_oedeem: { title: '4. Dikke vingers (oedeem)', value: null, type: 0 },
    zwaar_gevoel_armen_benen: { title: '5. Zwaar gevoel in armen en/of benen', value: null, type: 0 },
    pijnlijke_gewrichten: { title: '6. Pijnlijke gewrichten', value: null, type: 0 },
    zwak_bindweefsel: { title: '7. Zwak bindweefsel', value: null, type: 0 },
    spierpijn: { title: '8. Spierpijn', value: null, type: 0 },
    gewrichtsontstekingen: { title: '9. Gewrichtsontstekingen', value: null, type: 0 },
    gevoelloosheid_handen_voeten: { title: '10. Gevoelloosheid in handen en voeten', value: null, type: 0 },
    ochtendstijfheid: { title: '11. Ochtendstijfheid', value: null, type: 0 },
    krakende_gewrichten: { title: '12. Krakende gewrichten', value: null, type: 0 },
    gezwollen_gewrichten: { title: '13. Gezwollen gewrichten', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_pijn = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    scherp_stekend: { title: '1. Scherp/ stekend', value: null, type: 0 },
    verspringend: { title: '2. Verspringend', value: null, type: 0 },
    wervelkolom: { title: '3. Wervelkolom', value: null, type: 0 },
    pijn_schouderbladen_frozen_shoulder: { title: '4. Pijn tussen de schouderbladen/ frozen shoulder', value: null, type: 0 },
    zeurend: { title: '5. Zeurend', value: null, type: 0 },
    uitstralende: { title: '6. Uitstralende', value: null, type: 0 },
    nekklachten: { title: '7. Nekklachten', value: null, type: 0 },
    rugpijn_l_m_h: { title: '8. Rugpijn (laag - midden - hoog)', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_vrouw = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    menstruatie: { title: '1. Menstruatie: geen/ regelmatig/ onregelmatig', value: null, type: 0 },
    menstruatie_txt: { title: '', value: '', type: 1 },
    hevige_mens: { title: '2. Hevige menstruatie', value: null, type: 0 },
    eerste_mens_leeftijd: { title: '3. Eerste menstruatie op leeftijd van?', value: '', type: 1 },
    bloedstolsels: { title: '4. Helder bloed - donkere bloedstolsels', value: null, type: 0 },
    depressie_v_mens: { title: '5. Depressie voor de menstruatie', value: null, type: 0 },
    acne_v_mens: { title: '6. Acne die verergert tijdens de menstruatie', value: null, type: 0 },
    vag_infecties: { title: '7. Vaginale infecties/ jeuk', value: null, type: 0 },
    vaak_plassen: { title: '8. Vaak kleine hoeveelheden plassen', value: null, type: 0 },
    opvliegers_meno: { title: '9. Opvliegers tijdens de menopauze', value: null, type: 0 },
    zw_diabetes: { title: '10. Zwangerschaps-diabetes', value: null, type: 0 },
    ivf_behandelingen: { title: '11. IVF-behandelingen', value: null, type: 0 },
    ant_conceptie_klachten: { title: '12. Klachten van de anticonceptiepil/ spiraaltje', value: null, type: 0 },
    pijn_mens: { title: '13. Pijnlijke menstruatie', value: null, type: 0 },
    lichte_mens: { title: '14. Lichte menstruatie', value: null, type: 0 },
    extreme_bloedingen: { title: '15. Extreme bloedingen', value: null, type: 0 },
    pms: { title: '16. Premenstrueel syndroom', value: null, type: 0 },
    pijn_borsten_cysten: { title: '17. Pijnlijke gevoelige borsten/ cysten', value: null, type: 0 },
    vag_afscheiding: { title: '18. Vaginale afscheiding (doorzichtig - wit - geel - bruin)', value: null, type: 0 },
    baarm_eierstokken_verw: { title: '19. Verwijderde baarmoeder/ eierstokken', value: null, type: 0 },
    blaasontstekingen: { title: '20. Regelmatig blaasontstekingen', value: null, type: 0 },
    miskramen: { title: '21. Eén of meerdere miskramen gehad', value: null, type: 0 },
    moeite_zwanger_worden: { title: '22. Moeite om zwanger te worden', value: null, type: 0 },
    borstvergroting_verkl: { title: '23. Borstvergroting - borstverkleining', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const KR_man = (id = 0, defaultValue = 0) => ({
    klachtr_id:id,
    prostaatkl: { title: '1. Prostaatklachten', value: null, type: 0 },
    pijn_binnenkant_benen_hielen: { title: '2. Pijn aan de binnenkant van de benen of hielen', value: null, type: 0 },
    vroegt_zaadlozing: { title: '3. Vroegtijdige zaadlozing', value: null, type: 0 },
    vaak_plassen: { title: '4. Vaak kleine hoeveelheden plassen', value: null, type: 0 },
    erectieprob: { title: '5. Erectieproblemen', value: null, type: 0 },
    verminderde_vruchtb: { title: '6. Verminderde vruchtbaarheid', value: null, type: 0 },
    opm_klant: "",
    opm_admin: "",

});

export const Klachten = (id = 0, defaultValue = 0) => ({
hoofd :KR_hoofd(id)
,spijsvertering :KR_spijsvertering(id)
,ontlasting_stoelgang :KR_ontlasting_stoelgang(id)
,transpiratie :KR_transpiratie(id)
,urine :KR_urine(id)
,allergie_overgevoeligheden :KR_allergie_overgevoeligheden(id)
,huid :KR_huid(id)
,nagels :KR_nagels(id)
,gebit :KR_gebit(id)
,hormonen :KR_hormonen(id)
,slaappatroon :KR_slaappatroon(id)
,psychisch :KR_psychisch(id)
,longen :KR_longen(id)
,cardiovasculair :KR_cardiovasculair(id)
,spieren_en_gewrichten :KR_spieren_en_gewrichten(id)
,pijn :KR_pijn(id)
,vrouw :KR_vrouw(id)
,man :KR_man(id)
});



export const hoofd = [
'klachtr_id',
    'vol_geh_og_voch',
    'niesaanv',
    'opgez_ogen',
    'wallen_ogen',
    'droge_ogen',
    'dicht_ogen',
    'staar',
    'brand_ogen',
    'tran_jeuk_ogen',
    'verstop_neus',
    'bloedneus',
    'oorpijn',
    'hoofd_ontw',
    'hoofd_stress',
    'hoofd_midd',
    'hoofd_b_ogen',
    'migraine',
    'droog_haar',
    'vet_haar',
    'neus_opstaan',
    'water_neus_ogen',
    'hooikoorts',
    'kringen_ogen',
    'dik_ogen',
    'oogontst',
    'geel_ogwit',
    'troeb_zicht',
    'jeuk_neus',
    'droge_neus',
    'jeuk_oren',
    'oorsuiz',
    'hoofd_geh',
    'geluiden',
    'hoofd_menst',
    'hoofd_boven',
    'haaruitv',
    'roos',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const spijsvertering = [
'klachtr_id',
    'nare_smaak',
    's_adem',
    'verm_eetlust',
    'besl_tong',
    'gezw_tandvlees',
    'amandelontst',
    'vastz_slijm',
    'gezw_lymf',
    'krac_stem',
    'boeren',
    'misselijkheid',
    'zuurbranden',
    'wind_enum',
    'maagpijn',
    'onver_rest_ontl',
    'brand_jeuk_anus',
    'maagzweer',
    'droge_mond',
    'verlies_smaak',
    'gezw_tong',
    'brand_tong',
    'gezw_lippen',
    'zweren_mond',
    'keelontst',
    'moeite_slikken',
    'kuchen',
    'opgebl_gev',
    'galaanvallen',
    'zwaar_gev',
    'pijnl_buik',
    's_vertering',
    'const_diarree',
    'reg_wat_ontl',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const ontlastingStoelgang = [
'klachtr_id',
    'geur',
    'vorm',
    'vet_drijv_ontl',
    'bloed_ontl',
    'kleur',
    'plak_ontl',
    'vastz_slijm',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const transpiratie = [
'klachtr_id',
    'geur',
    'zweetaanvallen',
    'hele_lichaam_zweetaanvallen',
    'nachtelijke_zweetaanvallen',
    'zweten_na_maaltijd',
    'blozen',
    'overmatig_zweten',
    'zweten_op_hoofd_handen_voeten',
    'sterke_zweetgeur',
    'overdag_veel_zweten',
    'zweetvoeten_brandende_voeten',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const urine = [
'klachtr_id',
    'geur',
    'bloed_in_urine',
    'kleur',
    'vlokken_in_urine',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const allergieOvergevoeligheden = [
'klachtr_id',
    'insectenbeten',
    'geluid_overgevoeligheid',
    'parfum',
    'zonneallergie',
    'additieven',
    'nikkel_metaal',
    'medicijnen_allergie',
    'hyperactiviteit',
    'licht_overgevoeligheid',
    'chemische_stoffen',
    'formaldehyde',
    'galbulten',
    'chinese_maaltijden',
    'voeding_allergie',
    'vaccinatie_reacties',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const huid = [
'klachtr_id',
    'acne',
    'droge_huid',
    'slechte_wondgenezing',
    'naaldenprikgevoel',
    'blauwe_plekken',
    'huidstriemen',
    'vette_huid',
    'jeuk',
    'eczeem',
    'huiduitslag',
    'bruine_verkleuring',
    'cellulitis',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const nagels = [
'klachtr_id',
    'witte_vlekjes',
    'scheurende_nagels',
    'nagelriem_ontstekingen',
    'broze_nagels',
    'lepelvormige_nagels',
    'nagel_ribbels',
    'kalknagel',
    'dunne_nagels',
    'nagel_verkleuring',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const gebit = [
'klachtr_id',
    'slecht_gebit',
    'kunstgebit',
    'kronen_bruggen',
    'bleekbehandelingen',
    'kaakoperatie',
    'vullingen_amalgaam',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const hormonen = [
'klachtr_id',
    'zin_in_seks',
    'abnormale_dorst',
    'veel_suiker',
    'warmte_intolerantie',
    'verminderd_initiatief',
    'gevoelig_voor_kou',
    'flushes',
    'mannelijk_voorkomen',
    'middags_geeuwen',
    'langzaam_opstarten',
    'afgenomen_zin_in_seks',
    'gewichtstoename_heupen_taille',
    'moeilijk_aankomen',
    'moeilijk_afvallen',
    'snel_moe',
    'opvliegers',
    'haargroei',
    'chronisch_moe',
    'zwakte_na_verkoudheid',
    'slechte_temperatuurregulatie',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const slaappatroon = [
'klachtr_id',
    'slapeloosheid',
    'slecht_inslapen',
    'vroeg_wakker_worden',
    'vast_wakker_worden',
    'goed_inslapen_maar_wakker',
    'moe_wakker_worden',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const psychisch = [
'klachtr_id',
    'verveeld_ontevreden',
    'depressief_somber',
    'angsten_paniekaanvallen',
    'snel_verward_nerveus',
    'coördinatie_stoornissen',
    'stemmingswisselingen',
    'verminderd_initiatief',
    'malende_negatieve_gedachten',
    'nachtmerries',
    'burn_out_overspannen',
    'niet_onder_druk_werken',
    'emotioneel_zorgelijk',
    'onduidelijk_praten',
    'prikkelbaar_opvliegend',
    'leermoeilijkheden',
    'labiel',
    'slecht_geheugen',
    'dwanghandelingen',
    'dromen_niet_herinneren',
    'concentratieproblemen',
    'opm_klant',
'opm_admin',
    'admin_id'
];

export const longen = [
'klachtr_id',
    "zware_onreg_ademhaling",
    "kortademigheid",
    "kortademigheid_bij_inspanning",
    "benauwd_gevoel",
    "neiging_flauwvallen",
    "astma",
    "onwel_op_hoogten",
    "kortademigheid_bij_temp",
    "vaak_zuchten_geeuwen",
    "hyperventilatie",
    "bronchitis_emfyseem",
    "piepende_ademhaling",
    'opm_klant',
    "opm_admin",
    "admin_id"
];

export const cardiovasculair = [
'klachtr_id',
    "gezwollen_enkels",
    "onreg_hartslag",
    "hartruis",
    "bonkend_hart_bij_liggen",
    "spataders",
    "hoge_bloeddruk",
    "rusteloze_benen",
    "koude_handen_voeten",
    "bloedarmoede_bleekheid",
    "pijn_borst_uitstralend_naar_linkerarm",
    "hartkloppingen",
    "snelle_hartslag_na_maaltijd",
    "snelle_hartslag_in_rust",
    "aambeien",
    "lage_bloeddruk",
    "pijn_in_kuiten",
    "kloppende_vaten",
    'opm_klant',
    "opm_admin",
    "admin_id"
];

export const spierenengewrichten = [
'klachtr_id',
    "slappe_zwakke_spieren",
    "slechte_motoriek",
    "krampen_spieren_benen_tenen",
    "dikke_vingers_oedeem",
    "zwaar_gevoel_armen_benen",
    "pijnlijke_gewrichten",
    "zwak_bindweefsel",
    "spierpijn",
    "gewrichtsontstekingen",
    "gevoelloosheid_handen_voeten",
    "ochtendstijfheid",
    "krakende_gewrichten",
    "gezwollen_gewrichten",
    'opm_klant',
    "opm_admin",
    "admin_id"
];

export const pijn = [
'klachtr_id',
    "scherp_stekend",
    "verspringend",
    "wervelkolom",
    "pijn_schouderbladen_frozen_shoulder",
    "zeurend",
    "uitstralende",
    "nekklachten",
    "rugpijn_l_m_h",
    'opm_klant',
    "opm_admin",
    "admin_id"
];

export const vrouw = [
'klachtr_id',
    "menstruatie",
    "menstruatie_txt",
    "hevige_mens",
    "eerste_mens_leeftijd",
    "bloedstolsels",
    "depressie_v_mens",
    "acne_v_mens",
    "vag_infecties",
    "vaak_plassen",
    "opvliegers_meno",
    "zw_diabetes",
    "ivf_behandelingen",
    "ant_conceptie_klachten",
    "pijn_mens",
    "lichte_mens",
    "extreme_bloedingen",
    "pms",
    "pijn_borsten_cysten",
    "vag_afscheiding",
    "baarm_eierstokken_verw",
    "blaasontstekingen",
    "miskramen",
    "moeite_zwanger_worden",
    "borstvergroting_verkl",
    'opm_klant',
    "opm_admin",
    "admin_id"
];

export const man = [
'klachtr_id',
    "prostaatkl",
    "pijn_binnenkant_benen_hielen",
    "vroegt_zaadlozing",
    "vaak_plassen",
    "erectieprob",
    "verminderde_vruchtb",
    'opm_klant',
    "opm_admin",
    "admin_id"
];

export const benamingKlachten = [
    { id:0,key: "hoofd", link: "hoofd", title: "Hoofd" },
    { id:1,key: "spijsvertering", link: "spijsvertering", title: "Spijsvertering" },
    { id:2,key: "ontlasting_stoelgang",link: "ontlastingstoelgang",title: "Ontlasting stoelgang",},
    { id:3,key: "transpiratie", link: "transpiratie", title: "Transpiratie" },
    { id:4,key: "urine", link: "urine", title: "Urine" },
    { id:5,key: "allergie_overgevoeligheden",link: "allergieovergevoeligheden",title: "Allergie overgevoeligheden",},
    { id:6,key: "huid", link: "huid", title: "Huid" },
    { id:7,key: "nagels", link: "nagels", title: "Nagels" },
    { id:8,key: "gebit", link: "gebit", title: "Gebit" },
    { id:9,key: "hormonen", link: "hormonen", title: "Hormonen" },
    { id:10,key: "slaappatroon", link: "slaappatroon", title: "Slaappatroon" },
    { id:11,key: "psychisch", link: "psychisch", title: "Psychisch" },
    { id:12,key: "longen", link: "longen", title: "Longen" },
    { id:13,key: "cardiovasculair", link: "cardiovasculair", title: "Cardiovasculair" },
    {
      id:14,key: "spieren_en_gewrichten",
      link: "spierenengewrichten",
      title: "Spieren en gewrichten",
    },
    { id:15,key: "pijn", link: "pijn", title: "Pijn" },
    { id:16,key: "vrouw", link: "vrouw", title: "Vrouw" },
    { id:17,key: "man", link: "man", title: "Man" },
  ];

export const benamingKlachtenObject = {
    hoofdData :{ id:0, link: "hoofd", title: "Hoofd" },
    spijsverteringData :{ id:1, link: "spijsvertering", title: "Spijsvertering" },
    ontlastingstoelgangData :{ id:2,link: "ontlastingstoelgang",title: "Ontlasting stoelgang",},
    transpiratieData :{ id:3, link: "transpiratie", title: "Transpiratie" },
    urineData :{ id:4, link: "urine", title: "Urine" },
    allergie_overgevoelighedenData :{ id:5,link: "allergieovergevoeligheden",title: "Allergie overgevoeligheden",},
    huidData :{ id:6, link: "huid", title: "Huid" },
    nagelsData :{ id:7, link: "nagels", title: "Nagels" },
    gebitData :{ id:8, link: "gebit", title: "Gebit" },
    hormonenData :{ id:9, link: "hormonen", title: "Hormonen" },
    slaappatroonData :{ id:10, link: "slaappatroon", title: "Slaappatroon" },
    psychischData :{ id:11, link: "psychisch", title: "Psychisch" },
    longenData :{ id:12, link: "longen", title: "Longen" },
    cardiovasculairData :{ id:13, link: "cardiovasculair", title: "Cardiovasculair" },
    spieren_en_gewrichtenData :{ id:14,link: "spierenengewrichten",title: "Spieren en gewrichten",},
    pijnData :{ id:15, link: "pijn", title: "Pijn" },
    vrouwData :{ id:16, link: "vrouw", title: "Vrouw" },
    manData :{ id:17, link: "man", title: "Man" },
};

export const TI_hoofd = {
    vol_geh_og_voch: '1. Vol gevoel in hoofd, voor- en bijholten Opgezwollen ogen',
    niesaanv:  '2. Niesaanvallen', 
    opgez_ogen:  '3. Opgezwollen ogen', 
    wallen_ogen:  '4. Wallen onder de ogen', 
    droge_ogen:  '5. Droge ogen', 
    dicht_ogen:  '6. Dichtgeplakte ogen - vuil in de ooghoeken', 
    staar:  '7. Staar - glaucoom - vlekken voor de ogen', 
    brand_ogen:  '8. Branderige ogen', 
    tran_jeuk_ogen:  '9. Tranende, jeukende ogen', 
    verstop_neus:  '10. Verstopte neus', 
    bloedneus:  '11. Regelmatig een bloedneus', 
    oorpijn:  '12. Oorpijn - oorinfecties', 
    hoofd_ontw:  '13. Hoofdpijn bij het ontwaken (vermindert in de loop van de dag)', 
    hoofd_stress:  '14. Hoofdpijn na stress', 
    hoofd_midd:  '15. Hoofdpijn ’s middags', 
    hoofd_b_ogen:  '16. Hoofdpijn boven de ogen - tussen de wenkbrauwen - bij de slapen', 
    migraine:  '17. Migraine', 
    droog_haar:  '18. Dor en droog haar', 
    vet_haar:  '19. Vet haar', 
    neus_opstaan:  '20. ’s Morgens bij het opstaan volle neus', 
    water_neus_ogen:  '21. Waterige neus en/of ogen', 
    hooikoorts:  '22. Hooikoorts', 
    kringen_ogen:  '23. Donkere kringen onder de ogen', 
    dik_ogen:  '24. Dikke oogleden', 
    oogontst:  '25. Oogontsteking', 
    geel_ogwit:  '26. Geel oogwit - blauw oogwit - rood oogwit (adertjes)', 
    troeb_zicht:  '27. Troebel zicht - nachtblindheid', 
    jeuk_neus:  '28. Jeuk aan de neus', 
    droge_neus:  '29. Droge neus', 
    jeuk_oren:  '30. Jeukende oren - looporen', 
    oorsuiz:  '31. Oorsuizingen (hoge piep - lage piep)', 
    hoofd_geh:  '32. Hoofdpijn: bonzend/stekend/zwaar/vol/kloppend/stuwend/aangesnoerd gevoel', 
    geluiden:  '33. Geluiden in het hoofd', 
    hoofd_menst:  '34. Hoofdpijn voor/tijdens/na de menstruatie', 
    hoofd_boven:  '35. Hoofdpijn boven op het hoofd/zijkant van het hoofd/achterhoofd', 
    haaruitv:  '36. Haaruitval', 
    roos:  '37.Roos', 
    opm_klant: "",
    opm_admin: ""
};

export const TI_spijsvertering = {
    nare_smaak:  '1. Nare smaak in de mond: metaal - bitter - zuur - ander', value:null, type: 0 ,
    s_adem:  '2. Slechte adem', 
    verm_eetlust:  '3. Verminderde eetlust', 
    besl_tong:  '4. Beslagen tong (kleur?)', 
    gezw_tandvlees:  '5. Gezwollen tandvlees', 
    amandelontst:  '6. Amandelontsteking/ vatbaarheid voor infecties', 
    vastz_slijm:  '7. Vastzittend slijm - slijmproppen in de keel', 
    gezw_lymf:  '8. Gezwollen lymfeklieren', 
    krac_stem:  '9. Krachteloze stem - stemverlies - heesheid', 
    boeren:  '10. Boeren, oprispingen', 
    misselijkheid:  '11. Misselijkheid - braken', 
    zuurbranden:  '12. Zuurbranden of geïrriteerde maag (verbetert na het eten)', 
    wind_enum:  '13. Winderigheid en/of opgeblazen gevoel uren na de maaltijd', 
    maagpijn:  '14. Maagpijn', 
    onver_rest_ontl:  '15. Onverteerde resten in de ontlasting', 
    brand_jeuk_anus:  '16. Brandende of jeukende anus', 
    maagzweer:  '17. Maagzweer/ Darmzweer', 
    droge_mond:  '18. Droge mond, dorstig', 
    verlies_smaak:  '19. Verlies van smaak - verlies van reuk', 
    gezw_tong:  '20. Gezwollen tong', 
    brand_tong:  '21. Branderige tong', 
    gezw_lippen:  '22. Gezwollen lippen', 
    zweren_mond:  '23. Zweren in de mond (aften)', 
    keelontst:  '24. Keelontsteking - angina', 
    moeite_slikken:  '25. Moeite met slikken', 
    kuchen:  '26. Kuchen - brok in de keel', 
    opgebl_gev:  '27. Opgeblazen gevoel', 
    galaanvallen:  '28. Galaanvallen/ galstenen', 
    zwaar_gev:  '29. Zwaar, vol gevoel (steen) in de maag', 
    pijnl_buik:  '30. Pijnlijke buik half uur/uur na de maaltijd (duurt 3 à 4 uur)', 
    s_vertering:  '31. Slechte vertering van groenten en/of fruit', 
    const_diarree:  '32. Constipatie/ diarree', 
    reg_wat_ontl:  '33. Regelmatig waterige, dunne ontlasting', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_ontlasting_stoelgang = {
    geur:  '1. Geur: zurig - rotte eieren - vettig', 
    vorm:  '2. Vorm: waterdun - gistend - brijachtig - hard - keutels', 
    vet_drijv_ontl:  '3. Vette, drijvende ontlasting', 
    bloed_ontl:  '4. Bloed in de ontlasting', 
    kleur:  '5. Kleur: zwart - okergeel - groen - lichtbruin - grijs', 
    plak_ontl:  '6. Plakkerige ontlasting', 
    vastz_slijm:  '7. Vastzittend slijm - slijmproppen in de keel', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_transpiratie = {
    geur:  '1. Geur: zoet - zuur - zout - scherp - ammoniak', 
    zweetaanvallen:  '2. Zweetaanvallen', 
    hele_lichaam_zweetaanvallen:  '3. Zweetaanvallen over het hele lichaam', 
    nachtelijke_zweetaanvallen:  '4. Zweetaanvallen ’s nachts', 
    zweten_na_maaltijd:  '5. Zweten na de maaltijd', 
    blozen:  '6. Blozen', 
    overmatig_zweten:  '7. Overmatig zweten', 
    zweten_op_hoofd_handen_voeten:  '8. Alleen aan hoofd, handen, voeten', 
    sterke_zweetgeur:  '9. Sterke geur', 
    overdag_veel_zweten:  '10. Veel zweten overdag', 
    zweetvoeten_brandende_voeten:  '11. Zweetvoeten - brandende voeten', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_urine = {
    geur:  '1. Geur: zoet - zuur - asperge', 
    bloed_in_urine:  '2. Bloed in de urine', 
    kleur:  '3. Kleur: groen - rood - geel - kleurloos', 
    vlokken_in_urine:  '4. Vlokken in de urine', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_allergie_overgevoeligheden = {
    insectenbeten:  '1. Gevoelig voor insectenbeten (muggen)', 
    geluid_overgevoeligheid:  '2. Overgevoelig voor geluid', 
    parfum:  '3. Gevoelig voor parfum', 
    zonneallergie:  '4. Zonneallergie', 
    additieven:  '5. Gevoelig voor additieven (E-nummers)', 
    nikkel_metaal:  '6. Gevoelig voor nikkel - metaal', 
    medicijnen_allergie:  '7. Allergisch voor medicijnen (welke?)', value:"", type: 'string' ,
    hyperactiviteit:  '8. Hyperactiviteit, onrustigheid', 
    licht_overgevoeligheid:  '9. Overgevoelig voor licht', 
    chemische_stoffen:  '10. Gevoelig voor chemische stoffen', 
    formaldehyde:  '11. Gevoelig voor formaldehyde', 
    galbulten:  '12. Galbulten', 
    chinese_maaltijden:  '13. Gevoelig voor Chinese maaltijden/ mononatrium-glutamaat/ smaakversterkers', 
    voeding_allergie:  '14. Allergisch voor bepaalde voeding (welke?)', value:"", type: 'string' ,
    vaccinatie_reacties:  '15. Heftige reacties op vaccinaties (welke?)', value:"", type: 'string' ,
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_huid = {
    acne:  '1. Acne', 
    droge_huid:  '2. Droge huid - schilferige huid', 
    slechte_wondgenezing:  '3. Slechte wondgenezing', 
    naaldenprikgevoel:  '4. Naaldenprikgevoel - gevoelloosheid van de huid', 
    blauwe_plekken:  '5. Snel blauwe plekken', 
    huidstriemen:  '6. Huidstriemen', 
    vette_huid:  '7. Vette huid', 
    jeuk:  '8. Jeuk', 
    eczeem:  '9. Eczeem (nat/ droog/ vurig)', 
    huiduitslag:  '10. Huiduitslag', 
    bruine_verkleuring:  '11. Bruine vlekken of verkleuringen', 
    cellulitis:  '12. Cellulitis', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_nagels = {
    witte_vlekjes:  '1. Witte vlekjes in de nagels', 
    scheurende_nagels:  '2. Snel scheurende nagels', 
    nagelriem_ontstekingen:  '3. Nagelriem-ontstekingen', 
    broze_nagels:  '4. Broze, breekbare nagels', 
    lepelvormige_nagels:  '5. Lepelvormige nagels', 
    nagel_ribbels:  '6. Ribbels in de nagels', 
    kalknagel:  '7. Kalknagel(s)', 
    dunne_nagels:  '8. Dunne nagels (continue - periodiek)', 
    nagel_verkleuring:  '9. Verkleuringen', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_gebit = {
    slecht_gebit:  '1. Slecht gebit', 
    kunstgebit:  '2. Kunstgebit', 
    kronen_bruggen:  '3. Kronen - bruggen', 
    bleekbehandelingen:  '4. Bleekbehandelingen in het verleden', 
    kaakoperatie:  '5. Kaakoperatie', 
    vullingen_amalgaam:  '6. Vullingen (aantal?) - Amalgaam?', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_hormonen = {
    zin_in_seks:  '1. Toegenomen zin in seks', 
    abnormale_dorst:  '2. Abnormale dorst', 
    veel_suiker:  '3. Veel suiker kunnen eten zonder bijverschijnselen', 
    warmte_intolerantie:  '4. Warmte niet kunnen verdragen', 
    verminderd_initiatief:  '5. Verminderd initiatief/ mentale traagheid', 
    gevoelig_voor_kou:  '6. Gevoelig voor kou/ slechte bloedcirculatie', 
    flushes:  '7. Flushes (rode, jeukerige, geprikkelde huid)', 
    mannelijk_voorkomen:  '8. Mannelijk voorkomen (vrouw)', 
    middags_geeuwen:  '9. ’s Middags geeuwen', 
    langzaam_opstarten:  '10. Langzaam op gang komen ’s ochtends', 
    afgenomen_zin_in_seks:  '11. Afgenomen zin in seks', 
    gewichtstoename_heupen_taille:  '12. Gewichtstoename rond de heupen - taille', 
    moeilijk_aankomen:  '13. Moeilijk aankomen', 
    moeilijk_afvallen:  '14. Moeilijk afvallen', 
    snel_moe:  '15. Snel moe/ Slaperig overdag', 
    opvliegers:  '16. Opvliegers', 
    haargroei:  '17. Haargroei in het gezicht of op het lichaam (vrouw)', 
    chronisch_moe:  '18. Chronisch moe, suf', 
    zwakte_na_verkoudheid:  '19. Zwakte na verkoudheid, langzaam herstel', 
    slechte_temperatuurregulatie:  '20. Slechte regulatie van de lichaamstemperatuur', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_slaappatroon = {
    slapeloosheid:  '1. Slapeloosheid', 
    slecht_inslapen:  '2. Slecht inslapen, lang wakker liggen', 
    vroeg_wakker_worden:  '3. ’s Morgens vroeg wakker worden', 
    vast_wakker_worden:  '4. Wakker worden op vaste tijdstippen (hoelaat?)', 
    goed_inslapen_maar_wakker:  '5. Goed inslapen, maar na enkele uren wakker worden en vervolgens niet goed meer kunnen inslapen', 
    moe_wakker_worden:  '6. ’s Morgens moe/uitgeput wakker worden', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_psychisch = {
    verveeld_ontevreden:  '1. Verveeld/ontevreden/gefrustreerd', 
    depressief_somber:  '2. Zwaarmoedig/depressief/somber', 
    angsten_paniekaanvallen:  '3. Fobieën/angsten/paniekaanvallen', 
    snel_verward_nerveus:  '4. Snel in verwarring/onzeker/nerveus', 
    coördinatie_stoornissen:  '5. Coördinatie-stoornissen', 
    stemmingswisselingen:  '6. Wisselende stemmingen', 
    verminderd_initiatief:  '7. Verminderd initiatief', 
    malende_negatieve_gedachten:  '8. Malende gedachten/negatieve gedachten', 
    nachtmerries:  '9. Nachtmerries', 
    burn_out_overspannen:  '10. Burn-out/overspannenheid', 
    niet_onder_druk_werken:  '11. Niet onder druk kunnen werken', 
    emotioneel_zorgelijk:  '12. Bedroefd/emotioneel/zorgelijk', 
    onduidelijk_praten:  '13. Onduidelijk praten/woorden omdraaien', 
    prikkelbaar_opvliegend:  '14. Prikkelbaarheid/opvliegend/snel boos', 
    leermoeilijkheden:  '15. Leermoeilijkheden', 
    labiel:  '16. Labiel', 
    slecht_geheugen:  '17. Slecht geheugen (korte termijn - lange termijn)', 
    dwanghandelingen:  '18. Dwanghandelingen', 
    dromen_niet_herinneren:  '19. Dromen niet kunnen herinneren', 
    concentratieproblemen:  '20. Concentratieproblemen', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_longen = {
    zware_onreg_ademhaling:  '1. Zware en/of onregelmatige ademhaling', 
    kortademigheid:  '2. Kortademigheid', 
    kortademigheid_bij_inspanning:  '3. Kortademigheid tijdens/na inspanningen', 
    benauwd_gevoel:  '4. Benauwd gevoel', 
    neiging_flauwvallen:  '5. Neiging tot flauwvallen', 
    astma:  '6. Astma', 
    onwel_op_hoogten:  '7. Onwel worden op grote hoogten', 
    kortademigheid_bij_temp:  '8. Kortademigheid bij warmte/koude', 
    vaak_zuchten_geeuwen:  '9. Vaak zuchten/geeuwen', 
    hyperventilatie:  '10. Hyperventilatie', 
    bronchitis_emfyseem:  '11. Regelmatig bronchitis/emfyseem', 
    piepende_ademhaling:  '12. Piepende ademhaling', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_cardiovasculair = {
    gezwollen_enkels:  "1. Gezwollen enkels ('s nachts erger)", 
    onreg_hartslag:  '2. Onregelmatige hartslag', 
    hartruis:  '3. Hartruis', 
    bonkend_hart_bij_liggen:  '4. Last van bonkend hart bij liggen', 
    spataders:  '5. Spataders', 
    hoge_bloeddruk:  '6. Hoge bloeddruk', 
    rusteloze_benen:  '7. Rusteloze benen', 
    koude_handen_voeten:  '8. Koude handen en voeten', 
    bloedarmoede_bleekheid:  '9. Bloedarmoede/bleekheid', 
    pijn_borst_uitstralend_naar_linkerarm:  '10. Pijn in de borst/uitstralend naar linkerarm (erger bij inspanning)', 
    hartkloppingen:  '11. Hartkloppingen', 
    snelle_hartslag_na_maaltijd:  '12. Snelle hartslag na de maaltijd/bonkend hart na rust', 
    snelle_hartslag_in_rust:  '13. Snelle hartslag in rust', 
    aambeien:  '14. Aambeien', 
    lage_bloeddruk:  '15. Lage bloeddruk', 
    pijn_in_kuiten:  '16. Pijn in de kuiten', 
    kloppende_vaten:  '17. Kloppende vaten/stuwingen', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_spieren_en_gewrichten = {
    slappe_zwakke_spieren:  '1. Slappe, zwakke spieren', 
    slechte_motoriek:  '2. Slechte motoriek, moeite met fijne bewegingen', 
    krampen_spieren_benen_tenen:  '3. Krampen in spieren, benen, tenen tijdens rust/slaap', 
    dikke_vingers_oedeem:  '4. Dikke vingers (oedeem)', 
    zwaar_gevoel_armen_benen:  '5. Zwaar gevoel in armen en/of benen', 
    pijnlijke_gewrichten:  '6. Pijnlijke gewrichten', 
    zwak_bindweefsel:  '7. Zwak bindweefsel', 
    spierpijn:  '8. Spierpijn', 
    gewrichtsontstekingen:  '9. Gewrichtsontstekingen', 
    gevoelloosheid_handen_voeten:  '10. Gevoelloosheid in handen en voeten', 
    ochtendstijfheid:  '11. Ochtendstijfheid', 
    krakende_gewrichten:  '12. Krakende gewrichten', 
    gezwollen_gewrichten:  '13. Gezwollen gewrichten', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_pijn = {
    scherp_stekend:  '1. Scherp/ stekend', 
    verspringend:  '2. Verspringend', 
    wervelkolom:  '3. Wervelkolom', 
    pijn_schouderbladen_frozen_shoulder:  '4. Pijn tussen de schouderbladen/ frozen shoulder', 
    zeurend:  '5. Zeurend', 
    uitstralende:  '6. Uitstralende', 
    nekklachten:  '7. Nekklachten', 
    rugpijn_l_m_h:  '8. Rugpijn (laag - midden - hoog)', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_vrouw = {
    menstruatie:  '1. Menstruatie: geen/ regelmatig/ onregelmatig', 
    menstruatie_txt:  '', value: '', type: 1 ,
    hevige_mens:  '2. Hevige menstruatie', 
    eerste_mens_leeftijd:  '3. Eerste menstruatie op leeftijd van?', value: '', type: 1 ,
    bloedstolsels:  '4. Helder bloed - donkere bloedstolsels', 
    depressie_v_mens:  '5. Depressie voor de menstruatie', 
    acne_v_mens:  '6. Acne die verergert tijdens de menstruatie', 
    vag_infecties:  '7. Vaginale infecties/ jeuk', 
    vaak_plassen:  '8. Vaak kleine hoeveelheden plassen', 
    opvliegers_meno:  '9. Opvliegers tijdens de menopauze', 
    zw_diabetes:  '10. Zwangerschaps-diabetes', 
    ivf_behandelingen:  '11. IVF-behandelingen', 
    ant_conceptie_klachten:  '12. Klachten van de anticonceptiepil/ spiraaltje', 
    pijn_mens:  '13. Pijnlijke menstruatie', 
    lichte_mens:  '14. Lichte menstruatie', 
    extreme_bloedingen:  '15. Extreme bloedingen', 
    pms:  '16. Premenstrueel syndroom', 
    pijn_borsten_cysten:  '17. Pijnlijke gevoelige borsten/ cysten', 
    vag_afscheiding:  '18. Vaginale afscheiding (doorzichtig - wit - geel - bruin)', 
    baarm_eierstokken_verw:  '19. Verwijderde baarmoeder/ eierstokken', 
    blaasontstekingen:  '20. Regelmatig blaasontstekingen', 
    miskramen:  '21. Eén of meerdere miskramen gehad', 
    moeite_zwanger_worden:  '22. Moeite om zwanger te worden', 
    borstvergroting_verkl:  '23. Borstvergroting - borstverkleining', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const TI_man = {
    prostaatkl:  '1. Prostaatklachten', 
    pijn_binnenkant_benen_hielen:  '2. Pijn aan de binnenkant van de benen of hielen', 
    vroegt_zaadlozing:  '3. Vroegtijdige zaadlozing', 
    vaak_plassen:  '4. Vaak kleine hoeveelheden plassen', 
    erectieprob:  '5. Erectieproblemen', 
    verminderde_vruchtb:  '6. Verminderde vruchtbaarheid', 
    opm_klant: "Wilt u nog is toevoegen?",
    opm_admin: "Geef nog opmerkingen"
};

export const KlachtenTitel = {
    hoofd :TI_hoofd
    ,spijsvertering :TI_spijsvertering
    ,ontlasting_stoelgang :TI_ontlasting_stoelgang
    ,transpiratie :TI_transpiratie
    ,urine :TI_urine
    ,allergie_overgevoeligheden :TI_allergie_overgevoeligheden
    ,huid :TI_huid
    ,nagels :TI_nagels
    ,gebit :TI_gebit
    ,hormonen :TI_hormonen
    ,slaappatroon :TI_slaappatroon
    ,psychisch :TI_psychisch
    ,longen :TI_longen
    ,cardiovasculair :TI_cardiovasculair
    ,spieren_en_gewrichten :TI_spieren_en_gewrichten
    ,pijn :TI_pijn
    ,vrouw :TI_vrouw
    ,man :TI_man
};

export const VL_hoofd = (id = 0) => ({
     
    vol_geh_og_voch: null,
    niesaanv: null,
    opgez_ogen: null,
    wallen_ogen: null,
    droge_ogen: null,
    dicht_ogen: null,
    staar: null,
    brand_ogen: null,
    tran_jeuk_ogen: null,
    verstop_neus: null,
    bloedneus: null,
    oorpijn: null,
    hoofd_ontw: null,
    hoofd_stress: null,
    hoofd_midd: null,
    hoofd_b_ogen: null,
    migraine: null,
    droog_haar: null,
    vet_haar: null,
    neus_opstaan: null,
    water_neus_ogen: null,
    hooikoorts: null,
    kringen_ogen: null,
    dik_ogen: null,
    oogontst: null,
    geel_ogwit: null,
    troeb_zicht: null,
    jeuk_neus: null,
    droge_neus: null,
    jeuk_oren: null,
    oorsuiz: null,
    hoofd_geh: null,
    geluiden: null,
    hoofd_menst: null,
    hoofd_boven: null,
    haaruitv: null,
    roos: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""

});

export const VL_spijsvertering = (id = 0) => ({
    
    nare_smaak: null,
    s_adem: null,
    verm_eetlust: null,
    besl_tong: null,
    gezw_tandvlees: null,
    amandelontst: null,
    vastz_slijm: null,
    gezw_lymf: null,
    krac_stem: null,
    boeren: null,
    misselijkheid: null,
    zuurbranden: null,
    wind_enum: null,
    maagpijn: null,
    onver_rest_ontl: null,
    brand_jeuk_anus: null,
    maagzweer: null,
    droge_mond: null,
    verlies_smaak: null,
    gezw_tong: null,
    brand_tong: null,
    gezw_lippen: null,
    zweren_mond: null,
    keelontst: null,
    moeite_slikken: null,
    kuchen: null,
    opgebl_gev: null,
    galaanvallen: null,
    zwaar_gev: null,
    pijnl_buik: null,
    s_vertering: null,
    const_diarree: null,
    reg_wat_ontl: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_ontlasting_stoelgang = (id = 0) => ({
    
    geur: null,
    vorm: null,
    vet_drijv_ontl: null,
    bloed_ontl: null,
    kleur: null,
    plak_ontl: null,
    vastz_slijm: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_transpiratie = (id = 0) => ({
    
    geur: null,
    zweetaanvallen: null,
    hele_lichaam_zweetaanvallen: null,
    nachtelijke_zweetaanvallen: null,
    zweten_na_maaltijd: null,
    blozen: null,
    overmatig_zweten: null,
    zweten_op_hoofd_handen_voeten: null,
    sterke_zweetgeur: null,
    overdag_veel_zweten: null,
    zweetvoeten_brandende_voeten: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_urine = (id = 0) => ({
    
    geur: null,
    bloed_in_urine: null,
    kleur: null,
    vlokken_in_urine: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_allergie_overgevoeligheden = (id = 0) => ({
    
    insectenbeten: null,
    geluid_overgevoeligheid: null,
    parfum: null,
    zonneallergie: null,
    additieven: null,
    nikkel_metaal: null,
    medicijnen_allergie: "",
    hyperactiviteit: null,
    licht_overgevoeligheid: null,
    chemische_stoffen: null,
    formaldehyde: null,
    galbulten: null,
    chinese_maaltijden: null,
    voeding_allergie: "",
    vaccinatie_reacties: "",
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_huid = (id = 0) => ({
    
    acne: null,
    droge_huid: null,
    slechte_wondgenezing: null,
    naaldenprikgevoel: null,
    blauwe_plekken: null,
    huidstriemen: null,
    vette_huid: null,
    jeuk: null,
    eczeem: null,
    huiduitslag: null,
    bruine_verkleuring: null,
    cellulitis: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_nagels = (id = 0) => ({
    
    witte_vlekjes: null,
    scheurende_nagels: null,
    nagelriem_ontstekingen: null,
    broze_nagels: null,
    lepelvormige_nagels: null,
    nagel_ribbels: null,
    kalknagel: null,
    dunne_nagels: null,
    nagel_verkleuring: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_gebit = (id = 0) => ({
    
    slecht_gebit: null,
    kunstgebit: null,
    kronen_bruggen: null,
    bleekbehandelingen: null,
    kaakoperatie: null,
    vullingen_amalgaam: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_hormonen = (id = 0) => ({
    
    zin_in_seks: null,
    abnormale_dorst: null,
    veel_suiker: null,
    warmte_intolerantie: null,
    verminderd_initiatief: null,
    gevoelig_voor_kou: null,
    flushes: null,
    mannelijk_voorkomen: null,
    middags_geeuwen: null,
    langzaam_opstarten: null,
    afgenomen_zin_in_seks: null,
    gewichtstoename_heupen_taille: null,
    moeilijk_aankomen: null,
    moeilijk_afvallen: null,
    snel_moe: null,
    opvliegers: null,
    haargroei: null,
    chronisch_moe: null,
    zwakte_na_verkoudheid: null,
    slechte_temperatuurregulatie: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_slaappatroon = (id = 0) => ({
    
    slapeloosheid: null,
    slecht_inslapen: null,
    vroeg_wakker_worden: null,
    vast_wakker_worden: null,
    goed_inslapen_maar_wakker: null,
    moe_wakker_worden: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_psychisch = (id = 0) => ({
    
    verveeld_ontevreden: null,
    depressief_somber: null,
    angsten_paniekaanvallen: null,
    snel_verward_nerveus: null,
    coördinatie_stoornissen: null,
    stemmingswisselingen: null,
    verminderd_initiatief: null,
    malende_negatieve_gedachten: null,
    nachtmerries: null,
    burn_out_overspannen: null,
    niet_onder_druk_werken: null,
    emotioneel_zorgelijk: null,
    onduidelijk_praten: null,
    prikkelbaar_opvliegend: null,
    leermoeilijkheden: null,
    labiel: null,
    slecht_geheugen: null,
    dwanghandelingen: null,
    dromen_niet_herinneren: null,
    concentratieproblemen: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_longen = (id = 0) => ({
    
    zware_onreg_ademhaling: null,
    kortademigheid: null,
    kortademigheid_bij_inspanning: null,
    benauwd_gevoel: null,
    neiging_flauwvallen: null,
    astma: null,
    onwel_op_hoogten: null,
    kortademigheid_bij_temp: null,
    vaak_zuchten_geeuwen: null,
    hyperventilatie: null,
    bronchitis_emfyseem: null,
    piepende_ademhaling: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_cardiovasculair = (id = 0) => ({
    
    gezwollen_enkels: null,
    onreg_hartslag: null,
    hartruis: null,
    bonkend_hart_bij_liggen: null,
    spataders: null,
    hoge_bloeddruk: null,
    rusteloze_benen: null,
    koude_handen_voeten: null,
    bloedarmoede_bleekheid: null,
    pijn_borst_uitstralend_naar_linkerarm: null,
    hartkloppingen: null,
    snelle_hartslag_na_maaltijd: null,
    snelle_hartslag_in_rust: null,
    aambeien: null,
    lage_bloeddruk: null,
    pijn_in_kuiten: null,
    kloppende_vaten: null,
    opm_klant: "",
    opm_admin: ""
});

export const VL_pijn = (id = 0) => ({
    
    scherp_stekend: null,
    verspringend: null,
    wervelkolom: null,
    pijn_schouderbladen_frozen_shoulder: null,
    zeurend: null,
    uitstralende: null,
    nekklachten: null,
    rugpijn_l_m_h: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_spieren_en_gewrichten = (id = 0) => ({
    
    slappe_zwakke_spieren: null,
    slechte_motoriek: null,
    krampen_spieren_benen_tenen: null,
    dikke_vingers_oedeem: null,
    zwaar_gevoel_armen_benen: null,
    pijnlijke_gewrichten: null,
    zwak_bindweefsel: null,
    spierpijn: null,
    gewrichtsontstekingen: null,
    gevoelloosheid_handen_voeten: null,
    ochtendstijfheid: null,
    krakende_gewrichten: null,
    gezwollen_gewrichten: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_vrouw = (id = 0) => ({
    
    menstruatie: null,
    menstruatie_txt: '',
    hevige_mens: null,
    eerste_mens_leeftijd: '',
    bloedstolsels: null,
    depressie_v_mens: null,
    acne_v_mens: null,
    vag_infecties: null,
    vaak_plassen: null,
    opvliegers_meno: null,
    zw_diabetes: null,
    ivf_behandelingen: null,
    ant_conceptie_klachten: null,
    pijn_mens: null,
    lichte_mens: null,
    extreme_bloedingen: null,
    pms: null,
    pijn_borsten_cysten: null,
    vag_afscheiding: null,
    baarm_eierstokken_verw: null,
    blaasontstekingen: null,
    miskramen: null,
    moeite_zwanger_worden: null,
    borstvergroting_verkl: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const VL_man = (id = 0) => ({
    
    prostaatkl: null,
    pijn_binnenkant_benen_hielen: null,
    vroegt_zaadlozing: null,
    vaak_plassen: null,
    erectieprob: null,
    verminderde_vruchtb: null,
    opm_klant: "",
    opm_admin: "",
    admin_id:""
});

export const KlachtenValues = (id = 0) => ({
    hoofd :VL_hoofd(id)
    ,spijsvertering :VL_spijsvertering(id)
    ,ontlasting_stoelgang :VL_ontlasting_stoelgang(id)
    ,transpiratie :VL_transpiratie(id)
    ,urine :VL_urine(id)
    ,allergie_overgevoeligheden :VL_allergie_overgevoeligheden(id)
    ,huid :VL_huid(id)
    ,nagels :VL_nagels(id)
    ,gebit :VL_gebit(id)
    ,hormonen :VL_hormonen(id)
    ,slaappatroon :VL_slaappatroon(id)
    ,psychisch :VL_psychisch(id)
    ,longen :VL_longen(id)
    ,cardiovasculair :VL_cardiovasculair(id)
    ,spieren_en_gewrichten :VL_spieren_en_gewrichten(id)
    ,pijn :VL_pijn(id)
    ,vrouw :VL_vrouw(id)
    ,man :VL_man(id)
    });



