import React from "react";
import emailjs from "@emailjs/browser";
import { sendEmail } from "../../function";
import { emailHTML } from "../../EmailTemplates";

/*
<!DOCTYPE html>
<html lang="nl">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Bevestiging Registratie Repeat Connect</title>
  <style>
    body {
      font-family: Arial, sans-serif;
      line-height: 1.6;
      color: #333;
    }
    .container {
      max-width: 600px;
      margin: 0 auto;
      padding: 20px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background-color: #f9f9f9;
    }
    h1 {
      color: #4CAF50;
    }
    .details {
      margin: 20px 0;
    }
    .button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #4CAF50;
      color: white;
      text-decoration: none;
      border-radius: 5px;
    }
    .footer {
      margin-top: 20px;
      font-size: 12px;
      color: #666;
    }
  </style>
</head>
<body>
  <div class="container">
    <h1>Gefeliciteerd!</h1>
    <p>Beste {{name}},</p>
    <p><i><b>Welkom bij Repeat Connect!</b></i></p>
    <p>We bevestigen hierbij dat uw registratie bij Repeat Connect succesvol is afgerond. U heeft nu toegang tot ons digitale platform.</p>
<h4>Uw inloggegevens:</h4>
    <div class="details">
      <p><strong>Gebruikersnaam:</strong> {{gebruikersnaam}}</p>
      <p><strong>Wachtwoord:</strong> {{wachtwoord}}</p>
    </div>

    <p>Met uw account kunt u:</p>
 <p>   
Een uitgebreid overzicht van uw gezondheidstoestand bekijken.
Uw afspraken eenvoudig beheren.</p>

<p>De vooruitgang van uw gezondheid volgen.</p>

<p>Onze specialisten staan voor u klaar om ervoor te zorgen dat u de best mogelijke zorg ontvangt en uw gezondheid optimaal blijft.</p>

    <p>Klik op onderstaande knop om in te loggen:</p>

    <p><a href="{{inloglink}}" class="button">Inloggen</a></p>

    <p>Heeft u vragen of hulp nodig? Neem gerust contact met ons op via <a href="mailto:info@repeathealth.com">info@repeathealth.com</a>.</p>

    <p>We kijken ernaar uit om u van dienst te zijn.</p>

    <p>Met vriendelijke groet,</p>
    <p><strong>Het Repeat Connect-team</strong></p>

    <div class="footer">
      <p>Dit is een automatisch gegenereerde e-mail. Beantwoord dit bericht niet.</p>
    </div>
  </div>
</body>
</html>


*/

const SendEmailButton2 = ({ email, text = "Verstuur Wachtwoord" }) => {
  const send = () => {
    // Object with dynamic data
    const emailData = {
      from_name: email?.from_name,
      sender_email: "info@repeathealth.com", // Replace with dynamic sender email
      title:`Hallo ${email?.first_name}, Welkom bij RepeatConnect!`,
      receiver_email: email?.receiver, // Replace with dynamic receiver email
      gebruikersnaam: email?.username || email?.receiver, // Replace with dynamic username
      name:email?.name,
      wachtwoord: email?.password, // Replace with dynamic password
      inloglink: "https://repeatconnect.com", // Replace with the login URL
    };



    const emailHtml = emailHTML(emailData)


    sendEmail('template_qdoh9mv',{ message_html: emailHtml,title:`Hallo ${email?.first_name}, Welkom bij RepeatConnect!`,receiver_email: email?.receiver, // Replace with dynamic receiver email
    });
  };

  return (
    <button
      className="border p-2 border-grey-200 bg-grey-900 rounded"
      onClick={send}
    >
      {text}
    </button>
  );
};

export default SendEmailButton2;
