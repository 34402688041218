import React, { useLayoutEffect, useState } from "react";
import RPModal from "../../Modal/RPModal";
import { KlachtenValues } from "../../klantenregister";
import RpKlachtenRegisterForm from "../klachtenregister/RpKlachtenRegisterForm";
import { getKlacht, getStates } from "../../Localstorage/localstorage";
import { AXIOS_GET } from "../../server/api/crud";
import { faListCheck, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function KlachtenRegisterFormulier({ swipperRef, userdetails,user, navigation }) {
  const [open, setOpen] = useState(false);
  const [klachten, setKlachten] = useState(null);
  const [state, setState] = useState(null);
  const [klacht, setKlacht] = useState(0);
  const klachtslocal = getKlacht();
  const status = getStates();

  useLayoutEffect(() => {
    let kl = {};
    let st = {};

    const fetchDataKlacht = async (id) => {
      try {
        const response = await AXIOS_GET(`klachtenregistratie/all/${id}`);
        // Loop through the object properties

        const dataKlachten = response?.data;
        let newKlacht = {};

        Object.entries(dataKlachten).map(([key, value]) => {
          let remove = key
            .replace("Data", "")
            .replace("ontlastingstoelgang", "ontlasting_stoelgang");
          if (value.length > 0) {
            newKlacht[remove] = value[0];
          } else {
            newKlacht[remove] = KlachtenValues(id)[remove];
          }
        });
        setKlachten(newKlacht);
      } catch (error) {
        setKlachten(KlachtenValues(id));
        console.error("Error fetching data:", error);
      }
    };

    const fetchdata = async () => {
      await AXIOS_GET(`klachtenregistratie/${userdetails?.user_id}`)
        .then((resklacht) => {
          if (resklacht.data.length > 0) {
            kl = resklacht.data[0];
            st = { id: resklacht.data[0]?.status };
            setKlacht(kl);
            setState(st);
            fetchDataKlacht(kl?.id);
          } else {
            setKlacht(klachtslocal);
            setState(status);
            fetchDataKlacht(klachtslocal?.id);
          }
        })
        .catch(() => {
          setState({ id: 0 });
          fetchDataKlacht(0);
        });
    };
    fetchdata();
  }, [getKlacht, getStates]);


  return (
    <div className="flex justify-center items-center mt-16">
      {klacht.status < 17 && klacht.status > 0 && klacht?.versturen == 0 ? (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div
            className={`ml-8 text-4xl bg-green-50 flex items-center justify-center text-green-800`}
          >
            <FontAwesomeIcon icon={faListCheck} />
          </div>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Het klachtenformulier is al ingevuld. Wilt u nog aanpassingen maken?
            Klik op deze ruimte
          </span>
        </button>
      ) : klacht?.versturen == 1 ? (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <div
            className={`ml-8 text-4xl flex items-center justify-center text-green-800 mb-8`}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
          </div>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Het klachtenformulier is ingevuld en verstuurd.
          </span>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Vervolgens als u verdergaan wilt gaan met het invullen van de
            vragenlijsten? Klik dan bovenaan op de tab 'Stap 4: VoedingDagboek'.
          </span>
        </button>
      ) : (
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="relative block w-3/4 rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <svg
            className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-100"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 2H32C33.1 2 34 2.9 34 4V44C34 45.1 33.1 46 32 46H12C10.9 46 10 45.1 10 44V4C10 2.9 10.9 2 12 2Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 2H24L34 12H10V2Z"
            />
          </svg>

          <span className="mt-2 block text-lg font-semibold text-gray-900 dark:text-gray-100">
            Vul uw Klachtenregistratie vragenlijst in door op deze ruimte te
            klikken.
          </span>
        </button>
      )}
      <RPModal
        setOpen={setOpen}
        open={open}
        Component={
          <RpKlachtenRegisterForm
            setOpen={setOpen}
            setKlachten={setKlachten}
            klachten={klachten}
            status={state}
            klacht={klacht}
          />
        }
      />
    </div>
  );
}

export default KlachtenRegisterFormulier;
