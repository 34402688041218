import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useSelector } from "react-redux";
import LittleCalendarBordeless from "./LittleCalendarBorderless";
import { useLayoutEffect, useState } from "react";
import RP_Avatar from "../RP_Avatar";

function generateDaysArray2(currentMonth, selectedDay, events) {
  const startOfMonth = moment(currentMonth).locale("nl-be").startOf("isoWeek");
  const endOfMonth = moment(currentMonth)
    .locale("nl-be")
    .endOf("month")
    .endOf("isoWeek");
  const days = [];

  let currentDate = moment(startOfMonth).locale("nl-be");

  while (currentDate.isSameOrBefore(endOfMonth)) {
    const isToday = currentDate.isSame(moment(), "day");

    // Filter events that match the current date
    const eventsForDay = events?.filter(
      (event) =>
        moment(event?.start_time).locale("nl-be").format("YYYY-MM-DD") ===
        currentDate.locale("nl-be").format("YYYY-MM-DD")
    );

    days.push({
      date: currentDate.format("YYYY-MM-DD"),
      isCurrentMonth: currentDate.isSame(currentMonth, "month"),
      isToday,
      isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
      events: eventsForDay || [], // Use filtered events for this day
    });

    currentDate.add(1, "day");
  }

  return days;
}

const Meetings = ({ appointments, dayselected }) => {
  const [events, setEvents] = useState([]);

  useLayoutEffect(() => {
    let array = appointments?.filter(
      (item) =>
        moment(item.start_time).locale("nl-be").format("YYYY-MM-DD") ===
        moment(dayselected?.date).locale("nl-be").format("YYYY-MM-DD")
    );
    setEvents(array);
  }, [dayselected?.date]);


  return (
    <section className="mt-12 md:mt-0 md:pl-14 ">
      <h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
        Datum:{" "}
        <time dateTime="2022-01-21">
          {moment(dayselected?.date).locale("nl-be").format("LL")}
        </time>
      </h2>
      <ol className="mt-4 space-y-1 text-sm leading-6 text-gray-500 dark:text-white">
        {events?.length > 0 &&
          events?.map((meeting,index) => (
            <li
              key={index}
              className="group flex items-center space-x-4 rounded-xl dark:bg-gray-700 px-4 py-2 focus-within:bg-gray-100 hover:bg-gray-600"
            >
             {/*  <RP_Avatar avatar={meeting?.user_avatar} size={10} alt={` ${meeting?.user}`} /> */}
              <div className="flex-auto">
                <p className="text-gray-900 dark:text-gray-50">{meeting?.invitees[0]?.name}</p>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Specialist: {meeting?.creators[0].user_name}
                </p>
                <p className="text-gray-500 text-xs dark:text-green-300">{meeting?.name}</p>
                <p className="mt-0.5 text-xs">
                  <time dateTime={meeting.start_time}>
                    {moment(meeting.start_time).format("HH:mm")}
                  </time>{" "}
                  -{" "}
                  <time dateTime={meeting.end_time}>
                    {moment(meeting.end_time).format("HH:mm")}
                  </time>
                </p>
              </div>
              <Menu
                as="div"
                className="relative opacity-0 focus-within:opacity-100 group-hover:opacity-100"
              >
                <div>
                  <MenuButton className="-m-2 flex items-center rounded-full p-1.5 text-gray-500 hover:text-gray-600">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </MenuButton>
                </div>

                <MenuItems
                  transition
                  className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-transparent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                >
                  <div className="py-1">
                    <MenuItem>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      >
                        Edit
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <a
                        href="#"
                        className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                      >
                        Cancel
                      </a>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Menu>
            </li>
          ))}
      </ol>
    </section>
  );
};

const currentMonth = moment().format("YYYY-MM");

const PatientenView = ({ appointments, select }) => {
  const [currentDate, setCurrentDate] = useState(
    moment().locale("nl-be").format("YYYY-MM")
  ); // Current week starting date
  const [dayselected, setDayselected] = useState(null);
  return (
    <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200 bg-transparent p-8 mt-8">
      <LittleCalendarBordeless
        currentMonth={currentDate}
        setDayselected={setDayselected}
        appointments={appointments}
        dayselected={dayselected}
        generateDaysArray={generateDaysArray2}
      />
      <Meetings appointments={appointments} dayselected={dayselected} />
    </div>
  );
};

export default PatientenView;
