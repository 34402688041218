import { PaperClipIcon } from "@heroicons/react/20/solid";
import React from "react";

export const Attachments = ({ attachments }) => {
  return (
    <div className="sm:col-span-2 w-full p-8 rounded border shadow">
       <label
        className="text-xl text-gray-600 dark:text-gray-100"
        htmlFor="time-filter"
      >
        Documenten
      </label>
      <dd className="mt-1 text-sm text-gray-900">
        <ul
          role="list"
          className="divide-y divide-gray-200 rounded-md border border-gray-200  dark:bg-gray-800"
        >
          {attachments.map((attachment) => (
            <li
              key={attachment?.bestand}
              className="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
            >
              <div className="flex w-0 flex-1 items-center">
                <PaperClipIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                />
                <span className="ml-2 w-0 flex-1 dark:text-white truncate">
                  <font className="font-bold dark:text-white">{attachment?.title}</font> (
                  {attachment?.bestand})
                </span>
              </div>
              <div className="ml-4 flex-shrink-0">
                <a
                  href={attachment?.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium dark:text-blue-400 text-blue-600 hover:text-blue-500"
                >
                  Download
                </a>
              </div>
            </li>
          ))}
        </ul>
      </dd>
    </div>
  );
};
