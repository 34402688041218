import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { imgrepeat } from "../../image";
import { useSelector } from "react-redux";

const calendly = {
  name: "Calendly",
  href: "/calendly",
  img: imgrepeat.calendly,
  current: true,
};

const SideBar = ({
  navigation,
  classNames,
  location,
  user,
  toggleOpen,
  open,
}) => {
  const [showDetails, setshowDetails] = useState(false);
  const { mailRooms } = useSelector((state) => state.mail);



    function getOpenMail () {
      var totalSum = 0;

      if(mailRooms.length > 0){
          totalSum = mailRooms.reduce((sum, item) => {
          return sum + (item.sum_status || 0)
        
        }, 0);
      }
      return totalSum;
    }
  return (
    <div
      className={` hidden lg:w-full lg:max-w-xs lg:px-6 lg:flex-1 lg:fixed lg:inset-y-0 lg:left-0 lg:z-10 lg:block lg:w-50 lg:overflow-y-auto flex grow ${
        user?.role == 2 ? "bg-[#002614] dark:bg-gray-900" : "bg-gray-700"
      } lg:pb-4`}
    >
      <div className="flex h-16 shrink-0 items-center justify-center">
        <img
          className="h-10 w-auto"
          src="https://sam.xjy.mybluehost.me/wp-content/uploads/2024/03/repeatconnect2.png"
          alt="Repeathealth"
        />
      </div>
      <nav className="flex flex-col">
        <ul role="list" className="flex flex-col gap-y-7">
          <li>
            <ul className="-mx-2 flex-1 space-y-1">
              {navigation(user,getOpenMail()).map((item) => (
                <li key={item.name}>
                  <NavLink
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive || location.pathname.startsWith(item.href)
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:text-white hover:bg-gray-900",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )
                    }
                  >
                    {item.href == "/mailbox" ? (
                      <div className="flex-1 flex gap-x-3 relative">
                        {item?.count > 0 && <div className="bg-red-900 px-1.5 py-0.5 text-xs font-medium text-red-200 rounded absolute  -top-2 -left-3 p-1">
                          {item?.count}
                        </div>}

                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        <span className="relative">{item.name}</span>
                      </div>
                    ) : (
                      <div className="flex-1 flex gap-x-3">
                        <item.icon
                          className="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                      </div>
                    )}
                    {item?.children && (
                      <>
                        {showDetails ? (
                          <button
                            title="Sluit de details"
                            className="  p-1 rounded-full"
                            onClick={() => setshowDetails(false)}
                          >
                            {" "}
                            <ChevronDownIcon className="h-4 w-4 shrink-0" />{" "}
                          </button>
                        ) : (
                          <button
                            title="Open voor details"
                            className=" p-1 rounded-full"
                            onClick={() => setshowDetails(true)}
                          >
                            {" "}
                            <ChevronRightIcon className="h-4 w-4 shrink-0" />{" "}
                          </button>
                        )}
                      </>
                    )}
                  </NavLink>
                  {item?.children && showDetails && (
                    <ul className="ml-4 space-y-1">
                      {item.children.map((child) => (
                        <li key={child.name}>
                          <NavLink
                            to={child.href}
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )
                            }
                          >
                            <child.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {child.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </li>
          {user?.role == 1 && (
            <li className="-mx-6 mt-auto">
              <NavLink
                to={calendly.href}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-blue-900 text-white"
                      : "text-gray-400 hover:text-white hover:bg-blue-800",
                    "flex items-center gap-x-4 px-6 py-3 text-sm/6 font-semibold text-white hover:bg-gray-900"
                  )
                }
              >
                <img
                  alt="Calendly"
                  src={imgrepeat.calendly}
                  className="size-5 rounded-full bg-indigo-700"
                />
                <span className="sr-only">Calendly</span>
                <span aria-hidden="true">Afspraken gemaakt in Calendly</span>
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default SideBar;
