"use client";

import {
  Input,
  Label,
} from "@headlessui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import Pagination from "./Pagination";
import { fetchAllUsersDetails } from "../apiCalls/usersApi";

const SelectDetailsPatient = ({ data, setData, setLoading, setError }) => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(8);

  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      fetchAllUsersDetails(
        pageSize,
        page,
        searchQuery,
        setLoading,
        setError,
        setData
      );
    }, 300),
    []
  );

  useEffect(() => {
    fetchAllUsersDetails(pageSize, page, search, setLoading, setError, setData);
  }, [page, pageSize]);

  // Add debounced search function

  return (
    <div className="flex w-full dark:bg-gray-800 items-center px-2 rounded-md">
      <Input
        placeholder="Zoeken"
        className="block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        onChange={(event) => {
          setSearch(event.target.value);
          debouncedSearch(event.target.value);
        }}
        onBlur={() => setSearch("")}
        displayValue={(person) =>
          person?.full_name ||
          person?.email ||
          person?.first_name ||
          person?.last_name ||
          person?.firstname ||
          person?.lastname
        }
      />

      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageRanges={[8, 16, 32, 64, 128,256]}
      />
    </div>
  );
};

export default SelectDetailsPatient;
