// calendarUtils.js

import moment from "moment";
import { appointmentss, capitalizeFirstLetter } from "../../function";

export const titleDayArray = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];

export function generateDaysArrayForMonth(currentMonth, selectedDay, events) {

    const startOfMonth = moment(currentMonth).locale('nl-be').startOf("isoWeek");
    const endOfMonth = moment(currentMonth).locale('nl-be').endOf("month").endOf("isoWeek");
    const days = [];
  
    let currentDate = moment(startOfMonth).locale('nl-be');
  
    while (currentDate.isSameOrBefore(endOfMonth)) {
      const isToday = currentDate.isSame(moment(), "day");
  
      // Filter events that match the current date
      const eventsForDay = events?.filter(event => 
        moment(event.start_time).locale('nl-be').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
      );

      days.push({
        date: currentDate.format("YYYY-MM-DD"),
        isCurrentMonth: currentDate.isSame(currentMonth, "month"),
        isToday,
        isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
        events: eventsForDay,  // Use filtered events for this day
      });
      currentDate.add(1, "day");
    }
  
  
    return days;
  }

export function generateDaysArrayForMonthCal(currentMonth, selectedDay, events) {

    const startOfMonth = moment(currentMonth).locale('nl-be').startOf("isoWeek");
    const endOfMonth = moment(currentMonth).locale('nl-be').endOf("month").endOf("isoWeek");
    const days = [];
  
    let currentDate = moment(startOfMonth).locale('nl-be');

    let array = appointmentss["collection"][0]['rules']?.filter((app) => app.type === 'date');

  
    while (currentDate.isSameOrBefore(endOfMonth)) {
      const isToday = currentDate.isSame(moment(), "day");
  
      // Filter events that match the current date
      const eventsForDay = events?.filter(event => 
        moment(event.start_time).locale('nl-be').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
      );

  
      days.push({
        date: currentDate.format("YYYY-MM-DD"),
        isCurrentMonth: currentDate.isSame(currentMonth, "month"),
        isToday,
        isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
        events: eventsForDay,  // Use filtered events for this day
      });
      currentDate.add(1, "day");
    }
  
    return days;
  }

export function generateDaysArrayForWeek(
    currentWeek,
    selectedDay,
    events = [
      {
        id: 1,
        name: "Design review",
        time: "10AM",
        datetime: "2024-10-06T10:00",
        href: "#",
      },
      {
        id: 2,
        name: "Sales meeting",
        time: "2PM",
        datetime: "2024-10-04T14:00",
        href: "#",
      },
      {
        id: 1,
        name: "Design review",
        time: "10AM",
        datetime: "2024-10-05T10:00",
        href: "#",
      },
      {
        id: 2,
        name: "Sales meeting",
        time: "2PM",
        datetime: "2024-10-07T14:00",
        href: "#",
      },
    ]
  ) {
    const startOfWeek = moment(currentWeek).locale("nl-be").startOf("isoWeek");
    const endOfWeek = moment(currentWeek).locale("nl-be").endOf("isoWeek");
    const days = [];
  
    let currentDate = startOfWeek;
    while (currentDate.isSameOrBefore(endOfWeek)) {
      const isToday = currentDate.isSame(moment(), "day");
  
      // Filter events that match the current date
      const eventsForDay = events?.filter(event => 
        moment(event.start_time).locale('nl-be').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
      );
  
      const dayInWords = currentDate.format('dddd'); // Get the day in words
      const firstLetter = dayInWords.charAt(0);
      days.push({
        numDay: currentDate.format("DD/MM"),
        num: currentDate.format("DD"),
        dnum: currentDate.day,
        titleDay: capitalizeFirstLetter(currentDate.format("dd")),
        date: currentDate.format("YYYY-MM-DD"),
        isCurrentWeek: currentDate.isSame(currentWeek, "Week"),
        isToday,
        isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
        events: eventsForDay, // Use filtered events for this day
      });
  
      currentDate.add(1, "day");
    }
    return days;
  }

export function generateDaysArrayForDay(currentMonth, selectedDay, events) {
    const startOfMonth = moment(currentMonth).locale("nl-be").startOf("isoWeek");
    const endOfMonth = moment(currentMonth)
      .locale("nl-be")
      .endOf("month")
      .endOf("isoWeek");
    const days = [];
  
    let currentDate = moment(startOfMonth).locale("nl-be");
  
    while (currentDate.isSameOrBefore(endOfMonth)) {
      const isToday = currentDate.isSame(moment(), "day");
  
      // Filter events that match the current date
      const eventsForDay = events?.filter(event => 
        moment(event.start_time).locale('nl-be').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
      );
  
      days.push({
        date: currentDate.format("YYYY-MM-DD"),
        isCurrentMonth: currentDate.isSame(currentMonth, "month"),
        isToday,
        isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
        events: eventsForDay, // Use filtered events for this day
      });
  
      currentDate.add(1, "day");
    }
  
    return days;
  }
  
export function generateDaysArrayUpComing(currentMonth, selectedDay, events) {
    const startOfMonth = moment(currentMonth).locale("nl-be").startOf("isoWeek");
    const endOfMonth = moment(currentMonth)
      .locale("nl-be")
      .endOf("month")
      .endOf("isoWeek");
    const days = [];
  
    let currentDate = moment(startOfMonth).locale("nl-be");
  
    while (currentDate.isSameOrBefore(endOfMonth)) {
      const isToday = currentDate.isSame(moment(), "day");
  
      // Filter events that match the current date
      const eventsForDay = events?.filter(event => 
        moment(event.start_time).locale('nl-be').format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD')
      );
  
      days.push({
        date: currentDate.format("YYYY-MM-DD"),
        isCurrentMonth: currentDate.isSame(currentMonth, "month"),
        isToday,
        isSelected: selectedDay?.date === currentDate.format("YYYY-MM-DD"),
        events: eventsForDay || [], // Use filtered events for this day
      });
  
      currentDate.add(1, "day");
    }
  
    return days;
  }
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
